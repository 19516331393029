import React, { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { useProjectsActions } from "."

export default function useProjectVersions() {
  const { committee_id, project_id } = useParams()
  const { getProjectVersions } = useProjectsActions()
  const [versions, setVersions] = useState(null)
  const [isLoading, setIsLoading] = useState(true)

  async function fetchProjectVersions() {
    try {
      setIsLoading(true)
      const versions = await getProjectVersions(committee_id, project_id)
      setVersions(versions)
    } catch (error) {
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    fetchProjectVersions()
  }, [committee_id, project_id])

  return { isLoading, versions }
}
