import React, { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import CommitteeMemberService from "../services/CommitteeService"

export default function useCommitteeMembersNotAdded() {
  const { committee_id } = useParams()
  const [members, setMembers] = useState(null)
  const [isLoading, setIsLoading] = useState(true)

  const getCommitteeMemberNotAdded = async () => {
    setIsLoading(true)
    try {
      const { data } =
        await CommitteeMemberService.getNotAddedMembers(committee_id)

      setMembers(data.members)
    } catch (error) {
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    getCommitteeMemberNotAdded()
  }, [])

  return { members, isLoading }
}
