import { useRef, useState } from "react"
import { useCommitteeActions } from "../../hooks"
import { useNavigate } from "react-router-dom"
import { toastError, toastSuccess } from "../../../../helper"
import InputSelectCommitteeImage from "../UI/InputSelectCommitteeImage"
import { FaSpinner } from "react-icons/fa6"

export default function FormCreateCommittee() {
  const navigate = useNavigate()
  const { inProgress, createCommittee } = useCommitteeActions()

  const [imageFile, setImageFile] = useState(null)
  const titleRef = useRef()
  const symbolRef = useRef()

  const handleImageChange = (file) => {
    setImageFile(file)
  }

  const onSubmitCreateNewCommittee = async (e) => {
    e.preventDefault()

    try {
      const title = titleRef.current.value
      const symbol = symbolRef.current.value

      const payload = {
        title,
        symbol,
        image: imageFile,
      }

      const message = await createCommittee(payload)
      if (message) {
        toastSuccess(message)
        navigate("/committees?page=1")
      }
    } catch (error) {
      if (!error.response && !error.request) {
        toastError("لم يتم الاتصال بالخادم. يرجى التحقق من الاتصال بالإنترنت.")
      } else if (error.response) {
        toastError(error.response.data.error)
      } else if (error.request) {
        toastError("لم يتم استلام استجابة من الخادم. حاول مرة أخرى لاحقًا.")
      } else {
        toastError("حدث خطأ غير متوقع. حاول مرة أخرى لاحقًا.")
      }
    }
  }

  return (
    <form onSubmit={onSubmitCreateNewCommittee}>
      <div className="flex flex-col gap-4">
        <InputSelectCommitteeImage
          imageFile={imageFile}
          onChange={handleImageChange}
        />
        <div className="flex w-full flex-col gap-1">
          <label
            className="font-bold"
            htmlFor="ID_TITLE"
          >
            الأسم <span className="text-danger">*</span>
          </label>
          <input
            ref={titleRef}
            type="text"
            id="ID_TITLE"
            name="title"
            className="w-full rounded-md border-2 border-muted px-6 py-3 focus:outline-none focus:ring-2 focus:ring-primary"
          />
        </div>
        <div className="flex w-full flex-col gap-1">
          <label
            className="font-bold"
            htmlFor="ID_SYMBOL"
          >
            الرمز <span className="text-danger">*</span>
          </label>
          <input
            ref={symbolRef}
            type="text"
            id="ID_SYMBOL"
            name="symbol"
            className="w-full rounded-md border-2 border-muted px-6 py-3 focus:outline-none focus:ring-2 focus:ring-primary"
            onKeyDown={function (event) {
              if (event.key === " ") {
                event.preventDefault()
              }
            }}
            onChange={function (event) {
              event.target.value = event.target.value.replace(/\s/g, "")
            }}
          />
        </div>
        <button
          type="submit"
          disabled={inProgress}
          className="flex w-fit rounded-md border-2 border-primary-hover bg-primary px-4 py-3 text-white hover:bg-primary-hover disabled:border-muted disabled:bg-light disabled:text-dark"
        >
          {inProgress ? (
            <div className="flex items-center">
              <FaSpinner
                size={16}
                className="ml-2 animate-spin"
              />
              <div>جاري إنشاء لجنة...</div>
            </div>
          ) : (
            "إنشاء لجنة"
          )}
        </button>
      </div>
    </form>
  )
}
