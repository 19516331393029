import React, { useState } from "react"
import { useSearchParams } from "react-router-dom"
import { CommitteeService, COMMITTEE_ACTIONS, useCommitteeContext } from ".."

export default function useCommitteeActions() {
  const [searchParams] = useSearchParams()
  const { committeeDispatch } = useCommitteeContext()
  const [inProgress, setInProgress] = useState(false)

  async function getCommittees() {
    try {
      committeeDispatch({
        type: COMMITTEE_ACTIONS.SET_IS_LOADING,
        payload: { isLoading: true },
      })
      const { data } = await CommitteeService.getCommittees(
        searchParams.toString(),
      )
      committeeDispatch({
        type: COMMITTEE_ACTIONS.SET_COMMITTEES,
        payload: {
          committees: data.committees,
          pagination: {
            links: data.links,
            current_page: data.current_page,
            first_page: data.first_page,
            last_page: data.last_page,
          },
        },
      })
    } catch (error) {
      throw error
    } finally {
      committeeDispatch({
        type: COMMITTEE_ACTIONS.SET_IS_LOADING,
        payload: { isLoading: false },
      })
    }
  }

  async function getCommittee(committeeId) {
    try {
      const { data } = await CommitteeService.getCommittee(committeeId)
      return data.committee
    } catch (error) {
      throw error
    }
  }

  async function createCommittee(payload) {
    try {
      setInProgress(true)
      const { data } = await CommitteeService.create(payload)
      return data.message
    } catch (error) {
      throw error
    } finally {
      setInProgress(false)
    }
  }

  async function deleteCommittee(committeeId) {
    try {
      const { data } = await CommitteeService.delete(committeeId)
      await getCommittees()
      return data.message
    } catch (error) {
      throw error
    }
  }

  async function updateCommittee(committeeId, committee) {
    try {
      setInProgress(true)
      const { data } = await CommitteeService.update(committeeId, committee)
      return data.message
    } catch (error) {
      throw error
    } finally {
      setInProgress(false)
    }
  }

  // const createCommittee = async (committee) => {
  //   try {
  //     setInProgress(true)
  //     const response = await createNewCommitteeAPI(committee)
  //     return response
  //   } catch (error) {
  //     throw error
  //   } finally {
  //     setInProgress(false)
  //   }
  // }

  return {
    inProgress,
    getCommittees,
    getCommittee,
    deleteCommittee,
    createCommittee,
    updateCommittee,
  }
}
