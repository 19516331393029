export default function LoaderProjectCommentsList() {
  return (
    <div className="flex flex-col gap-2">
      {[...Array(12)].map((_, index) => (
        <div
          key={index}
          className="h-[64px] w-full animate-pulse rounded-md border-2 border-muted bg-white"
        />
      ))}
    </div>
  )
}
