import React from "react"
import { AiFillFile, AiOutlineFileImage } from "react-icons/ai"
import {
  FaFileCsv,
  FaFileExcel,
  FaFilePdf,
  FaFilePowerpoint,
  FaFileWord,
} from "react-icons/fa"

export default function FileIcon({ type, size = 16 }) {
  const getIconByType = () => {
    // Normalize and map common extensions
    const fileType = type.toLowerCase()
    if (["pdf"].includes(fileType))
      return (
        <FaFilePdf
          className="text-dark"
          size={size}
        />
      )
    if (["doc", "docx", "word"].includes(fileType))
      return (
        <FaFileWord
          className="text-dark"
          size={size}
        />
      )
    if (["xls", "xlsx", "excel"].includes(fileType))
      return (
        <FaFileExcel
          className="text-dark"
          size={size}
        />
      )
    if (["csv"].includes(fileType))
      return (
        <FaFileCsv
          className="text-dark"
          size={size}
        />
      )
    if (["ppt", "pptx", "powerpoint"].includes(fileType))
      return (
        <FaFilePowerpoint
          className="text-dark"
          size={size}
        />
      )
    if (["png", "jpg", "jpeg", "webp"].includes(fileType))
      return (
        <AiOutlineFileImage
          className="text-dark"
          size={size}
        />
      )
    return <AiFillFile /> // Default icon for unsupported types
  }

  return <>{getIconByType()}</>
}
