import { COMMITTEE_ACTIONS } from "."

export function committeeReducer(state, action) {
  switch (action.type) {
    case COMMITTEE_ACTIONS.SET_COMMITTEES:
      return {
        ...state,
        committees: action.payload.committees,
        pagination: action.payload.pagination,
      }

    case COMMITTEE_ACTIONS.SET_IS_LOADING:
      return { ...state, isLoading: action.payload.isLoading }
    default:
      return state
  }
}
