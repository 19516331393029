import { useState } from "react"
import CommitteeDocumentService from "../services/committeeDocumentService"
import download from "downloadjs"
import useCommitteeFolderActions from "./useCommitteeFolderActions"

export default function useCommitteeDocumentActions() {
  const { getCommitteeFolders } = useCommitteeFolderActions()
  const [inProgress, setInProgress] = useState(false)

  async function addDocument(committeeId, folderId, document) {
    setInProgress(true)
    try {
      const { data } = await CommitteeDocumentService.addDocument(
        committeeId,
        folderId,
        document,
      )
      return data.message
    } catch (error) {
      throw error
    } finally {
      setInProgress(false)
    }
  }

  async function downloadDocument(committeeId, folderId, documentId, fileName) {
    setInProgress(true)
    try {
      const response = await CommitteeDocumentService.downloadDocument(
        committeeId,
        folderId,
        documentId,
      )
      download(response.data, fileName, response.headers["Content-Type"])
      return `تم تحميل الملف ${fileName} بنجاح`
    } catch (error) {
      throw error
    } finally {
      setInProgress(false)
    }
  }

  async function deleteDocument(committeeId, folderId, documentId) {
    setInProgress(true)
    try {
      const { data } = await CommitteeDocumentService.deleteDocument(
        committeeId,
        folderId,
        documentId,
      )
      await getCommitteeFolders()
      return data.message
    } catch (error) {
      throw error
    } finally {
      setInProgress(false)
    }
  }

  return { addDocument, downloadDocument, deleteDocument, inProgress }
}
