import { useContext, useReducer } from "react"
import UserContext from "./UserContext"
import { userInitialState } from "./userInitialState"
import { userReducer } from "./userReducer"
import { Outlet } from "react-router-dom"

export const useUserContext = () => useContext(UserContext)

export function UserProvider() {
  const [userState, userDispatch] = useReducer(userReducer, userInitialState)

  return (
    <UserContext.Provider value={{ userState, userDispatch }}>
      <Outlet />
    </UserContext.Provider>
  )
}
