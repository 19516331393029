import BreadcrumbProjectEdit from "../components/project-edit-page/BreadcrumbProjectEdit"
import FormEditProjectDetails from "../components/project-edit-page/FormEditProjectDetails"
import LoaderEditProjectDetailsForm from "../components/project-edit-page/LoaderEditProjectDetailsForm"
import { useProjectContext } from "../context/project"

export default function ProjectEditPage() {
  const { projectState } = useProjectContext()

  return (
    <div className="flex w-full flex-col gap-4">
      <BreadcrumbProjectEdit />
      {projectState.isLoading ? (
        <LoaderEditProjectDetailsForm />
      ) : (
        <FormEditProjectDetails project={projectState.project} />
      )}
    </div>
  )
}
