import { useState } from "react"
import { useSearchParams } from "react-router-dom"
import CommitteeMemberService from "../services/CommitteeService"
import { COMMITTEE_MEMBERS_ACTIONS, useCommitteeMembersContext } from ".."

export default function useCommitteeMemberActions() {
  const { committeeMembersDispatch } = useCommitteeMembersContext()
  const [inProgress, setInProgress] = useState(false)
  const [searchParams] = useSearchParams()

  async function getMembers(committeeId) {
    try {
      committeeMembersDispatch({
        type: COMMITTEE_MEMBERS_ACTIONS.SET_IS_LOADING,
        payload: { isLoading: true },
      })
      const { data } = await CommitteeMemberService.getMembers(
        committeeId,
        searchParams.toString(),
      )
      committeeMembersDispatch({
        type: COMMITTEE_MEMBERS_ACTIONS.SET_MEMBERS,
        payload: {
          members: data.members,
          pagination: {
            links: data.links,
            current_page: data.current_page,
            first_page: data.first_page,
            last_page: data.last_page,
          },
        },
      })
    } catch (error) {
    } finally {
      committeeMembersDispatch({
        type: COMMITTEE_MEMBERS_ACTIONS.SET_IS_LOADING,
        payload: { isLoading: false },
      })
    }
  }

  async function getMember(committeeId, memberId) {
    try {
      const { data } = await CommitteeMemberService.getMember(
        committeeId,
        memberId,
      )
      return data.member
    } catch (error) {}
  }

  async function deleteMember(committeeId, memberId) {
    try {
      setInProgress(true)
      const { data } = await CommitteeMemberService.deleteMember(
        committeeId,
        memberId,
      )
      getMembers(committeeId)
      return data.message
    } catch (error) {
      throw error
    } finally {
      setInProgress(false)
    }
  }
  const addMember = async (committeeId, memberId, payload) => {
    try {
      setInProgress(true)
      const { data } = await CommitteeMemberService.addMember(
        committeeId,
        memberId,
        payload,
      )
      return data.message
    } catch (error) {
      throw error
    } finally {
      setInProgress(false)
    }
  }

  const changeRole = async (committeeId, memberId, payload) => {
    try {
      setInProgress(true)
      const { data } = await CommitteeMemberService.changeRole(
        committeeId,
        memberId,
        payload,
      )
      return data.message
    } catch (error) {
      throw error
    } finally {
      setInProgress(false)
    }
  }

  return {
    inProgress,
    getMembers,
    getMember,
    deleteMember,
    addMember,
    changeRole,
  }
}
