import { useLayoutEffect } from "react"
import { Link, useSearchParams } from "react-router-dom"
import {
  ButtonCommitteesFilter,
  CommitteesGrid,
  useCommitteeActions,
  useCommitteeContext,
} from ".."
import NotFoundCommittees from "../components/NotFoundCommittees"

export default function CommitteesPage() {
  const [searchParams] = useSearchParams()
  const { getCommittees } = useCommitteeActions()
  const { committeeState } = useCommitteeContext()

  useLayoutEffect(() => {
    getCommittees()
  }, [searchParams])

  return (
    <div className="flex flex-col w-full gap-2">
      <div className="flex gap-0.5 font-bold">
        <Link
          className="hover:text-success"
          to={"/"}
        >
          الرئيسية
        </Link>
        <div>/</div>
        <h2 className="text-success">اللجان الفنية العربية</h2>
      </div>
      <div className="flex items-center justify-between">
        <Link
          to="/committees/create"
          className="px-4 py-2 font-bold text-white border-2 rounded-md border-primary-hover bg-primary hover:bg-primary-hover"
        >
          إضافة لجنة
        </Link>
        <ButtonCommitteesFilter />
      </div>
      <CommitteesGrid
        isLoading={committeeState.isLoading}
        committees={committeeState.committees}
      />
    </div>
  )
}
