import React, { useState } from "react"
import { ModalUserCommittees } from "."

export default function ButtonUserCommittees({ committees, full_name_ar }) {
  const [isCommitteesModelOpen, setIsCommitteesModelOpen] = useState(false)

  const handleOnClickOpenCommitteesModal = () => {
    setIsCommitteesModelOpen(true)
  }

  const handleOnClickCloseCommitteesModal = () => {
    setIsCommitteesModelOpen(false)
  }

  return (
    <>
      <button
        onClick={handleOnClickOpenCommitteesModal}
        className={`rounded-md border-2 border-muted px-4 py-2 hover:bg-light disabled:cursor-not-allowed disabled:bg-light disabled:opacity-45`}
        disabled={!committees}
      >
        اللجان الفنية العربية
      </button>
      <ModalUserCommittees
        isOpen={isCommitteesModelOpen}
        onClose={handleOnClickCloseCommitteesModal}
        committees={committees}
        full_name_ar={full_name_ar}
      />
    </>
  )
}
