import { COMMITTEE_MEMBERS_ACTIONS } from "."

export function committeeMembersReducer(state, action) {
  switch (action.type) {
    case COMMITTEE_MEMBERS_ACTIONS.SET_MEMBERS:
      return {
        ...state,
        members: action.payload.members,
        pagination: action.payload.pagination,
      }
    case COMMITTEE_MEMBERS_ACTIONS.SET_IS_LOADING:
      return { ...state, isLoading: action.payload.isLoading }
    default:
      return state
  }
}
