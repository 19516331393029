import { BreadcrumbProjectDetails } from "../components/project-details-page"
import ButtonDownloadProject from "../components/project-details-page/ButtonDownloadProject"
import ButtonProjectAttachments from "../components/project-details-page/ButtonProjectAttachments"
import ProjectDetails from "../components/project-details-page/ProjectDetails"
import ButtonProjectVersions from "../components/project-details-page/ButtonProjectVersions"
import ProjectComments from "../components/project-details-page/ProjectComments"
import ButtonEditProject from "../components/project-details-page/ButtonEditProject"

export default function ProjectsDetailsPage() {
  return (
    <div className="flex w-full flex-col gap-2">
      <BreadcrumbProjectDetails />
      <div className="flex justify-between">
        <div className="flex gap-2">
          <ButtonDownloadProject />
          <ButtonProjectAttachments />
          <ButtonProjectVersions />
        </div>
        <div>
          <ButtonEditProject />
        </div>
      </div>
      <ProjectDetails />
      <ProjectComments />
    </div>
  )
}
