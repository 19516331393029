import axiosClient from "../../../config/axiosClient"

const CommitteeMemberService = {
  getMembers(committeeId, queryString = "") {
    return axiosClient.get(
      `/dashboard/committees/${committeeId}/members?${queryString}`,
    )
  },
  deleteMember(committeeId, memberId) {
    return axiosClient.delete(
      `/dashboard/committees/${committeeId}/members/${memberId}`,
    )
  },
  getMember(committeeId, memberId) {
    return axiosClient.get(
      `/dashboard/committees/${committeeId}/members/${memberId}`,
    )
  },
  getNotAddedMembers(committeeId) {
    return axiosClient.get(
      `/dashboard/committees/${committeeId}/members/not-added`,
    )
  },
  addMember(committeeId, memberId, payload) {
    return axiosClient.post(
      `/dashboard/committees/${committeeId}/members/${memberId}`,
      payload,
    )
  },
  changeRole(committeeId, memberId, payload) {
    return axiosClient.post(
      `/dashboard/committees/${committeeId}/members/${memberId}/change-role`,
      payload,
    )
  },
}

export default CommitteeMemberService
