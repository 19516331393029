import React from "react"
import { Link } from "react-router-dom"
import useCommittee from "../../../committees/hooks/useCommittee"

export default function BreadcrumbCommitteeDocument() {
  const { committee, isLoading } = useCommittee()
  return (
    <div className="mb-2 flex gap-0.5 font-bold">
      <Link
        className="hover:text-success"
        to={"/"}
      >
        الرئيسية
      </Link>
      <div>/</div>
      <div>اللجنة الفنية العربية</div>
      <div>/</div>
      {isLoading ? (
        <h2 className="animate-pulse">اللجنة الفنية العربية لمواصفات ...</h2>
      ) : (
        <h2 className="hover:text-success">{committee?.title}</h2>
      )}
      <div>/</div>
      <h2 className="text-success">الوثائق</h2>
    </div>
  )
}
