import { axiosClient } from "../../../config"

const ProjectService = {
  getProjects(committeeId, queryString) {
    return axiosClient.get(
      `/dashboard/committees/${committeeId}/projects?${queryString}`,
    )
  },
  getProject(committeeId, projectId) {
    return axiosClient.get(
      `/dashboard/committees/${committeeId}/projects/${projectId}`,
    )
  },
  updateProject(committeeId, projectId, payload) {

    return axiosClient.post(
      `/dashboard/committees/${committeeId}/projects/${projectId}`,
      payload,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      },
    )
  },
  getAttachments(committeeId, projectId) {
    return axiosClient.get(
      `/dashboard/committees/${committeeId}/projects/${projectId}/attachments`,
    )
  },
  addAttachment(committeeId, projectId, payload) {
    return axiosClient.post(
      `/dashboard/committees/${committeeId}/projects/${projectId}/attachments`,
      payload,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      },
    )
  },
  downloadAttachment(committeeId, projectId, attachmentId) {
    return axiosClient.get(
      `/dashboard/committees/${committeeId}/projects/${projectId}/attachments/${attachmentId}/download`,
      {
        responseType: "blob",
      },
    )
  },
  removeProjectAttachment(committeeId, projectId, attachmentId) {
    return axiosClient.delete(
      `/dashboard/committees/${committeeId}/projects/${projectId}/attachments/${attachmentId}`,
    )
  },
  downloadProject(committeeId, projectId) {
    return axiosClient.get(
      `/dashboard/committees/${committeeId}/projects/${projectId}/download`,
      {
        responseType: "blob",
      },
    )
  },
  downloadVersion(committeeId, projectId, versionId) {
    return axiosClient.get(
      `/dashboard/committees/${committeeId}/projects/${projectId}/versions/${versionId}/download`,
      {
        responseType: "blob",
      },
    )
  },
  getVersions(committeeId, projectId) {
    return axiosClient.get(
      `/dashboard/committees/${committeeId}/projects/${projectId}/versions`,
    )
  },
  getComments(committeeId, projectId) {
    return axiosClient.get(
      `/dashboard/committees/${committeeId}/projects/${projectId}/comments`,
    )
  },
}

export default ProjectService
