import React, { useEffect, useState } from "react"
import Select from "react-tailwindcss-select"
import useEntities from "../../hooks/useEntities"

export default function SelectEntities({ value, onChange, error }) {
  const { isLoading, entities, errors } = useEntities()
  const [selectedEntity, setSelectedEntity] = useState(null)

  // Update selectedCountry whenever countries or value changes
  useEffect(() => {
    if (value && entities.length > 0) {
      const defaultEntity = entities.find((entity) => entity.id === value)
      if (defaultEntity) {
        setSelectedEntity({
          label: defaultEntity.name,
          value: defaultEntity.id,
        })
      } else {
        setSelectedEntity(null)
      }
    } else {
      setSelectedEntity(null)
    }
  }, [value, entities])

  const handleOnChange = (option) => {
    setSelectedEntity(option)
    if (onChange) {
      onChange(option?.value ?? null)
    }
  }

  return (
    <div className="flex w-full flex-col gap-2">
      <Select
        value={selectedEntity}
        onChange={handleOnChange}
        options={entities.map((entity) => ({
          label: entity.name,
          value: entity.id,
        }))}
        loading={isLoading}
        isDisabled={isLoading}
        isSearchable
        placeholder="جهاز التقييس..."
        isClearable
        classNames={{
          menuButton: ({ isDisabled }) =>
            `focus:outline-none focus:ring-2 focus:ring-primary border-2 border-muted py-0.5 pr-4 bg-light flex rounded-md bg-white ${isDisabled ? "opacity-50 cursor-not-allowed" : ""}`,
        }}
        formatOptionLabel={(data) => (
          <li
            className={`z-50 mt-1 line-clamp-1 block cursor-pointer select-none rounded-md px-4 py-3 transition duration-200 ${data.isSelected ? `bg-primary font-bold text-white` : ``}`}
          >
            <div className="flex items-center gap-2">
              <div>{data.label}</div>
            </div>
          </li>
        )}
      />
      {error && <p className="text-xs text-danger">{error}</p>}
    </div>
  )
}
