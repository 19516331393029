import { useState } from "react"
import { FiFilter } from "react-icons/fi"
import { ModalUsersFilter } from "."

export default function ButtonUsersFilter() {
  const [isUsersFilterOpen, setIsUsersFilterOpen] = useState(false)

  const handleOnClickOpenUsersFilter = () => {
    setIsUsersFilterOpen(true)
  }

  const handleOnClickCloseUsersFilter = () => {
    setIsUsersFilterOpen(false)
  }

  return (
    <>
      <button
        onClick={handleOnClickOpenUsersFilter}
        className="flex items-center gap-2 rounded-md border-2 border-muted bg-white p-2 pl-4 text-dark hover:bg-light"
      >
        <FiFilter size={16} />
        <div className="font-bold">فلتر البحث</div>
      </button>
      <ModalUsersFilter
        isOpen={isUsersFilterOpen}
        onClose={handleOnClickCloseUsersFilter}
      />
    </>
  )
}
