import { useEffect } from "react"
import { Outlet, useParams } from "react-router-dom"
import { useProjectsActions } from "../hooks"

export default function ProjectLayout() {
  const { committee_id, project_id } = useParams()
  const { getProject } = useProjectsActions()

  useEffect(() => {
    getProject(committee_id, project_id)
  }, [committee_id, project_id])
  return <Outlet />
}
