import { Link } from "react-router-dom"
import { useUser } from "../.."

export default function BreadcrumbCommitteeUserProfile() {
  const { user, isLoading } = useUser()
  return (
    <div className="flex gap-0.5 font-bold">
      <h2>الرئيسية</h2>
      <div>/</div>
      <Link
        className="hover:text-success"
        to={"/users?page=1"}
      >
        المستخدمين
      </Link>
      <div>/</div>
      {isLoading ? (
        <div className="w-32 h-4 rounded-md animate-pulse bg-muted"></div>
      ) : (
        <h2 className="text-success">
          {user.first_name_ar} {user.last_name_ar}
        </h2>
      )}
    </div>
  )
}
