import { COMMITTEE_FOLDERS_ACTIONS } from "."

export function committeeFoldersReducer(state, action) {
  switch (action.type) {
    case COMMITTEE_FOLDERS_ACTIONS.SET_FOLDERS:
      return {
        ...state,
        folders: action.payload.folders,
        pagination: action.payload.pagination,
      }

    case COMMITTEE_FOLDERS_ACTIONS.SET_IS_LOADING:
      return { ...state, isLoading: action.payload.isLoading }
    default:
      return state
  }
}
