import { Link } from "react-router-dom"
import useCommitteeGlobalDocuments from "../hooks/useCommitteeGlobalDocuments"
import { FaFile } from "react-icons/fa"
import ButtonDownloadGlobalDocument from "../components/committee-global-documents-page/ButtonDownloadGlobalDocument"
import ButtonDeleteGlobalDocument from "../components/committee-global-documents-page/ButtonDeleteGlobalDocument"
import NotFoundGlobalCommitteeDocuments from "../components/NotFoundGlobalCommitteeDocuments"

export default function CommitteeGlobalDocumentsPage() {
  const { isLoading, documents, fetchDocuments } = useCommitteeGlobalDocuments()

  return (
    <div className="flex flex-col w-full gap-2">
      <div className="flex gap-0.5 font-bold">
        <h2>الرئيسية</h2>
        <div>/</div>
        <div className="text-success">الوثائق العامة للجان الفنية</div>
      </div>
      <Link to={`/committees/global/documents/create`}>
        <button className="px-4 py-2 font-bold text-white border-2 rounded-md border-primary-hover bg-primary hover:bg-primary-hover">
          إضافة وثيقة
        </button>
      </Link>
      <div>
      <div className="grid grid-cols-12 gap-2">
          {isLoading
            ? [...Array(12)].map((_, index) => (
                <div
                  key={index}
                  className="col-span-6 px-2 py-2 pr-4 bg-white border-2 rounded-md min-h-12 border-muted"
                />
              ))
            : documents.map((document) => (
                <div
                  key={document.id}
                  className="col-span-6 px-2 py-2 pr-4 bg-white border-2 rounded-md border-muted"
                >
                  <div className="flex justify-between">
                    <div className="flex items-center gap-2">
                      <FaFile size={20} />
                      <div>{document.name}</div>
                    </div>
                    <div className="flex gap-2">
                      <ButtonDownloadGlobalDocument
                        documentId={document.id}
                        documentName={document.name}
                      />
                      <ButtonDeleteGlobalDocument
                        documentId={document.id}
                        fetchDocuments={fetchDocuments}
                      />
                    </div>
                  </div>
                </div>
              ))}
        </div>
      </div>
    </div>
  )
}
