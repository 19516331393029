import { Link } from "react-router-dom"
import { datetimeFormat, getMemberRoleNameByKey } from "../../../../helper"
import { ButtonCommitteeMemberOptions } from "."

export default function CommitteeMembersGridItem({ member }) {
  return (
    <div className="col-span-12 overflow-hidden bg-white border-2 rounded-md group border-muted hover:border-primary md:col-span-6 lg:col-span-4">
      <Link
        to={`/users/${member.id}`}
        className="relative group-hover:text-primary"
      >
        <div className="absolute top-0 left-0 z-0 h-20">
          <div className="relative w-full h-full">
            <img
              className="object-contain w-full h-full opacity-80"
              alt={member.country.name_ar}
              title={member.country.name_ar}
              src={`${process.env.REACT_APP_BASE_URL}/storage/${member.country.image}`}
            />
            <div className="absolute top-0 bottom-0 right-0 z-0 w-1/2 h-full bg-gradient-to-l from-white to-transparent"></div>
            <div className="absolute bottom-0 right-0 z-0 w-full h-1/2 bg-gradient-to-t from-white to-transparent"></div>
          </div>
        </div>

        <div className="relative z-0 flex flex-col gap-2 p-2">
          <div className="">{member.country.name_ar}</div>
          <div className="flex gap-0.5">
            <div className="font-bold">الرتبة في اللجنة :</div>
            <div className="">{getMemberRoleNameByKey(member.role)}</div>
          </div>
          <div className="flex gap-0.5">
            <div className="font-bold">الاسم الكامل :</div>
            <div className="">
              {member.first_name_ar} {member.last_name_ar}
            </div>
          </div>
          <div className="flex gap-0.5">
            <div className="font-bold"> البريد الإلكتروني :</div>
            <div className="">{member.email}</div>
          </div>
          <div className="flex gap-0.5">
            <div className="font-bold">الانضمام في :</div>
            <div className="">{datetimeFormat(member.joined_at)}</div>
          </div>
        </div>
      </Link>
      <div className="flex justify-between p-2 pt-0">
        <div />
        <ButtonCommitteeMemberOptions member={member} />
      </div>
    </div>
  )
}
