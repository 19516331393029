import { axiosClient } from "../../../config"

const UserService = {
  getUsers(queryString) {
    return axiosClient.get(`/dashboard/users?${queryString}`)
  },
  changeAccountStatus(userId, status) {
    return axiosClient.post(`/dashboard/users/${userId}/toggle-activation`, {
      is_activated: status,
    })
  },
  deleteUser(userId) {
    return axiosClient.delete(`/dashboard/users/${userId}`)
  },
  createUser(user) {
    return axiosClient.post("/dashboard/users", user)
  },
  changePassword(userId, payload) {
    return axiosClient.post(
      `/dashboard/users/${userId}/change-password`,
      payload,
    )
  },
  getUser(userId) {
    return axiosClient.get(`/dashboard/users/${userId}`)
  },
  updateUser(userId, user) {
    return axiosClient.post(`/dashboard/users/${userId}/update`, user)
  },
}

export default UserService

