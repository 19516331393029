import { useEffect, useState } from "react"
import { useUserActions } from "."
import { useParams } from "react-router-dom"

export default function useUser() {
  const { user_id } = useParams()
  const { getUser } = useUserActions()
  const [user, setUser] = useState(null)
  const [isLoading, setIsLoading] = useState(true)

  async function fetchUser() {
    try {
      setIsLoading(true)
      const user = await getUser(user_id)

      setUser(user)
    } catch (error) {
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    fetchUser()
  }, [user_id])

  return { user, isLoading }
}
