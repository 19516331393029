import { useEffect, useState } from "react"
import useCommitteeMemberActions from "./useCommitteeMemberActions"
import { useParams } from "react-router-dom"

export default function useMember() {
  const { committee_id, member_id } = useParams()
  const [member, setMember] = useState(null)
  const [isLoading, setIsLoading] = useState(true)
  const { getMember } = useCommitteeMemberActions()
  async function fetchMember() {
    try {
      setIsLoading(true)
      const member = await getMember(committee_id, member_id)
      setMember(member)
    } catch (error) {
    } finally {
      setIsLoading(false)
    }
  }
  useEffect(() => {
    fetchMember()
  }, [committee_id, member_id])

  return { isLoading, member }
}
