import FormCreateCommitteeFolder from "../components/committee-folder-create-page/FormCreateCommitteeFolder"
import BreadcrumbCommitteeFolderCreate from "../components/committee-folder-create-page/BreadcrumbCommitteeFolderCreate"

export default function CommitteeFoldersCreateDocumentsPage() {
  return (
    <div className="flex w-full flex-col gap-2">
      <BreadcrumbCommitteeFolderCreate />
      <div className="rounded-md border-2 border-muted bg-white p-4">
        <FormCreateCommitteeFolder />
      </div>
    </div>
  )
}
