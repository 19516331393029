import { useContext, useEffect, useReducer } from "react"
import { Outlet, useParams } from "react-router-dom"
import { ProjectContext, projectInitialState, projectReducer } from "."
import { useProjectsActions } from "../../hooks"

export const useProjectContext = () => useContext(ProjectContext)

// UserProvider to wrap the app and provide context
export function ProjectProvider() {
  const [projectState, projectDispatch] = useReducer(
    projectReducer,
    projectInitialState,
  )

  return (
    <ProjectContext.Provider value={{ projectState, projectDispatch }}>
      <Outlet />
    </ProjectContext.Provider>
  )
}
