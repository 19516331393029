import React from "react"
import {
  datetimeAgo,
  datetimeFormat,
  getProjectTypeByKey,
} from "../../../../helper"
import { RiDownloadLine, RiQuestionAnswerFill } from "react-icons/ri"
import { FaEye } from "react-icons/fa"
import LoaderProjectDetails from "./LoaderProjectDetails"
import { useProjectContext } from "../../context/project"

export default function ProjectDetails() {
  const { projectState } = useProjectContext()
  if (projectState.isLoading) {
    return <LoaderProjectDetails />
  }
  return (
    <div className="flex flex-col gap-2">
      <div className="flex flex-col gap-2 rounded-md border-2 border-muted bg-white p-2">
        <div>
          <div className="flex justify-between">
            <div className="flex items-center gap-2">
              <div className="h-6 overflow-hidden rounded-[4px] border-2 border-muted">
                <img
                  className="h-full w-full"
                  alt={projectState.project.user.country.name_ar}
                  title={projectState.project.user.country.name_ar}
                  src={`${process.env.REACT_APP_BASE_URL}/storage/${projectState.project.user.country.image}`}
                />
              </div>
              <em
                title={datetimeFormat(projectState.project.created_at)}
                className="text-xs text-secondary"
              >
                {datetimeAgo(projectState.project.created_at)}
              </em>
            </div>
          </div>
          <div className="text-sm font-bold">
            {projectState.project.user.first_name_ar}{" "}
            {projectState.project.user.last_name_ar}
          </div>
        </div>
        <div className="flex flex-col gap-1">
          <h2 className="text-xl font-bold">
            {projectState.project.title_ar || "--"}
          </h2>
          <h2 className="text-xl font-bold">
            {projectState.project.title_en || "--"}
          </h2>
        </div>
      </div>
      <div className="rounded-md border-2 border-muted bg-white p-4">
        <div>
          <h3 className="text-base font-bold underline">تفاصيل المشروع</h3>
          <div className="grid grid-cols-12 gap-2 p-4">
            <div className="col-span-5 font-bold md:col-span-2">
              نوع المشروع
            </div>
            <div className="col-span-5 md:col-span-10">
              {getProjectTypeByKey(projectState.project.type)?.split("/")[0]}
            </div>
            <div className="col-span-5 font-bold md:col-span-2">
              صفة المشروع
            </div>
            <div className="col-span-5 md:col-span-10">
              {getProjectTypeByKey(projectState.project.type)?.split("/")[1]}
            </div>
            <div className="col-span-5 font-bold md:col-span-2">
              نوع الوثيقة
            </div>
            <div className="col-span-5 md:col-span-10">
              {projectState.project.category}
            </div>
            <div className="col-span-5 font-bold md:col-span-2">اللغة</div>
            <div className="col-span-5 md:col-span-10">
              {projectState.project.language || "غير محددة"}
            </div>
            <div className="col-span-5 font-bold md:col-span-2">المراحل</div>
            <div className="col-span-5 md:col-span-10">
              {projectState.project.stage}
            </div>
          </div>
        </div>
        <div>
          <h3 className="text-base font-bold underline">المرجع الرئيسي</h3>
          <p className="p-4">{projectState.project.reference}</p>
        </div>
        <div>
          <h3 className="text-base font-bold underline">مراجع أخرى </h3>
          {projectState.project?.references?.length > 0 ? (
            <ul className="flex list-disc flex-col gap-2 px-8 py-4">
              {projectState.project.references.map((reference, index) => (
                <li key={index}>{reference}</li>
              ))}
            </ul>
          ) : (
            <p className="p-4">لا توجد مراجع أخرى</p>
          )}
        </div>
        <div>
          <h3 className="text-base font-bold underline">
            التصنيف الدولي للمواصفات (ICS)
          </h3>
          <p className="p-4">{projectState.project.ics || "غير محدد"}</p>
        </div>
        <div>
          <h3 className="text-base font-bold underline">مجال المشروع</h3>
          <p className="p-4">{projectState.project.domain || "غير محدد"}</p>
        </div>
        <div>
          <h3 className="text-base font-bold underline">ملاحظات إضافية</h3>
          <p className="p-4">{projectState.project.description || "لا توجد"}</p>
        </div>
      </div>
      <div className="flex justify-between gap-2">
        <div></div>
        <div className="grid grid-cols-3 gap-2">
          <div className="border-mutted col-span-1 flex !w-28 justify-end gap-2 rounded-md border-2 border-muted bg-white px-3 py-2">
            <div>{projectState.project.views_count}</div>
            <div>
              <FaEye size={16} />
            </div>
          </div>
          <div className="border-mutted col-span-1 flex !w-28 justify-end gap-2 rounded-md border-2 border-muted bg-white px-3 py-2">
            <div>{projectState.project.comments_count}</div>
            <div>
              <RiQuestionAnswerFill size={16} />
            </div>
          </div>
          <div className="border-mutted col-span-1 flex !w-28 justify-end gap-2 rounded-md border-2 border-muted bg-white px-3 py-2">
            <div>{projectState.project.downloads_count}</div>
            <div>
              <RiDownloadLine size={16} />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
