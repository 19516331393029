import { useEffect, useState } from "react"
import { getEntities } from "../services/entity"

export default function useEntities() {
  const [isLoading, setIsLoading] = useState(true)
  const [entities, setEntities] = useState([])
  const [errors, setErrors] = useState({})

  useEffect(() => {
    const fetchEntities = async () => {
      setIsLoading(true)
      try {
        const entities = await getEntities()
        setEntities(entities)
      } catch (error) {
        if (error.response) {
          const { data } = error.response
          if (data && data.errors) {
            setErrors(data.errors)
          } else {
            setErrors({ general: ["حدث خطأ غير متوقع. حاول مرة أخرى لاحقًا."] })
          }
        } else if (error.request) {
          setErrors({
            general: ["لم يتم تلقي استجابة من الخادم. حاول مرة أخرى لاحقًا."],
          })
        } else {
          setErrors({
            general: ["حدث خطأ أثناء إعداد الطلب. حاول مرة أخرى لاحقًا."],
          })
        }
      } finally {
        setIsLoading(false)
      }
    }
    fetchEntities()
  }, [])

  return { isLoading, entities, errors }
}
