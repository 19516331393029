import { useRef, useState } from "react"
import ModalFiler from "../../../core/components/UI/ModalFiler"
import { useSearchParams } from "react-router-dom"
import SelectCountries from "../../../core/components/UI/SelectCountries"

export default function ModalCommitteeFolderFilter({ isOpen, onClose }) {
  const [searchParams, setSearchParams] = useSearchParams()

  const documentNameRef = useRef(null)
  const yearRef = useRef(null)
  const fullnameRef = useRef(null)
  const [countryId, setCountryId] = useState(null)

  const handleOnSubmitCommitteeFoldersFilter = (event) => {
    event.preventDefault()
    const filteredData = {
      document_name: documentNameRef.current.value,
      year: yearRef.current.value,
      fullname: fullnameRef.current.value,
      country_id: countryId,
    }

    setSearchParams({ ...filteredData, page: "1" })
    onClose()
  }

  const handleReset = () => {
    setCountryId(null)
    documentNameRef.current.value = ""
    yearRef.current.value = ""
    fullnameRef.current.value = ""

    setSearchParams({ page: "1" })
  }
  
  return (
    <ModalFiler
      onClose={onClose}
      isOpen={isOpen}
    >
      <form
        className="flex flex-col gap-2"
        onSubmit={handleOnSubmitCommitteeFoldersFilter}
      >
        <div className="flex flex-col gap-1">
          <label
            htmlFor="ID_COUNTRY"
            className="font-bold"
          >
            البلد
          </label>
          <SelectCountries
            value={countryId}
            onChange={(selectedCountryId) => setCountryId(selectedCountryId)}
          />
        </div>
        <div className="flex flex-col gap-1">
          <label
            htmlFor="ID_FULLNAME"
            className="font-bold"
          >
            الاسم الكامل
          </label>
          <input
            ref={fullnameRef}
            id="ID_FULLNAME"
            type="text"
            placeholder="الاسم الكامل"
            className="w-full px-6 py-3 border-2 rounded-md border-muted focus:outline-none focus:ring-2 focus:ring-primary"
          />
        </div>

        <div className="flex flex-col gap-1">
          <label
            htmlFor="ID_DOCUMENT_NAME"
            className="font-bold"
          >
            اسم الوثيقة
          </label>
          <input
            type="text"
            id="ID_DOCUMENT_NAME"
            placeholder="اسم الوثيقة"
            className="w-full px-6 py-3 border-2 rounded-md border-muted focus:outline-none focus:ring-2 focus:ring-primary"
            ref={documentNameRef}
          />
        </div>
        <div className="flex flex-col gap-1">
          <label
            htmlFor="ID_YEAR"
            className="font-bold"
          >
            السنة
          </label>
          <input
            ref={yearRef}
            type="number"
            id="ID_YEAR"
            placeholder="السنة"
            className="w-full px-6 py-3 border-2 rounded-md border-muted focus:outline-none focus:ring-2 focus:ring-primary"
          />
        </div>

        <div className="flex gap-2">
          <button
            type="button"
            className="w-full px-4 py-3 border-2 rounded-md border-muted bg-light hover:bg-muted"
            onClick={handleReset}
          >
            مسح
          </button>
          <button
            type="submit"
            className="w-full px-4 py-3 text-white border-2 rounded-md border-primary-hover bg-primary hover:bg-primary-hover"
          >
            بحث
          </button>
        </div>
      </form>
    </ModalFiler>
  )
}
