import { useEffect, useState } from "react"
import { FiRefreshCw, FiUploadCloud } from "react-icons/fi"

export default function InputSelectCommitteeImage({
  defaultImageUrl,
  onChange,
}) {
  const [imageFile, setImageFile] = useState(null)

  useEffect(() => {
    if (defaultImageUrl) {
      setImageFile(defaultImageUrl)
    }
  }, [defaultImageUrl])

  const handleFileChange = (e) => {
    const file = e.target.files[0]
    if (file) {
      setImageFile(file)
      onChange(file)
    }
  }

  return (
    <div className="flex flex-col gap-1">
      <label
        htmlFor="ID_COMMITTEE_IMAGE"
        className="font-bold"
      >
        الصورة <span className="text-danger">*</span>
      </label>
      {imageFile ? (
        <div className="relative w-full overflow-hidden rounded-md border-2 border-muted">
          <label
            htmlFor="ID_COMMITTEE_IMAGE"
            className="absolute right-2 top-2 cursor-pointer rounded-md border-2 border-primary-hover bg-primary text-white hover:bg-primary-hover"
          >
            <div className="flex items-center gap-2 px-3 py-2">
              <FiRefreshCw size={16} />
              <span>تغيير الصورة</span>
            </div>
          </label>
          <img
            src={
              imageFile instanceof File
                ? URL.createObjectURL(imageFile)
                : imageFile
            }
            alt="Selected"
            className="h-56 w-full object-cover"
          />
        </div>
      ) : (
        <label
          htmlFor="ID_COMMITTEE_IMAGE"
          className="group flex h-56 w-full cursor-pointer flex-col items-center justify-center gap-4 rounded-md border-2 border-muted hover:border-primary hover:text-primary"
        >
          <FiUploadCloud size={32} />
          <span className="rounded-md bg-muted px-4 py-2 group-hover:bg-primary group-hover:text-white">
            تصفح ملفات
          </span>
        </label>
      )}
      <input
        id="ID_COMMITTEE_IMAGE"
        type="file"
        name="image"
        accept="image/png, image/webp, image/jpeg"
        onChange={handleFileChange}
        className="absolute z-[-1] opacity-0"
      />
    </div>
  )
}
