import { useContext, useReducer } from "react"
import { Outlet } from "react-router-dom"
import {
  CommitteeFoldersContext,
  committeeFoldersInitialState,
  committeeFoldersReducer,
} from "."

export const useCommitteeFoldersContext = () =>
  useContext(CommitteeFoldersContext)

// UserProvider to wrap the app and provide context
export const CommitteeFoldersProvider = () => {
  const [committeeFoldersState, committeeFoldersDispatch] = useReducer(
    committeeFoldersReducer,
    committeeFoldersInitialState,
  )

  return (
    <CommitteeFoldersContext.Provider
      value={{ committeeFoldersState, committeeFoldersDispatch }}
    >
      <Outlet />
    </CommitteeFoldersContext.Provider>
  )
}
