import axiosClient from "../../../config/axiosClient"

export const CommitteeService = {
  getCommittees(queryString) {
    return axiosClient.get(`/dashboard/committees?${queryString}`)
  },
  getCommittee(committeeId) {
    return axiosClient.get(`/dashboard/committees/${committeeId}`)
  },
  delete(committeeId) {
    return axiosClient.delete(`/dashboard/committees/${committeeId}`)
  },
  create(payload) {
    return axiosClient.post(`/dashboard/committees`, payload, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
  },
  update(committeeId, payload) {
    return axiosClient.post(`/dashboard/committees/${committeeId}`, payload, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
  },
}

// const createNewCommitteeAPI = async (committee) => {
//   try {
//     const { data } = await axiosClient.post(
//       `/dashboard/committees`,
//       committee,
//       {
//         headers: {
//           "Content-Type": "multipart/form-data",
//         },
//       },
//     )
//     return data
//   } catch (error) {
//     throw error
//   }
// }

// const getCommitteeAPI = async (committeeId) => {
//   try {
//     const {
//       data: { committee },
//     } = await axiosClient.get(`/dashboard/committees/${committeeId}`)
//     return committee
//   } catch (error) {
//     throw error
//   }
// }

// const updateCommitteeAPI = async (committeeId, committee) => {
//   try {
//     const { data } = await axiosClient.post(
//       `/dashboard/committees/${committeeId}`,
//       committee,
//       {
//         headers: {
//           "Content-Type": "multipart/form-data",
//         },
//       },
//     )
//     return data.message
//   } catch (error) {
//     throw error
//   }
// }
// const deleteCommitteeAPI = async (committeeId) => {
//   try {
//     const { data } = await axiosClient.delete(
//       `/dashboard/committees/${committeeId}`,
//     )
//     return data.message
//   } catch (error) {
//     throw error
//   }
// }

// export {
//   getCommitteesAPI,
//   createNewCommitteeAPI,
//   getCommitteeAPI,
//   updateCommitteeAPI,
//   deleteCommitteeAPI,
// }
