import { useRef, useState } from "react"
import ModalFiler from "../../../core/components/UI/ModalFiler"
import SelectCountries from "../../../core/components/UI/SelectCountries"
import { useSearchParams } from "react-router-dom"
import SelectCommitteeMembersType from "../../../core/components/UI/SelectCommitteeMembersType"

export default function ModalCommitteeMembersFilter({ onClose, isOpen }) {
  const [searchParams, setSearchParams] = useSearchParams()

  const [countryId, setCountryId] = useState(null)
  const [committeeMemberRole, setCommitteeMemberRole] = useState(null)

  const fullnameRef = useRef(null)
  const emailRef = useRef(null)

  async function handleOnSubmitCommitteeMembersFilter(event) {
    event.preventDefault()

    const filters = {
      country_id: countryId,
      fullname: fullnameRef.current?.value,
      email: emailRef.current?.value,
      role:committeeMemberRole,
    }

    setSearchParams({ ...filters, page: "1" })
    onClose()
  }

  const handleReset = () => {
    setCountryId(null)
    setCommitteeMemberRole(null)
    fullnameRef.current.value = ""
    emailRef.current.value = ""
    setSearchParams({ page: "1" })
    onClose()
  }

  return (
    <ModalFiler
      onClose={onClose}
      isOpen={isOpen}
    >
      <form
        className="flex flex-col gap-2"
        onSubmit={handleOnSubmitCommitteeMembersFilter}
      >
        <div className="flex flex-col gap-1">
          <label
            htmlFor="country_id"
            className="font-bold"
          >
            البلد
          </label>
          <SelectCountries
            value={countryId}
            onChange={(selectedCountryId) => setCountryId(selectedCountryId)}
          />
        </div>

        <div className="flex flex-col gap-1">
          <label
            htmlFor="ID_ROLE"
            className="font-bold"
          >
            الرتبة في اللجنة
          </label>
          <SelectCommitteeMembersType
            value={committeeMemberRole}
            onChange={(role) => setCommitteeMemberRole(role)}
          />
        </div>
        <div className="flex flex-col gap-1">
          <label
            htmlFor="ID_FULLNAME"
            className="font-bold"
          >
            الاسم الكامل
          </label>
          <input
            ref={fullnameRef}
            type="text"
            id="ID_FULLNAME"
            placeholder="الاسم الكامل"
            className="w-full px-6 py-3 border-2 rounded-md border-muted focus:outline-none focus:ring-2 focus:ring-primary"
          />
        </div>

        <label
          htmlFor="ID_EMAIL"
          className="font-bold"
        >
          بريد إلكتروني
        </label>
        <input
          ref={emailRef}
          type="email"
          id="ID_EMAIL"
          placeholder="بريد إلكتروني"
          className="w-full px-6 py-3 border-2 rounded-md border-muted focus:outline-none focus:ring-2 focus:ring-primary"
        />

        <div className="flex gap-2">
          <button
            type="button"
            className="w-full px-4 py-3 border-2 rounded-md border-muted bg-light hover:bg-muted"
            onClick={handleReset}
          >
            مسح
          </button>
          <button
            type="submit"
            className="w-full px-4 py-3 text-white border-2 rounded-md border-primary-hover bg-primary hover:bg-primary-hover"
          >
            بحث
          </button>
        </div>
      </form>
    </ModalFiler>
  )
}
