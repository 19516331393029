import { useState } from "react"
import { useSearchParams } from "react-router-dom"
import ProjectService from "../services/projectService"
import download from "downloadjs"
import { PROJECTS_ACTIONS, useProjectsContext } from "../context"
import { PROJECT_ACTIONS, useProjectContext } from "../context/project"

export default function useProjectsActions() {
  const [searchParams] = useSearchParams()
  const { projectsDispatch } = useProjectsContext()
  const { projectDispatch } = useProjectContext()
  const [inProgress, setInProgress] = useState(false)

  async function getProjects(committeeId) {
    try {
      projectsDispatch({
        type: PROJECTS_ACTIONS.SET_IS_LOADING,
        payload: {
          isLoading: true,
        },
      })
      const { data } = await ProjectService.getProjects(
        committeeId,
        searchParams.toString(),
      )
      projectsDispatch({
        type: PROJECTS_ACTIONS.SET_PROJECTS,
        payload: {
          projects: data.projects,
          pagination: {
            links: data.links,
            current_page: data.current_page,
            first_page: data.first_page,
            last_page: data.last_page,
          },
        },
      })
    } catch (error) {
      throw error
    } finally {
      projectsDispatch({
        type: PROJECTS_ACTIONS.SET_IS_LOADING,
        payload: {
          isLoading: false,
        },
      })
    }
  }

  async function getProject(committeeId, projectId) {
    try {
      projectDispatch({
        type: PROJECT_ACTIONS.SET_IS_LOADING,
        payload: {
          isLoading: true,
        },
      })
      const { data } = await ProjectService.getProject(committeeId, projectId)
      projectDispatch({
        type: PROJECT_ACTIONS.SET_PROJECT,
        payload: {
          project: data.project,
        },
      })
      return data.project
    } catch (error) {
    } finally {
      projectDispatch({
        type: PROJECT_ACTIONS.SET_IS_LOADING,
        payload: {
          isLoading: false,
        },
      })
    }
  }

  async function downloadVersion(committeeId, projectId, versionId, fileName) {
    try {
      setInProgress(true)
      const response = await ProjectService.downloadVersion(
        committeeId,
        projectId,
        versionId,
      )
      download(response.data, fileName, response.headers["Content-Type"])
    } catch (error) {
      throw error
    } finally {
      setInProgress(false)
    }
  }
  async function downloadProject(committeeId, projectId, fileName) {
    try {
      setInProgress(true)
      const response = await ProjectService.downloadProject(
        committeeId,
        projectId,
      )
      download(response.data, fileName, response.headers["Content-Type"])
    } catch (error) {
      throw error
    } finally {
      setInProgress(false)
    }
  }

  async function getProjectAttachments(committeeId, projectId) {
    try {
      const { data } = await ProjectService.getAttachments(
        committeeId,
        projectId,
      )
      return data.attachments
    } catch (error) {
      throw error
    }
  }
  async function addProjectAttachment(committeeId, projectId, payload) {
    try {
      const { data } = await ProjectService.addAttachment(
        committeeId,
        projectId,
        payload,
      )

      return data.message
    } catch (error) {
      throw error
    }
  }

  async function downloadProjectAttachment(
    committeeId,
    projectId,
    attachmentId,
    fileName,
  ) {
    try {
      setInProgress(true)
      const response = await ProjectService.downloadAttachment(
        committeeId,
        projectId,
        attachmentId,
      )
      download(response.data, fileName, response.headers["Content-Type"])
    } catch (error) {
      throw error
    } finally {
      setInProgress(false)
    }
  }
  async function removeProjectAttachment(committeeId, projectId, attachmentId) {
    try {
      setInProgress(true)
      const { data } = await ProjectService.removeProjectAttachment(
        committeeId,
        projectId,
        attachmentId,
      )
      return data.message
    } catch (error) {
      throw error
    } finally {
      setInProgress(false)
    }
  }

  async function getProjectVersions(committeeId, projectId) {
    try {
      const { data } = await ProjectService.getVersions(committeeId, projectId)
      return data.versions
    } catch (error) {
      throw error
    }
  }

  async function getProjectComments(committeeId, projectId) {
    try {
      const { data } = await ProjectService.getComments(committeeId, projectId)
      return data.comments
    } catch (error) {
      throw error
    }
  }

  async function updateProject(committeeId, projectId, payload) {
    try {
      setInProgress(true)
      const { data } = await ProjectService.updateProject(
        committeeId,
        projectId,
        payload,
      )
      await getProject(committeeId,projectId)
      return data.message
    } catch (error) {
      throw error
    } finally {
      setInProgress(false)
    }
  }
  return {
    getProjects,
    getProject,
    downloadProject,
    getProjectAttachments,
    downloadProjectAttachment,
    getProjectComments,
    getProjectVersions,
    downloadVersion,
    updateProject,
    addProjectAttachment,
    removeProjectAttachment,
    inProgress,
  }
}
