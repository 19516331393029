import React, { useEffect, useState } from "react"
import Select from "react-tailwindcss-select"
const types = [
  { value: "NEW_ADOPTION", label: "جديد / تبني" }, // "New / Adoption"
  { value: "NEW_PREPARATION", label: "جديد / اعداد" }, // "New / Preparation"
  { value: "REVIEW_ADOPTION", label: "مراجعة / تبني" }, // "Review / Adoption"
  { value: "REVIEW_PREPARATION", label: "مراجعة / اعداد" }, // "Review / Preparation"
]
export default function SelectProjectTypes({ value, onChange, error }) {
  const [selectedType, setSelectedType] = useState(
    types.find((type) => type.value === value) || null,
  )
  const handleOnChange = (option) => {
    setSelectedType(option)
    onChange(option?.value ?? null)
  }
  useEffect(() => {
    if (value) {
      const defaultType = types.find((type) => type.value === value)
      setSelectedType(defaultType || null)
    } else {
      setSelectedType(null)
    }
  }, [value])

  return (
    <div className="flex w-full flex-col gap-2">
      <Select
        value={selectedType}
        onChange={handleOnChange}
        options={types}
        isSearchable={false}
        placeholder="نوع المشروع..."
        classNames={{
          menuButton: ({ isDisabled }) =>
            `focus:outline-none focus:ring-2 focus:ring-primary border-2 border-muted py-0.5 pr-4 bg-light flex rounded-md bg-white`,
          searchContainer: "relative w-full",
          searchBox:
            "w-full outline-none py- px-2 rounded-md border-2 border-muted py-2 bg-light mb-2",
          searchIcon: "absolute left-2 w-5 h-5 top-[8px] text-dark ",
          menu: `absolute z-50 -mt-1 w-full shadow-lg rounded-b-md bg-white border-2 border-muted py-2`,
        }}
        formatOptionLabel={(data) => (
          <li
            className={`z-50 mt-1 line-clamp-1 block cursor-pointer select-none rounded-md px-4 py-3 transition duration-200 ${data.isSelected ? `bg-primary font-bold text-white` : ``}`}
          >
            <div className="flex items-center">
              <div>{data.label}</div>
            </div>
          </li>
        )}
      />
      {error && <p className="text-xs text-danger">{error}</p>}
    </div>
  )
}
