import { FormAddCommitteeMember } from ".."
import BreadcrumbCommitteeMembersAdd from "../components/committee-members-add-page/BreadcrumbCommitteeMembersAdd"
// import BreadcrumbCommitteeMember from "../components/UI/BreadcrumbCommitteeMember"

export default function CommitteeMembersAddPage() {
  return (
    <div className="flex w-full flex-col">
      <BreadcrumbCommitteeMembersAdd />
      <div className="rounded-md border-2 border-muted bg-white p-4">
        <FormAddCommitteeMember />
      </div>
    </div>
  )
}
