import React, { useState } from "react"
import { MdDelete } from "react-icons/md"
import { useParams } from "react-router-dom"
import useProjectsActions from "../../hooks/useProjectsActions"
import { FaSpinner } from "react-icons/fa"
import { toastError, toastSuccess } from "../../../../helper"

export default function ButtonRemoveProjectAttachment({
  attachmentId,
  fetchProjectAttachments,
}) {
  const { committee_id, project_id } = useParams()
  const { removeProjectAttachment } = useProjectsActions()

  const [isRemoveing, setIsRemoveing] = useState(false)

  const handleOnRemoveAttachment = async () => {
    setIsRemoveing(true)
    try {
      await removeProjectAttachment(committee_id, project_id, attachmentId)
      toastSuccess("تم حذف ملف المرفق بنجاح")
      fetchProjectAttachments()
    } catch (error) {
      if (!error.response && !error.request) {
        toastError("لم يتم الاتصال بالخادم. يرجى التحقق من الاتصال بالإنترنت.")
      } else if (error.response) {
        toastError(error.response.data.error)
      } else if (error.request) {
        toastError("لم يتم استلام استجابة من الخادم. حاول مرة أخرى لاحقًا.")
      } else {
        toastError("حدث خطأ غير متوقع. حاول مرة أخرى لاحقًا.")
      }
    } finally {
      setIsRemoveing(false) // Reset the remove state
    }
  }

  return (
    <button
      onClick={handleOnRemoveAttachment}
      disabled={isRemoveing}
      className={`flex w-full items-center gap-2 rounded-md border-2 border-danger bg-danger/10 p-1 text-danger hover:bg-danger/20 disabled:cursor-progress disabled:border-muted disabled:bg-light disabled:text-muted`}
    >
      {isRemoveing ? (
        <FaSpinner
          size={16}
          className="animate-spin"
        />
      ) : (
        <MdDelete size={16} />
      )}
    </button>
  )
}
