import React, { useState } from "react"
import SelectCommitteeMembersNotAdded from "../SelectCommitteeMembersNotAdded"
import { useNavigate, useParams } from "react-router-dom"
import { useCommitteeMemberActions } from "../../hooks"
import { toastError, toastSuccess } from "../../../../helper"
import SelectCommitteeMembersType from "../../../core/components/UI/SelectCommitteeMembersType"
import { FaSpinner } from "react-icons/fa6"

export default function FormAddCommitteeMember() {
  const { committee_id } = useParams()
  const navigate = useNavigate()

  const { addMember, inProgress } = useCommitteeMemberActions()

  const [memberId, setMemberId] = useState(null)
  const [role, setRole] = useState(null)

  async function handleOnSubmitAddCommitteeMemberForm(event) {
    try {
      event.preventDefault()
      const payload = {
        role,
      }
      const message = await addMember(committee_id, memberId, payload)
      if (message) {
        toastSuccess(message)
        navigate(`/committees/${committee_id}/members`)
      }
    } catch (error) {
      if (!error.response && !error.request) {
        toastError("لم يتم الاتصال بالخادم. يرجى التحقق من الاتصال بالإنترنت.")
      } else if (error.response) {
        toastError(error.response.data.error)
      } else if (error.request) {
        toastError("لم يتم استلام استجابة من الخادم. حاول مرة أخرى لاحقًا.")
      } else {
        toastError("حدث خطأ غير متوقع. حاول مرة أخرى لاحقًا.")
      }
    }
  }
  return (
    <form onSubmit={handleOnSubmitAddCommitteeMemberForm}>
      <div className="flex flex-col gap-4">
        <div className="flex w-full items-start gap-2">
          <div className="flex w-full flex-col gap-0.5">
            <label
              className="font-bold"
              htmlFor="ID_MEMBER"
            >
              العضو <span className="text-danger">*</span>
            </label>
            <SelectCommitteeMembersNotAdded
              value={memberId}
              onChange={(memberId) => {
                setMemberId(memberId)
              }}
            />
          </div>
          <div className="flex w-full flex-col gap-0.5">
            <label
              className="font-bold"
              htmlFor="ID_MEMBER_ROLE"
            >
              الرتبة <span className="text-danger">*</span>
            </label>
            <SelectCommitteeMembersType
              value={role}
              onChange={(role) => {
                setRole(role)
              }}
            />
          </div>
        </div>
        <button
          type="submit"
          disabled={inProgress}
          className="flex w-fit items-center rounded-md border-2 border-primary-hover bg-primary px-4 py-3 text-white transition duration-200 hover:bg-primary-hover disabled:cursor-not-allowed disabled:border-muted disabled:bg-light disabled:text-dark"
        >
          {inProgress ? (
            <div className="flex items-center">
              <FaSpinner
                size={16}
                className="ml-4 animate-spin"
              />
              <span>جاري إضافة عضو...</span>
            </div>
          ) : (
            <span>إضافة عضو</span>
          )}
        </button>
      </div>
    </form>
  )
}
