import { useLayoutEffect } from "react"
import { Link, useSearchParams } from "react-router-dom"
import Pagination from "../../core/components/UI/Pagination"
import {
  ButtonUsersFilter,
  UsersGrid,
  useUserActions,
  useUserContext,
} from ".."
import BreadcrumbUsers from "../components/users-page/BreadcrumbUsers"
import NotFoundUsers from "../components/NotFoundUsers"

export default function UsersPage() {
  const [searchParams] = useSearchParams()

  const { userState } = useUserContext()
  const { getUsers } = useUserActions()

  useLayoutEffect(() => {
    getUsers()
  }, [searchParams])

  return (
    <div className="flex flex-col w-full gap-2">
      <BreadcrumbUsers />
      <div className="flex items-center justify-between">
        <Link
          to="/users/create"
          className="px-4 py-2 font-bold text-white border-2 rounded-md border-primary-hover bg-primary hover:bg-primary-hover"
        >
          إضافة مستخدم
        </Link>
        <ButtonUsersFilter />
      </div>
      <UsersGrid
        isLoading={userState.isLoading}
        users={userState.users}
      />
      <Pagination
        isLoading={userState.isLoading}
        pagination={userState?.pagination}
      />
    </div>
  )
}
