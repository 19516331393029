import { Link } from "react-router-dom"
import {
  datetimeAgo,
  datetimeFormat,
  getProjectTypeByKey,
} from "../../../../helper"
import { FaEye } from "react-icons/fa6"
import { RiDownloadLine, RiQuestionAnswerFill } from "react-icons/ri"

export default function ProjectListItem({ project }) {
  return (
    <Link
      to={`/committees/${project.committee_id}/projects/${project.id}`}
      key={project.id}
      className="group relative flex flex-col gap-2 rounded-md border-2 border-muted bg-white p-2 hover:border-primary"
    >
      <div className="absolute left-0 top-0"></div>
      <div>
        <div className="flex justify-between">
          <div className="flex items-center gap-2">
            <div className="h-6 overflow-hidden rounded-[4px] border-2 border-muted">
              <img
                className="h-full w-full"
                alt={project.user.country.name_ar}
                title={project.user.country.name_ar}
                src={`${process.env.REACT_APP_BASE_URL}/storage/${project.user.country.image}`}
              />
            </div>
            <em
              title={datetimeFormat(project.created_at)}
              className="text-xs text-secondary"
            >
              {datetimeAgo(project.created_at)}
            </em>
          </div>
        </div>
        <div className="text-sm font-bold">
          {project.user.first_name_ar} {project.user.last_name_ar}
        </div>
      </div>
      <div className="flex flex-col gap-1 group-hover:text-primary">
        <h2 className="text-xl font-bold">{project.title_ar}</h2>
        <div>{project.reference}</div>
      </div>
      <div className="flex items-center justify-between">
        <div className="flex items-center gap-2 text-xs">
          <div className="rounded-[5px] border-2 bg-light px-3 py-1">
            {project.category}
          </div>
          <div className="rounded-[5px] border-2 bg-light px-3 py-1">
            {getProjectTypeByKey(project.type)}
          </div>
          <div className="rounded-[5px] border-2 bg-light px-3 py-1">
            مرحلة – {project.stage}
          </div>
        </div>
        <div className="grid grid-cols-3 gap-2">
          <div className="col-span-1 flex !w-20 justify-end gap-2 px-2 py-1">
            <div>{project.views_count}</div>
            <div>
              <FaEye size={16} />
            </div>
          </div>
          <div className="col-span-1 flex justify-end gap-2 px-2 py-1">
            <div>{project.comments_count}</div>
            <div>
              <RiQuestionAnswerFill size={16} />
            </div>
          </div>
          <div className="col-span-1 flex justify-end gap-2 px-2 py-1">
            <div>{project.downloads_count}</div>
            <div>
              <RiDownloadLine size={16} />
            </div>
          </div>
        </div>
      </div>
    </Link>
  )
}
