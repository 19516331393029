import { BiEdit } from "react-icons/bi"
import { Link, useParams } from "react-router-dom"

export default function ButtonEditProject() {
  const { committee_id, project_id } = useParams()
  return (
    <Link
      to={`/committees/${committee_id}/projects/${project_id}/edit`}
      className="flex items-center gap-2 rounded-md border-2 border-muted-hover bg-white px-4 py-2 pr-2 font-bold hover:bg-muted"
    >
      <BiEdit size={16} />
      <div>تعديل</div>
    </Link>
  )
}
