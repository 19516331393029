import { useState } from "react"
import ModalCommentDetails from "./ModalCommentDetails"

export default function ButtonCommentDetails({ detail }) {
  const [isCommentDetailsModelOpen, setIsCommentDetailsModelOpen] =
    useState(false)

  const handleOnClickOpenCommentDetailsModal = () => {
    setIsCommentDetailsModelOpen(true)
  }

  const handleOnClickCloseCommentDetailsModal = () => {
    setIsCommentDetailsModelOpen(false)
  }
  return (
    <>
      <button
        className="border-r-2 border-muted bg-light px-2 py-1 hover:bg-muted"
        onClick={handleOnClickOpenCommentDetailsModal}
      >
        التفاصيل
      </button>
      <ModalCommentDetails
        isOpen={isCommentDetailsModelOpen}
        onClose={handleOnClickCloseCommentDetailsModal}
        detail={detail}
      />
    </>
  )
}
