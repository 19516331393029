import { useCallback, useState } from "react"
import { datetimeFormat } from "../../../../helper"
import { FaFolder, FaFolderOpen } from "react-icons/fa"

import ButtonCommitteeFolderOptions from "./ButtonCommitteeFolderOptions"
import CommitteeFolderDocumentsList from "./CommitteeFolderDocumentsList"

export default function CommitteeFoldersListItem({ folder }) {
  const [isOpen, setIsOpen] = useState(false)
  const handleOnToggleFolderOpen = useCallback(() => {
    if (folder.documents) {
      setIsOpen((prev) => !prev)
    }
  }, [folder.documents])
  return (
    <div
      id={folder.id}
      className="rounded-md border-2 border-muted bg-white"
    >
      <button
        className={`${
          !folder.documents && "cursor-default"
        } ${isOpen && "border-b-2"} flex w-full items-center justify-between p-2`}
        onClick={handleOnToggleFolderOpen}
      >
        <div className="mr-2 flex items-center gap-2">
          {isOpen ? <FaFolderOpen size={22} /> : <FaFolder size={22} />}
          <h2 className="line-clamp-1 text-sm font-bold">{folder.name}</h2>
        </div>
        <div className="flex items-center gap-2">
          <div className="line-clamp-1">
            {datetimeFormat(folder.created_at)}
          </div>
          <ButtonCommitteeFolderOptions folder={folder} />
        </div>
      </button>
      {isOpen && <CommitteeFolderDocumentsList documents={folder.documents} />}
    </div>
  )
}
