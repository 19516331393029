import { useState } from "react"
import { USER_ACTIONS, useUserContext, UserService } from ".."
import { useSearchParams } from "react-router-dom"

export default function useUserActions() {
  const [searchParams, setSearchParams] = useSearchParams()
  const { userDispatch } = useUserContext()
  const [inProgress, setInProgress] = useState(false)

  const getUsers = async () => {
    try {
      userDispatch({
        type: USER_ACTIONS.SET_IS_LOADING,
        payload: { isLoading: true },
      })
      const { data } = await UserService.getUsers(searchParams.toString())

      userDispatch({
        type: USER_ACTIONS.SET_USERS,
        payload: {
          users: data.users,
          pagination: {
            links: data.links,
            current_page: data.current_page,
            first_page: data.first_page,
            last_page: data.last_page,
          },
        },
      })
    } catch (error) {
    } finally {
      userDispatch({
        type: USER_ACTIONS.SET_IS_LOADING,
        payload: { isLoading: false },
      })
    }
  }

  const changeAccountStatus = async (userId, isActive) => {
    try {
      setInProgress(true)
      await UserService.changeAccountStatus(userId, isActive)
      await getUsers()
    } catch (error) {
      throw error
    } finally {
      setInProgress(false)
    }
  }

  const deleteUser = async (userId) => {
    try {
      setInProgress(true)
      await UserService.deleteUser(userId)
      await getUsers()
    } catch (error) {
      throw error
    } finally {
      setInProgress(false)
    }
  }

  const createUser = async (user) => {
    try {
      setInProgress(true)
      const { data } = await UserService.createUser(user)
      return data.message
    } catch (error) {
      throw error
    } finally {
      setInProgress(false)
    }
  }

  const getUser = async (userId) => {
    try {
      const { data } = await UserService.getUser(userId)
      return data.user
    } catch (error) {
      throw error
    } finally {
      setInProgress(false)
    }
  }

  const updateUser = async (userId, updatedData) => {
    try {
      setInProgress(true)
      const { data } = await UserService.updateUser(userId, updatedData)
      return data.message
    } catch (error) {
      throw error
    } finally {
      setInProgress(false)
    }
  }

  const changeUserPassword = async (userId, payload) => {
    try {
      setInProgress(true)
      const { data } = await UserService.changePassword(userId, payload)
      return data.message
    } catch (error) {
      throw error
    } finally {
      setInProgress(false)
    }
  }

  return {
    getUsers,
    changeAccountStatus,
    deleteUser,
    createUser,
    changeUserPassword,
    getUser,
    updateUser,
    inProgress,
  }
}
