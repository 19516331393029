import { useContext, useReducer } from "react"
import { Outlet } from "react-router-dom"
import { CommitteeContext, committeeInitialState, committeeReducer } from "."

export const useCommitteeContext = () => useContext(CommitteeContext)

// UserProvider to wrap the app and provide context
export const CommitteeProvider = () => {
  const [committeeState, committeeDispatch] = useReducer(
    committeeReducer,
    committeeInitialState,
  )

  return (
    <CommitteeContext.Provider value={{ committeeState, committeeDispatch }}>
      <Outlet />
    </CommitteeContext.Provider>
  )
}
