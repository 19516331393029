export default function UserAccountInformationsItem({ label, value }) {
  return (
    <div className="flex w-full flex-col gap-1">
      <div className="font-bold">{label}</div>
      <div className="rounded-md border-2 border-muted bg-light px-6 py-3">
        {value ?? "--"}
      </div>
    </div>
  )
}
