import { Link } from "react-router-dom"
import { MdEdit, MdRefresh } from "react-icons/md"
import Modal from "../../../core/components/UI/Modal"
import ButtonChangeAccountStatus from "./ButtonChangeAccountStatus"
import ButtonDeleteUser from "./ButtonDeleteUser"

export default function ModalUserOptions({ user, isOpen, onClose }) {
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
    >
      <div className="flex min-w-80 flex-col gap-2 rounded-md border-2 border-muted p-2">
        <ButtonChangeAccountStatus user={user} />
        <Link
          to={`/users/${user.id}/edit`}
          className={`flex items-center gap-2 rounded-md border-2 border-light px-4 py-2 hover:border-muted hover:bg-light`}
        >
          <MdEdit size={20} />
          <span>تعديل</span>
        </Link>
        <Link
          to={`/users/${user.id}/change/password`}
          className={`flex items-center gap-2 rounded-md border-2 border-light px-4 py-2 hover:border-muted hover:bg-light`}
        >
          <MdRefresh size={20} />
          <span>تغيير كلمة مرور</span>
        </Link>
        <ButtonDeleteUser
          user={user}
          onClose={onClose}
        />
      </div>
    </Modal>
  )
}
