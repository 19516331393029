import { useLayoutEffect } from "react"
import { useParams, useSearchParams } from "react-router-dom"
import BreadcrumbProject from "../components/projects-page/BreadcrumbProject"
import { useProjectsContext } from ".."
import { useProjectsActions } from "../hooks"
import { ButtonProjectsFilter } from "../components"
import { LoaderProjectList, ProjectList } from "../components/projects-page"
import Pagination from "../../core/components/UI/Pagination"
import NotFoundProjects from "../components/NotFoundProjects"

export default function ProjectsPage() {
  const [searchParams] = useSearchParams()
  const { committee_id } = useParams()

  const { getProjects } = useProjectsActions()

  const { projectsState } = useProjectsContext()

  useLayoutEffect(() => {
    getProjects(committee_id)
  }, [committee_id, searchParams])

  return (
    <div className="flex flex-col w-full gap-2">
      <BreadcrumbProject />
      <div className="flex items-center justify-between">
        <div />
        <ButtonProjectsFilter />
      </div>
      {projectsState.isLoading ? (
        <LoaderProjectList />
      ) : (
        <ProjectList projects={projectsState.projects} />
      )}

      <Pagination
        isLoading={projectsState.isLoading}
        pagination={projectsState.pagination}
      />
    </div>
  )
}
