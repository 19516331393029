import { Link } from "react-router-dom"
import { useCommittee } from "../../../committees"

export default function BreadcrumbCommitteeMember() {
  const { committee, isLoading } = useCommittee()
  return (
    <div className="mb-2 flex gap-0.5 font-bold">
      <h2>الرئيسية</h2>
      <div>/</div>
      <Link
        className="hover:text-success"
        to={"/committees"}
      >
        اللجان الفنية العربية
      </Link>
      <div>/</div>
      {isLoading ? (
        <h2 className="animate-pulse">اللجنة الفنية العربية لمواصفات ...</h2>
      ) : (
        <h2>{committee?.title}</h2>
      )}
      <div>/</div>
      <h2 className="text-success">أعضاء اللجنة</h2>
    </div>
  )
}
