import { Link } from "react-router-dom"
import { useCommittee } from "../hooks"
import {
  FormEditCommittee,
  LoaderEditCommitteeForm,
} from "../components/committees-edit-page"

export default function CommitteesEditPage() {
  const { isLoading, committee } = useCommittee()

  return (
    <div className="flex w-full flex-col gap-2">
      <div className="flex gap-0.5 font-bold">
        <Link
          className="hover:text-success"
          to={"/"}
        >
          الرئيسية
        </Link>
        <div>/</div>
        <Link
          to={"/committees?page=1"}
          className="hover:text-success"
        >
          اللجان الفنية العربية
        </Link>
        <div>/</div>
        {isLoading ? <div>--</div> : <h2>{committee?.title}</h2>}
        <div>/</div>
        <h2 className="text-success">تعديل</h2>
      </div>
      <div className="rounded-md border-2 border-muted bg-white p-4">
        {isLoading ? (
          <LoaderEditCommitteeForm />
        ) : (
          <FormEditCommittee committee={committee} />
        )}
      </div>
    </div>
  )
}
