import React, { useEffect, useState } from "react"
import Select from "react-tailwindcss-select"

const genders = [
  { value: "ذكر", label: "ذكر" },
  { value: "أنثى", label: " أنثى" },
]

export default function SelectGender({ value, onChange }) {
  const [selectedGender, setSelectedGender] = useState(
    genders.find((gender) => gender.value === value) || null,
  )
  const handleOnChange = (option) => {
    setSelectedGender(option)
    onChange(option?.value ?? null)
  }
  useEffect(() => {
    if (value) {
      const defaultGender = genders.find((gender) => gender.value === value)
      setSelectedGender(defaultGender || null)
    } else {
      setSelectedGender(null)
    }
  }, [value])

  return (
    <Select
      value={selectedGender}
      onChange={handleOnChange}
      options={genders}
      isSearchable={false}
      placeholder="نوع..."
      classNames={{
        menuButton: ({ isDisabled }) =>
          `focus:outline-none focus:ring-2 focus:ring-primary border-2 border-muted py-0.5 pr-4 bg-light flex rounded-md bg-white`,
        searchContainer: "relative w-full",
        searchBox:
          "w-full outline-none py- px-2 rounded-md border-2 border-muted py-2 bg-light mb-2",
        searchIcon: "absolute left-2 w-5 h-5 top-[8px] text-dark ",
        menu: `absolute z-50 -mt-1 w-full shadow-lg rounded-b-md bg-white border-2 border-muted py-2`,
      }}
      formatOptionLabel={(data) => (
        <li
          className={`z-50 mt-1 line-clamp-1 block cursor-pointer select-none rounded-md px-4 py-3 transition duration-200 ${data.isSelected ? `bg-primary font-bold text-white` : ``}`}
        >
          <div className="flex items-center">
            <div>{data.label}</div>
          </div>
        </li>
      )}
    />
  )
}
