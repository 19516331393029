import { useRef } from "react"
import { useNavigate, useParams } from "react-router-dom"
import {
  generateRandomPassword,
  toastError,
  toastSuccess,
} from "../../../../helper"
import { GrPowerReset } from "react-icons/gr"
import { FaSpinner } from "react-icons/fa"
import { useUserActions } from "../.."

export default function FormChangeUserPassword() {
  const { user_id } = useParams()
  const navigate = useNavigate()
  const { changeUserPassword, inProgress } = useUserActions()
  const passwordRef = useRef()

  const handleOnSubmitChangePasswordForm = async (event) => {
    try {
      event.preventDefault()
      const payload = {
        password: passwordRef.current.value,
      }
      const message = await changeUserPassword(user_id, payload)

      if (message) {
        toastSuccess(message)
        navigate("/users?page=1")
      }
    } catch (error) {
      if (!error.response && !error.request) {
        toastError("لم يتم الاتصال بالخادم. يرجى التحقق من الاتصال بالإنترنت.")
      } else if (error.response) {
        toastError(error.response.data.error)
      } else if (error.request) {
        toastError("لم يتم استلام استجابة من الخادم. حاول مرة أخرى لاحقًا.")
      } else {
        toastError("حدث خطأ غير متوقع. حاول مرة أخرى لاحقًا.")
      }
    }
  }

  const handleOnClickGenerateNewPasswordButton = () => {
    passwordRef.current.value = generateRandomPassword()
  }
  return (
    <form onSubmit={handleOnSubmitChangePasswordForm}>
      <div className="flex flex-col gap-4">
        <div className="flex items-end gap-2">
          <div className="flex w-full flex-col gap-1">
            <label
              className="font-bold"
              htmlFor="ID_PASSWORD"
            >
              كلمة المرور الجديدة <span className="text-danger">*</span>
            </label>
            <input
              ref={passwordRef}
              type="text"
              id="ID_PASSWORD"
              name="password"
              className="w-full rounded-md border-2 border-muted px-6 py-3 focus:outline-none focus:ring-2 focus:ring-primary"
            />
          </div>
          <button
            onClick={handleOnClickGenerateNewPasswordButton}
            className="rounded-md border-2 border-muted bg-light p-2.5 hover:bg-muted"
            type="button"
          >
            <GrPowerReset size={20} />
          </button>
        </div>
        <button
          type="submit"
          disabled={inProgress}
          className="flex w-fit rounded-md border-2 border-primary-hover bg-primary px-4 py-3 text-white hover:bg-primary-hover disabled:border-muted disabled:bg-light disabled:text-dark"
        >
          {inProgress ? (
            <div className="flex items-center">
              <FaSpinner
                size={16}
                className="ml-2 animate-spin"
              />
              <div>جاري تغيير كلمة مرور...</div>
            </div>
          ) : (
            "تغيير كلمة مرور"
          )}
        </button>
      </div>
    </form>
  )
}
