import { Link } from "react-router-dom"
import FormCreateCommittee from "../components/committees-create-page/FormCreateCommittee"

export default function CommitteesCreatePage() {
  return (
    <div className="flex w-full flex-col">
      <div className="mb-2 flex gap-0.5 font-bold">
        <h2>الرئيسية</h2>
        <div>/</div>
        <Link
          to={"/committees?page=1"}
          className="hover:text-success"
        >
          اللجان الفنية العربية
        </Link>
        <div>/</div>
        <h2 className="text-success">إنشاء لجنة جديد</h2>
      </div>
      <div className="rounded-md border-2 border-muted bg-white p-4">
        <FormCreateCommittee />
      </div>
    </div>
  )
}
