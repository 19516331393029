import { useState } from "react"
import CommitteeGlobalDocumentService from "../services/CommitteeGlobalDocumentService"
import download from "downloadjs"

export default function useCommitteeGlobalDocumentActions() {
  const [inProgress, setInProgress] = useState(false)

  async function getDocuments() {
    try {
      const { data } = await CommitteeGlobalDocumentService.getDocuments()
      return data.documents
    } catch (error) {
      throw error
    }
  }

  async function createDocument(payload) {
    try {
      setInProgress(true)
      const { data } =
        await CommitteeGlobalDocumentService.createDocument(payload)
      return data.message
    } catch (error) {
      throw error
    } finally {
      setInProgress(false)
    }
  }

  async function downloadDocument(documentId, fileName) {
    try {
      setInProgress(true)
      const response =
        await CommitteeGlobalDocumentService.downloadDocument(documentId)
      download(response.data, fileName, response.headers["Content-Type"])
    } catch (error) {
      throw error
    } finally {
      setInProgress(false)
    }
  }
  async function deleteDocument(documentId) {
    try {
      setInProgress(true)

      const { data } =
        await CommitteeGlobalDocumentService.deleteDocument(documentId)
      return data.message
    } catch (error) {
      throw error
    } finally {
      setInProgress(false)
    }
  }

  return {
    getDocuments,
    createDocument,
    downloadDocument,
    deleteDocument,
    inProgress,
  }
}
