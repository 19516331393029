import { useState } from "react"
import { datetimeFormat, getProjectTypeByKey } from "../../../../helper"
import ButtonDownloadProjectVersion from "../project-details-page/ButtonDownloadProjectVersion"

export default function ProjectVersionsListItem({ version }) {
  const [isOpen, setIsOpen] = useState(false)
  function handleOnOpenVersionDetails() {
    setIsOpen(!isOpen)
  }
  return (
    <div className="flex flex-col gap-2">
      <div className="overflow-hidden rounded-md border-2 border-muted">
        <button
          onClick={handleOnOpenVersionDetails}
          className={`flex w-full gap-2 ${isOpen ? "border-b-2" : ""} border-muted bg-white p-4 font-bold hover:bg-muted`}
        >
          <div>{version.stage}</div>
          <div>-</div>
          <div>{datetimeFormat(version.created_at)}</div>
        </button>
        {isOpen && (
          <div className="flex flex-col bg-white">
            <div className="flex flex-col gap-1 px-8 py-4">
              <h2 className="text-xl font-bold">{version.title_ar || "--"}</h2>
              <h2 className="text-xl font-bold">{version.title_en || "--"}</h2>
            </div>
            <div className="flex flex-col p-4">
              <div>
                <h3 className="text-base font-bold underline">
                  تفاصيل المشروع
                </h3>
                <div className="grid grid-cols-12 gap-2 p-4">
                  <div className="col-span-5 font-bold md:col-span-2">
                    نوع المشروع
                  </div>
                  <div className="col-span-5 md:col-span-10">
                    {getProjectTypeByKey(version.type)?.split("/")[0]}
                  </div>
                  <div className="col-span-5 font-bold md:col-span-2">
                    صفة المشروع
                  </div>
                  <div className="col-span-5 md:col-span-10">
                    {getProjectTypeByKey(version.type)?.split("/")[1]}
                  </div>
                  <div className="col-span-5 font-bold md:col-span-2">
                    نوع الوثيقة
                  </div>
                  <div className="col-span-5 md:col-span-10">
                    {version.category}
                  </div>
                  <div className="col-span-5 font-bold md:col-span-2">
                    اللغة
                  </div>
                  <div className="col-span-5 md:col-span-10">
                    {version.language || "غير محددة"}
                  </div>
                  <div className="col-span-5 font-bold md:col-span-2">
                    المراحل
                  </div>
                  <div className="col-span-5 md:col-span-10">
                    {version.stage}
                  </div>
                </div>
              </div>
              <div>
                <h3 className="text-base font-bold underline">
                  المرجع الرئيسي
                </h3>
                <p className="p-4">{version.reference}</p>
              </div>
              <div>
                <h3 className="text-base font-bold underline">مراجع أخرى </h3>
                {version?.references?.length > 0 ? (
                  <ul className="flex list-disc flex-col gap-2 px-8 py-4">
                    {version.references.map((reference, index) => (
                      <li key={index}>{reference}</li>
                    ))}
                  </ul>
                ) : (
                  <p className="p-4">لا توجد مراجع أخرى</p>
                )}
              </div>
              <div>
                <h3 className="text-base font-bold underline">
                  التصنيف الدولي للمواصفات (ICS)
                </h3>
                <p className="p-4">{version.ics || "غير محدد"}</p>
              </div>
              <div>
                <h3 className="text-base font-bold underline">مجال المشروع</h3>
                <p className="p-4">{version.domain || "غير محدد"}</p>
              </div>
              <div>
                <h3 className="text-base font-bold underline">
                  ملاحظات إضافية
                </h3>
                <p className="p-4">{version.description || "لا توجد"}</p>
              </div>
              <div>
                <ButtonDownloadProjectVersion version={version} />
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}
