import React from "react"
import PropTypes from "prop-types"
import { FaSpinner } from "react-icons/fa"

function SubmitButton({ inProgress, label, progressLabel }) {
  return (
    <button
      type="submit"
      disabled={inProgress}
      className="flex w-fit items-center rounded-md border-2 border-primary-hover bg-primary px-4 py-3 text-white transition duration-200 hover:bg-primary-hover disabled:cursor-not-allowed disabled:border-muted disabled:bg-light disabled:text-dark"
    >
      {inProgress ? (
        <div className="flex items-center">
          <FaSpinner
            size={16}
            className="ml-4 animate-spin"
          />
          <span>{progressLabel}</span>
        </div>
      ) : (
        <span>{label}</span>
      )}
    </button>
  )
}

SubmitButton.propTypes = {
  inProgress: PropTypes.bool,
  label: PropTypes.string.isRequired,
  progressLabel: PropTypes.string.isRequired,
}

SubmitButton.defaultProps = {
  inProgress: false,
}

export default SubmitButton
