import React, { useRef } from "react"
import { Link, useNavigate, useParams } from "react-router-dom"
import useCommitteeFolderActions from "../hooks/useCommitteeFolderActions"
import SubmitButton from "../../core/components/UI/Submit"
import toast from "react-hot-toast"
import useCommitteeFolder from "../hooks/useCommitteeFolder"
import { toastSuccess } from "../../../helper"

export default function CommitteeFolderRenamePage() {
  const { committee_id, folder_id } = useParams()
  const { isLoading, folder } = useCommitteeFolder()
  const navigate = useNavigate()

  const { renameCommitteeFolder, inProgress } = useCommitteeFolderActions()

  const folderNameRef = useRef(null)

  const handleOnSubmitRenameFolder = async (event) => {
    try {
      event.preventDefault()
      const payload = {
        name: folderNameRef.current.value,
      }
      const message = await renameCommitteeFolder(
        committee_id,
        folder_id,
        payload,
      )
      if (message) {
        toastSuccess(message)
        navigate(`/committees/${committee_id}/folders`)
      }
      
    } catch (error) {
      toast.error(error.response.data.error)
    }
  }
  return (
    <div className="flex w-full flex-col gap-2">
      <div className="mb-2 flex gap-0.5 font-bold">
        <Link
          className="hover:text-success"
          to="/"
        >
          الرئيسية
        </Link>
        <div>/</div>
        <Link
          className="hover:text-success"
          to={`/committees`}
        >
          اللجنة الفنية العربية
        </Link>
        <div>/</div>
        <Link
          className="hover:text-success"
          to={`/committees/${committee_id}`}
        >
          {folder?.committee?.title}
        </Link>
        <div>/</div>
        <Link
          className="hover:text-success"
          to={`/committees/${committee_id}/folders`}
        >
          وثائق اللجنة
        </Link>
        <div>/</div>
        <h2 className="text-success">إعادة تسمية المجلد</h2>
      </div>
      <div className="rounded-md border-2 border-muted bg-white p-4">
        <form onSubmit={handleOnSubmitRenameFolder}>
          <div className="flex flex-col gap-4">
            <div className="flex w-full flex-col gap-1">
              <label
                className="font-bold"
                htmlFor="folder_name"
              >
                اسم المجلد الجديد <span className="text-danger">*</span>
              </label>
              <input
                ref={folderNameRef}
                id="folder_name"
                name="folder_name"
                type="text"
                defaultValue={folder?.name}
                className="w-full rounded-md border-2 border-muted px-6 py-3 focus:outline-none focus:ring-2 focus:ring-primary"
              />
            </div>
            <SubmitButton
              label="إعادة تسمية المجلد"
              inProgress={inProgress}
              progressLabel="جاري إعادة تسمية المجلد..."
            />
          </div>
        </form>
      </div>
    </div>
  )
}
