import { CgClose } from "react-icons/cg"

export default function ReferenceInput({
  reference,
  index,
  onChange,
  onRemove,
}) {
  return (
    <div className="w-full">
      <div className="flex gap-[8px]">
        <input
          placeholder={`مرجع ${index + 1}`}
          type="text"
          name={`project_reference_${reference.id}`}
          className="w-full rounded-md border-2 border-muted px-6 py-3 focus:outline-none focus:ring-2 focus:ring-primary"
          value={reference.value}
          onChange={(e) => onChange(reference.id, e)}
        />
        {index > 0 && (
          <button
            type="button"
            onClick={() => onRemove(reference.id)}
            className="rounded-[6px] border-2 border-danger-hover bg-danger/80 px-[10px] py-[6px] text-white hover:bg-danger"
          >
            <CgClose size={22} />
          </button>
        )}
      </div>
    </div>
  )
}
