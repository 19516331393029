import React, { useState } from "react"
import useCommitteeGlobalDocumentActions from "../../hooks/useCommitteeGlobalDocumentActions"
import { toastError, toastSuccess } from "../../../../helper"
import { FaSpinner } from "react-icons/fa"
import { MdDownload } from "react-icons/md"

export default function ButtonDownloadGlobalDocument({
  documentId,
  documentName,
}) {
  const { inProgress, downloadDocument } = useCommitteeGlobalDocumentActions()

  const handleOnDownloadAttachment = async () => {
    try {
      await downloadDocument(documentId, documentName)
      toastSuccess("تم تحميل الوثيقة بنجاح")
    } catch (error) {
      if (!error.response && !error.request) {
        toastError("لم يتم الاتصال بالخادم. يرجى التحقق من الاتصال بالإنترنت.")
      } else if (error.response) {
        toastError("الملف غير موجود")
      } else if (error.request) {
        toastError("لم يتم استلام استجابة من الخادم. حاول مرة أخرى لاحقًا.")
      } else {
        toastError("حدث خطأ غير متوقع. حاول مرة أخرى لاحقًا.")
      }
    }
  }

  return (
    <button
      onClick={handleOnDownloadAttachment}
      disabled={inProgress}
      className="rounded-md border-2 border-muted bg-light p-1 hover:bg-muted disabled:bg-muted"
    >
      {inProgress ? (
        <FaSpinner
          size={16}
          className="animate-spin"
        />
      ) : (
        <MdDownload size={16} />
      )}
    </button>
  )
}
