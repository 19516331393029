import { Dialog, DialogPanel } from "@headlessui/react"
import React, { Children } from "react"

export default function Modal({ isOpen, onClose, children }) {
  return (
    <Dialog
      open={isOpen}
      onClose={() => onClose()}
      className="relative z-50 "
    >
      <div
        className="fixed inset-0 bg-dark/50 backdrop-blur"
        aria-hidden="true"
      />
      <div className="fixed inset-0 flex w-screen items-center justify-center p-4">
        <DialogPanel className="overflow-hidden rounded-md bg-white">
          {children}
        </DialogPanel>
      </div>
    </Dialog>
  )
}
