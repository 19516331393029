import { useState } from "react"
import { FiFilter } from "react-icons/fi"
import { ModalCommitteesFilter } from "."

export default function ButtonCommitteesFilter() {
  const [iscommitteesFilterOpen, setIscommitteesFilterOpen] = useState(false)

  const handleOnClickOpencommitteesFilter = () => {
    setIscommitteesFilterOpen(true)
  }

  const handleOnClickClosecommitteesFilter = () => {
    setIscommitteesFilterOpen(false)
  }

  return (
    <>
      <button
        onClick={handleOnClickOpencommitteesFilter}
        className="flex items-center gap-2 rounded-md border-2 border-muted bg-white p-2 pl-4 text-dark hover:bg-light"
      >
        <FiFilter size={16} />
        <div className="font-bold">فلتر البحث</div>
      </button>
      <ModalCommitteesFilter
        isOpen={iscommitteesFilterOpen}
        onClose={handleOnClickClosecommitteesFilter}
      />
    </>
  )
}
