import { COMMITTEE_GLOBAL_DOCUMENTS_ACTIONS } from "."

export function committeeGlobalDocumentsReducer(state, action) {
  switch (action.type) {
    case COMMITTEE_GLOBAL_DOCUMENTS_ACTIONS.SET_DOCUMENTS:
      return {
        ...state,
        documents: action.payload.documents,
        pagination: action.payload.pagination,
      }

    case COMMITTEE_GLOBAL_DOCUMENTS_ACTIONS.SET_IS_LOADING:
      return { ...state, isLoading: action.payload.isLoading }
    default:
      return state
  }
}
