import { useRef, useState } from "react"
import { useNavigate } from "react-router-dom"
import { useCommitteeActions } from "../../hooks"
import { toastError, toastSuccess } from "../../../../helper"
import InputSelectCommitteeImage from "../UI/InputSelectCommitteeImage"
import { FaSpinner } from "react-icons/fa"

export default function FormEditCommittee({ committee }) {
  const navigate = useNavigate()
  const { updateCommittee, inProgress } = useCommitteeActions()

  const [imageFile, setImageFile] = useState(null)
  const titleRef = useRef(null)
  const symbolRef = useRef(null)

  async function onSubmitUpdateNewCommittee(event) {
    try {
      event.preventDefault()
      const payload = {
        title: titleRef.current.value,
        symbol: symbolRef.current.value,
      }

      if (imageFile) {
        payload.image = imageFile
      }

      const message = await updateCommittee(committee.id, payload)

      if (message) {
        toastSuccess(message)
        navigate("/committees?page=1")
      }
    } catch (error) {
      if (!error.response && !error.request) {
        toastError("لم يتم الاتصال بالخادم. يرجى التحقق من الاتصال بالإنترنت.")
      } else if (error.response) {
        toastError(error.response.data.error)
      } else if (error.request) {
        toastError("لم يتم استلام استجابة من الخادم. حاول مرة أخرى لاحقًا.")
      } else {
        toastError("حدث خطأ غير متوقع. حاول مرة أخرى لاحقًا.")
      }
    }
  }

  const handleImageChange = (file) => {
    setImageFile(file)
  }

  return (
    <form onSubmit={onSubmitUpdateNewCommittee}>
      <div className="flex flex-col gap-4">
        <InputSelectCommitteeImage
          defaultImageUrl={`${process.env.REACT_APP_BASE_URL}/storage/${committee.image}`}
          onChange={handleImageChange}
        />
        <div className="flex w-full flex-col gap-1">
          <label
            className="font-bold"
            htmlFor="ID_TITLE"
          >
            الأسم <span className="text-danger">*</span>
          </label>
          <input
            ref={titleRef}
            type="text"
            id="ID_TITLE"
            name="title"
            defaultValue={committee.title}
            className="w-full rounded-md border-2 border-muted px-6 py-3 focus:outline-none focus:ring-2 focus:ring-primary"
          />
        </div>
        <div className="flex w-full flex-col gap-1">
          <label
            className="font-bold"
            htmlFor="ID_SYMBOL"
          >
            الرمز <span className="text-danger">*</span>
          </label>
          <input
            ref={symbolRef}
            type="text"
            id="ID_SYMBOL"
            name="symbol"
            defaultValue={committee.symbol}
            className="w-full rounded-md border-2 border-muted px-6 py-3 focus:outline-none focus:ring-2 focus:ring-primary"
          />
        </div>
        <button
          type="submit"
          disabled={inProgress}
          className="flex w-fit rounded-md border-2 border-primary-hover bg-primary px-4 py-3 text-white hover:bg-primary-hover disabled:border-muted disabled:bg-light disabled:text-dark"
        >
          {inProgress ? (
            <div className="flex items-center">
              <FaSpinner
                size={16}
                className="ml-2 animate-spin"
              />
              <div>جاري تعديل لجنة...</div>
            </div>
          ) : (
            "تعديل لجنة"
          )}
        </button>
      </div>
    </form>
  )
}
