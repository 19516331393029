import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import Select from "react-tailwindcss-select"
import useCommitteeMembersNotAdded from "../hooks/useCommitteeMembersNotAdded"

function SelectCommitteeMembersNotAdded({ value, onChange, error }) {
  const { isLoading, members, errors } = useCommitteeMembersNotAdded()
  const [selectedOption, setSelectedOption] = useState(null)

  // Sync selected option with incoming value and member list changes
  useEffect(() => {
    if (value && members.length > 0) {
      const matchedMember = members?.find((member) => member.id === value)
      if (matchedMember) {
        setSelectedOption({
          label: `${matchedMember.first_name_ar} ${matchedMember.last_name_ar}`,
          value: matchedMember.id,
        })
      } else {
        setSelectedOption(null)
      }
    } else {
      setSelectedOption(null)
    }
  }, [value, members])

  const handleSelectionChange = (option) => {
    setSelectedOption(option)
    if (onChange) onChange(option?.value ?? null)
  }

  const options = members?.map((member) => ({
    label: `${member.first_name_ar} ${member.last_name_ar}`,
    value: member.id,
  }))

  const formatOptionLabel = ({ label, isSelected }) => (
    <li
      className={`z-50 mt-1 line-clamp-1 block cursor-pointer select-none rounded-md px-4 py-3 transition duration-200 ${isSelected ? "bg-primary font-bold text-white" : ""}`}
    >
      <div className="flex items-center gap-2">{label}</div>
    </li>
  )

  return (
    <Select
      value={selectedOption}
      onChange={handleSelectionChange}
      options={options}
      loading={isLoading}
      isDisabled={isLoading}
      isSearchable
      placeholder="أعضاء..."
      isClearable
      classNames={{
        menuButton: ({ isDisabled }) =>
          `focus:outline-none focus:ring-2 focus:ring-primary border-2 border-muted py-0.5 pr-4 bg-light flex rounded-md bg-white`,
        searchContainer: "relative w-full",
        searchBox:
          "w-full outline-none py- px-2 rounded-md border-2 border-muted py-2 bg-light mb-2",
        searchIcon: "absolute left-2 w-5 h-5 top-[8px] text-dark ",
        menu: `absolute z-50 -mt-1 w-full shadow-lg rounded-b-md bg-white border-2 border-muted py-2`,
      }}
      formatOptionLabel={formatOptionLabel}
    />
  )
}

SelectCommitteeMembersNotAdded.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func,
  error: PropTypes.string,
}

SelectCommitteeMembersNotAdded.defaultProps = {
  value: null,
  onChange: null,
  error: null,
}

export default SelectCommitteeMembersNotAdded
