import { useState } from "react"
import { FiFilter } from "react-icons/fi"
import ModalProjectsFilter from "./ModalProjectsFilter"

export default function ButtonProjectsFilter() {
  const [isProjectsFilterOpen, setIsProjectsFilterOpen] = useState(false)

  const handleOnClickOpenProjectsFilter = () => {
    setIsProjectsFilterOpen(true)
  }

  const handleOnClickCloseProjectsFilter = () => {
    setIsProjectsFilterOpen(false)
  }

  return (
    <>
      <button
        onClick={handleOnClickOpenProjectsFilter}
        className="flex items-center gap-2 rounded-md border-2 border-muted bg-white p-2 pl-4 text-dark hover:bg-light"
      >
        <FiFilter size={16} />
        <div className="font-bold">فلتر البحث</div>
      </button>
      <ModalProjectsFilter
        isOpen={isProjectsFilterOpen}
        onClose={handleOnClickCloseProjectsFilter}
      />
    </>
  )
}
