import React from "react"

export default function LoaderProjectVersionsList() {
  return (
    <>
      {[...Array(24)].map((_, index) => (
        <div className="h-[48px] w-full animate-pulse rounded-md border-2 border-muted bg-white" />
      ))}
    </>
  )
}
