export default function LoaderCommitteeFoldersList() {
  return (
    <div className="flex flex-col gap-2">
      {[...Array(24)].map((_, index) => (
        <div
          key={index}
          className="h-11 w-full animate-pulse rounded-md border-2 border-muted bg-white"
        />
      ))}
    </div>
  )
}
