import { Link } from "react-router-dom"
import { useUser } from "../hooks"
import {
  FormChangeUserPassword,
  LoaderChangeUserPasswordForm,
} from "../components"

export default function UsersChangePasswordPage() {
  const { user, isLoading } = useUser()

  return (
    <div className="flex flex-col w-full gap-2">
      <div className="mb-2 flex gap-0.5 font-bold">
        <h2>الرئيسية</h2>
        <div>/</div>
        <Link
          className="hover:text-success"
          to={"/users?page=1"}
        >
          المستخدمين
        </Link>
        <div>/</div>
        {isLoading ? (
          <div className="w-32 h-4 rounded-md animate-pulse bg-muted" />
        ) : (
          <Link
            className="hover:text-success"
            to={"/users?page=1"}
          >
            {user.first_name_ar} {user.last_name_ar}
          </Link>
        )}
        <div>/</div>
        <h2 className="text-success">تغيير كلمة مرور</h2>
      </div>
      <div className="p-4 bg-white border-2 rounded-md border-muted">
        {isLoading ? (
          <LoaderChangeUserPasswordForm />
        ) : (
          <FormChangeUserPassword />
        )}
      </div>
    </div>
  )
}
