import { useContext, useReducer } from "react"
import { Outlet } from "react-router-dom"
import {
  CommitteeMembersContext,
  committeeMembersInitialState,
  committeeMembersReducer,
} from "."

// Custom hook to use the context
export const useCommitteeMembersContext = () =>
  useContext(CommitteeMembersContext)

// UserProvider to wrap the app and provide context
export const CommitteeMembersProvider = () => {
  const [committeeMembersState, committeeMembersDispatch] = useReducer(
    committeeMembersReducer,
    committeeMembersInitialState,
  )

  return (
    <CommitteeMembersContext.Provider
      value={{ committeeMembersState, committeeMembersDispatch }}
    >
      <Outlet />
    </CommitteeMembersContext.Provider>
  )
}
