import React, { useEffect, useState } from "react"
import Select from "react-tailwindcss-select"
import useCommittees from "../../hooks/useCommittees"

export default function SelectCommittees({ value, onChange, error }) {
  const { isLoading, committees } = useCommittees()
  const [selectedCommittee, setSelectedCommittee] = useState(null)

  useEffect(() => {
    if (value && committees.length > 0) {
      const defaultCommittee = committees.find(
        (committee) => committee.id === value,
      )
      if (defaultCommittee) {
        setSelectedCommittee({
          label: defaultCommittee.title,
          value: defaultCommittee.id,
          symbol: defaultCommittee.symbol,
        })
      } else {
        setSelectedCommittee(null)
      }
    } else {
      setSelectedCommittee(null)
    }
  }, [value, committees])

  const handleOnChange = (option) => {
    setSelectedCommittee(option)
    if (onChange) {
      onChange(option?.value ?? null)
    }
  }

  return (
    <Select
      value={selectedCommittee}
      onChange={handleOnChange}
      options={committees.map((committee) => ({
        label: committee.title,
        value: committee.id,
      }))}
      loading={isLoading}
      isDisabled={isLoading}
      isSearchable
      placeholder="اللجنة  الفنية العربية..."
      isClearable
      classNames={{
        menuButton: ({ isDisabled }) =>
          `focus:outline-none focus:ring-2 focus:ring-primary border-2 border-muted py-0.5 pr-3 bg-light flex rounded-md bg-white ${isDisabled ? "opacity-50 cursor-not-allowed" : ""}`,
      }}
      formatOptionLabel={(data) => (
        <li
          className={`z-50 mt-1 line-clamp-1 block cursor-pointer select-none rounded-md px-4 py-3 transition duration-200 ${data.isSelected ? `bg-primary font-bold text-white` : ``}`}
        >
          <div className="flex items-center gap-2">
            <div>{data.label}</div>
          </div>
        </li>
      )}
    />
  )
}
