import { formatDistanceToNow } from "date-fns"
import moment from "moment"
import "moment/locale/ar-kw"
import { ar } from "date-fns/locale"
import toast from "react-hot-toast"
const generateRandomPassword = () => {
  const length = 16 // Length of the password
  const charset =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789"
  let password = ""
  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * charset.length)
    password += charset[randomIndex]
  }
  return password
}

const getMemberRoleNameByKey = (KEY) => {
  const roles = {
    COMMITTEE_HEAD: "رئيس اللجنة",
    COMMITTEE_SECRETARY: "أمين اللجنة",
    MEMBER: "عضو",
    OBSERVER: "مراقب",
  }

  return roles[KEY]
}
const getUserTypeByKey = (KEY) => {
  const roles = {
    FORUMS_USER: "مستخدم المنتديات",
    ADMIN: "مسؤول",
  }
  return roles[KEY]
}

const getProjectTypeByKey = (KEY) => {
  const types = {
    NEW_ADOPTION: "جديد / تبني",
    NEW_PREPARATION: "جديد / اعداد",
    REVIEW_ADOPTION: "مراجعة / تبني",
    REVIEW_PREPARATION: "مراجعة / اعداد",
  }

  return types[KEY]
}

const datetimeFormat = (date) => {
  return moment(date).format("LLLL")
}

function datetimeAgo(date) {
  return formatDistanceToNow(moment(date), {
    addSuffix: true,
    locale: ar,
  })
}

function toastError(message) {
  toast.custom((t) => (
    <div
      className={`${t.visible ? "animate-enter" : "animate-leave"} pointer-events-auto flex w-full max-w-md rounded-md border-2 border-danger bg-white p-2 px-4 text-sm text-danger shadow-md`}
    >
      <div className="flex flex-col gap-1">
        <h3 className="font-bold">خطأ</h3>
        <p>{message}</p>
      </div>
    </div>
  ))
}

function toastSuccess(message) {
  toast.custom((t) => (
    <div
      className={`${t.visible ? "animate-enter" : "animate-leave"} pointer-events-auto flex w-full max-w-md rounded-md border-2 border-success bg-white p-2 px-4 text-sm text-success shadow-md`}
    >
      <div className="flex flex-col gap-1">
        <h3 className="font-bold">إشعار</h3>
        <p>{message}</p>
      </div>
    </div>
  ))
}

export {
  generateRandomPassword,
  getMemberRoleNameByKey,
  toastSuccess,
  toastError,
  datetimeFormat,
  getProjectTypeByKey,
  getUserTypeByKey,
  datetimeAgo,
}
