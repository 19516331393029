import { useEffect } from "react"
import { useCommitteeActions } from "."
import { useParams } from "react-router-dom"
import { useState } from "react"

export default function useCommittee() {
  const { committee_id } = useParams()
  const { getCommittee } = useCommitteeActions()

  const [committee, setCommittee] = useState(null)
  const [isLoading, setisLoading] = useState(true)

  async function fetchCommittee() {
    try {
      setisLoading(true)
      const data = await getCommittee(committee_id)
      setCommittee(data)
    } catch (error) {
    } finally {
      setisLoading(false)
    }
  }

  useEffect(() => {
    fetchCommittee()
  }, [committee_id])

  return { committee, isLoading }
}
