import { useState } from "react"
import { toastError, toastSuccess } from "../../../../helper"
import useCommitteeDocumentActions from "../../hooks/useCommitteeDocumentActions"
import { useNavigate } from "react-router-dom"
import { MdOutlineUploadFile } from "react-icons/md"
import { CgClose } from "react-icons/cg"
import { GrAdd } from "react-icons/gr"
import SubmitButton from "../../../core/components/UI/Submit"

export default function FormAddCommitteeFolderDocuments({ folder }) {
  const navigate = useNavigate()
  const { addDocument } = useCommitteeDocumentActions()

  const [documents, setDocuments] = useState([{ name: "", file: null }])
  const [isUploading, setIsUploading] = useState(false)

  const handleAddDocument = () => {
    setDocuments([...documents, { name: "", file: null }])
  }

  const handleRemoveDocument = (index) => {
    const updatedDocuments = documents.filter((_, i) => i !== index)
    setDocuments(updatedDocuments)
  }

  const handleInputChange = (index, field, value) => {
    const updatedDocuments = [...documents]
    updatedDocuments[index][field] = value
    setDocuments(updatedDocuments)
  }

  const handleFileChange = (index, file) => {
    const updatedDocuments = [...documents]
    updatedDocuments[index].file = file
    if (updatedDocuments[index].name === "") {
      updatedDocuments[index].name = file.name
        ?.split(".")
        .slice(0, -1)
        .join(".")
    }
    setDocuments(updatedDocuments)
  }

  async function handleOnSubmitAddDocuments(event) {
    try {
      event.preventDefault()
      setIsUploading(true)

      if (
        documents.some(function (doc) {
          return !doc.name || !doc.file
        })
      ) {
        setIsUploading(false)
        toastError("يجب ملء جميع الحقول!")
        return
      }

      const uploadPromises = documents.map(async function (document) {
        try {
          const message = await addDocument(
            folder?.committee?.id,
            folder?.id,
            document,
          )
          toastSuccess(message)
        } catch (error) {
          toastError("فشل رفع المستند: " + document.name)
        }
      })

      await Promise.all(uploadPromises)

      navigate(`/committees/${folder?.committee?.id}/folders`)
    } catch (error) {
      if (!error.response && !error.request) {
        toastError("لم يتم الاتصال بالخادم. يرجى التحقق من الاتصال بالإنترنت.")
      } else if (error.response) {
        toastError(error.response.data.error)
      } else if (error.request) {
        toastError("لم يتم استلام استجابة من الخادم. حاول مرة أخرى لاحقًا.")
      } else {
        toastError("حدث خطأ غير متوقع. حاول مرة أخرى لاحقًا.")
      }
    } finally {
      setIsUploading(false)
    }
  }

  return (
    <form onSubmit={handleOnSubmitAddDocuments}>
      <div className="flex flex-col gap-4">
        <div className="flex w-full flex-col gap-1">
          <label
            className="font-bold"
            htmlFor="folder_name"
          >
            اسم المجلد الجديد <span className="text-danger">*</span>
          </label>
          <div className="w-full cursor-default rounded-md border-2 border-muted bg-light px-6 py-3">
            {folder?.name}
          </div>
        </div>
        {documents.map((document, index) => (
          <div
            key={index}
            className="flex items-end gap-2"
          >
            <div className="flex w-full flex-col gap-1">
              <label
                className="font-bold"
                htmlFor={`ID_DOCUMENT_NAME_${index}`}
              >
                اسم الوثيقة <span className="text-danger">*</span>
              </label>
              <input
                id={`ID_DOCUMENT_NAME_${index}`}
                name={`document_name_${index}`}
                type="text"
                value={document.name}
                onChange={(e) =>
                  handleInputChange(index, "name", e.target.value)
                }
                className="w-full rounded-md border-2 border-muted px-6 py-3 focus:outline-none focus:ring-2 focus:ring-primary"
              />
            </div>
            <label
              htmlFor={`ID_DOCUMENT_FILE_${index}`}
              className="flex w-full cursor-pointer flex-col gap-2"
            >
              <span className="font-bold">
                الملف <span className="text-danger">*</span>
              </span>
              <div className="group flex items-center justify-between overflow-hidden rounded-md border-2 border-muted bg-white text-secondary hover:border-primary">
                <div className="line-clamp-1 w-full px-4 py-2">
                  {document.file ? document.file.name : `اختر ملف`}
                </div>
                <div className="h-full w-fit bg-muted px-2 py-2 group-hover:bg-primary group-hover:text-white">
                  <MdOutlineUploadFile size={22} />
                </div>
              </div>
              <input
                type="file"
                name={`project_attachment_${index}`}
                id={`ID_DOCUMENT_FILE_${index}`}
                className="hidden"
                onChange={(e) => handleFileChange(index, e.target.files[0])}
              />
            </label>

            {index > 0 && (
              <button
                type="button"
                onClick={() => handleRemoveDocument(index)}
                className="rounded-md border-2 border-danger-hover bg-danger/80 px-2 py-2 text-white hover:bg-danger"
              >
                <CgClose size={22} />
              </button>
            )}
          </div>
        ))}

        <button
          type="button"
          className="w-full rounded-md border-2 border-muted bg-light px-6 py-3 hover:bg-muted"
          onClick={handleAddDocument}
        >
          <div className="flex items-center justify-center gap-2">
            <GrAdd size={16} />
            <div>إضافة وثيقة</div>
          </div>
        </button>
        <SubmitButton
          label="إضافة وثائق"
          inProgress={isUploading}
          progressLabel="جاري إضافة وثائق..."
        />
      </div>
    </form>
  )
}
