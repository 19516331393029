import { USER_ACTIONS } from "./USER_ACTIONS"

export function userReducer(state, action) {
  switch (action.type) {
    case USER_ACTIONS.SET_USERS:
      return {
        ...state,
        users: action.payload.users,
        pagination: action.payload.pagination,
      }

    case USER_ACTIONS.SET_IS_LOADING:
      return { ...state, isLoading: action.payload.isLoading }
    default:
      return state
  }
}
