import React from "react"
import { useLogout } from "../../../auth"
import { FaSpinner } from "react-icons/fa"

export default function ButtonLogout() {
  const { inProgress, handleOnLogout } = useLogout()
  return (
    <button
      type="button"
      onClick={handleOnLogout}
      disabled={inProgress}
      className="flex w-fit items-center rounded-md border-2 border-danger-hover bg-danger px-4 py-2 text-white transition duration-200 hover:bg-danger-hover disabled:cursor-not-allowed disabled:border-muted disabled:bg-light disabled:text-dark"
    >
      {inProgress ? (
        <div className="flex items-center">
          <FaSpinner
            size={16}
            className="ml-4 animate-spin"
          />
          <span>جاري تسجيل الخروج...</span>
        </div>
      ) : (
        <span>تسجيل الخروج</span>
      )}
    </button>
  )
}
