import axiosClient from "../../../config/axiosClient"

const CommitteeGlobalDocumentService = {
  getDocuments() {
    return axiosClient.get(`/dashboard/global-documents`)
  },
  createDocument(payload) {
    return axiosClient.post(`dashboard/global-documents`, payload, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
  },
  deleteDocument(documentId) {
    return axiosClient.delete(`/dashboard/global-documents/${documentId}`)
  },
  downloadDocument(documentId) {
    return axiosClient.get(
      `/dashboard/global-documents/${documentId}/download`,
      {
        responseType: "blob",
      },
    )
  },
}

export default CommitteeGlobalDocumentService
