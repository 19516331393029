import axiosClient from "../../../config/axiosClient"

const CommitteeFolderService = {
  getFolders(committeeId, queryString) {
    return axiosClient.get(
      `/dashboard/committees/${committeeId}/folders?${queryString}`,
    )
  },
  getFolder(committeeId, folderId) {
    return axiosClient.get(
      `/dashboard/committees/${committeeId}/folders/${folderId}`,
    )
  },
  createFolder(committeeId, name) {
    return axiosClient.post(`/dashboard/committees/${committeeId}/folders`, {
      name,
    })
  },
  renameFolder(committeeId, folderId, payload) {
    return axiosClient.post(
      `/dashboard/committees/${committeeId}/folders/${folderId}/rename`,
      payload,
    )
  },
  deleteFolder(committeeId, folderId) {
    return axiosClient.delete(
      `/dashboard/committees/${committeeId}/folders/${folderId}`,
    )
  },
  downloadFolder(committeeId, folderId) {
    return axiosClient.get(
      `/dashboard/committees/${committeeId}/folders/${folderId}/download`,
      {
        responseType: "blob",
      },
    )
  },
}

export default CommitteeFolderService
