import { Link } from "react-router-dom"
import { FormCreateUser } from "../components"

export default function UsersCreatePage() {
  return (
    <div className="flex w-full flex-col">
      <div className="mb-2 flex gap-0.5 font-bold">
        <h2>الرئيسية</h2>
        <div>/</div>
        <Link
          to={"/users?page=1"}
          className="hover:text-success"
        >
          المستخدمين
        </Link>
        <div>/</div>
        <h2 className="text-success">إنشاء مستخدم جديد</h2>
      </div>
      <div className="w-full rounded-md border-2 border-muted bg-white p-4">
        <FormCreateUser />
      </div>
    </div>
  )
}
