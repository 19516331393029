import { MdDownload } from "react-icons/md"
import useCommitteeFolderActions from "../../hooks/useCommitteeFolderActions"
import { toastError, toastSuccess } from "../../../../helper"
import { FaSpinner } from "react-icons/fa"

export default function ButtonDownloadCommitteeFolder({ folder }) {
  const { downloadCommitteeFolder, inProgress } = useCommitteeFolderActions()
  async function handleOnCilickDownloadCommitteeFolder() {
    try {
      const message = await downloadCommitteeFolder(folder.id, folder?.name)
      if (message) {
        toastSuccess(message)
      }
    } catch (error) {
      if (!error.response && !error.request) {
        toastError("لم يتم الاتصال بالخادم. يرجى التحقق من الاتصال بالإنترنت.")
      } else if (error.response) {
        toastError(error.response.data.error)
      } else if (error.request) {
        toastError("لم يتم استلام استجابة من الخادم. حاول مرة أخرى لاحقًا.")
      } else {
        toastError("حدث خطأ غير متوقع. حاول مرة أخرى لاحقًا.")
      }
    }
  }
  return (
    <button
      onClick={handleOnCilickDownloadCommitteeFolder}
      disabled={inProgress}
      className={`flex items-center gap-2 rounded-md border-2 border-muted px-4 py-2 hover:border-muted hover:bg-light disabled:cursor-progress disabled:border-muted disabled:bg-light disabled:text-dark/50`}
      aria-busy={inProgress}
    >
      {inProgress ? (
        <>
          <FaSpinner
            size={16}
            className="animate-spin"
          />
          <div>جاري تحميل المجلد...</div>
        </>
      ) : (
        <>
          <MdDownload size={20} />
          <div>تحميل المجلد</div>
        </>
      )}
    </button>
  )
}
