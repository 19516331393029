import { ProjectListItem } from "."

export default function ProjectList({ projects }) {
  return (
    <div className="flex flex-col gap-1">
      {projects?.map((project) => (
        <ProjectListItem project={project} />
      ))}
    </div>
  )
}
