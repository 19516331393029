import { UsersGridItem } from "../.."
import { LoaderUsersGrid } from "."

export default function UsersGrid({ isLoading, users }) {
  if (isLoading) {
    return <LoaderUsersGrid />
  }
  if (users) {
    return (
      <div className="grid grid-cols-12 gap-2">
        {users?.map((user) => (
          <UsersGridItem user={user} />
        ))}
      </div>
    )
  }
}
