import React from "react"
import { MdDownload } from "react-icons/md"
import useCommitteeFolderActions from "../../hooks/useCommitteeFolderActions"
import useCommitteeDocumentActions from "../../hooks/useCommitteeDocumentActions"
import { useParams } from "react-router-dom"
import { toastError, toastSuccess } from "../../../../helper"
import { FaSpinner } from "react-icons/fa"

export default function ButtonDownloadCommitteeFolderDocument({ document }) {
  const { committee_id } = useParams()
  const { downloadDocument, inProgress } = useCommitteeDocumentActions()
  const handleOnDownloadCommitteeFolderDocument = async () => {
    try {
      const message = await downloadDocument(
        committee_id,
        document.folder_id,
        document.id,
        `${document.name}.${document.extension}`,
      )
      if (message) {
        toastSuccess(message)
      }
    } catch (error) {
      if (!error.response && !error.request) {
        toastError("لم يتم الاتصال بالخادم. يرجى التحقق من الاتصال بالإنترنت.")
      } else if (error.response) {
        toastError("حدث خطأ في تحميل مستند اللجنة. حاول مرة أخرى لاحقًا.")
      } else if (error.request) {
        toastError("لم يتم استلام استجابة من الخادم. حاول مرة أخرى لاحقًا.")
      } else {
        toastError("حدث خطأ غير متوقع. حاول مرة أخرى لاحقًا.")
      }
    }
  }

  return (
    <button
      onClick={handleOnDownloadCommitteeFolderDocument}
      className="rounded-[5px] border-2 border-muted bg-light p-1 hover:bg-muted disabled:cursor-progress disabled:border-muted disabled:bg-light disabled:text-dark/50"
      disabled={inProgress}
    >
      {inProgress ? (
        <FaSpinner
          size={16}
          className="animate-spin"
        />
      ) : (
        <MdDownload size={16} />
      )}
    </button>
  )
}
