import { useRef, useState } from "react"
import { Link, useNavigate } from "react-router-dom"
import useCommitteeGlobalDocumentActions from "../hooks/useCommitteeGlobalDocumentActions"
import toast from "react-hot-toast"
import { MdOutlineUploadFile } from "react-icons/md"
import { FaSpinner } from "react-icons/fa"
import { toastError, toastSuccess } from "../../../helper"

export default function CommitteeGlobalDocumentsCreatePage() {
  const navigate = useNavigate()
  const { createDocument, inProgress } = useCommitteeGlobalDocumentActions()

  const nameRef = useRef()
  const [file, setFile] = useState(null)

  async function handleOnCreateGlobalDocument(event) {
    try {
      event.preventDefault()
      const document = {
        name: nameRef.current.value,
        file,
      }
      const message = await createDocument(document)

      if (message) {
        toastSuccess(message)
        navigate("/committees/global/documents")
      }
    } catch (error) {
      if (!error.response && !error.request) {
        toastError("لم يتم الاتصال بالخادم. يرجى التحقق من الاتصال بالإنترنت.")
      } else if (error.response) {
        toastError(error.response.data.error)
      } else if (error.request) {
        toastError("لم يتم استلام استجابة من الخادم. حاول مرة أخرى لاحقًا.")
      } else {
        toastError("حدث خطأ غير متوقع. حاول مرة أخرى لاحقًا.")
      }
    }
  }

  return (
    <div className="flex w-full flex-col gap-2">
      <div className="flex gap-0.5 font-bold">
        <h2>الرئيسية</h2>
        <div>/</div>
        <Link
          className="hover:text-success"
          to={"/committees/global/documents"}
        >
          الوثائق العامة للجان الفنية
        </Link>
        <div>/</div>
        <h2 className="text-success">إضافة وثيقة</h2>
      </div>
      <div className="rounded-md border-2 border-muted bg-white p-4">
        <form onSubmit={handleOnCreateGlobalDocument}>
          <div className="flex flex-col gap-4">
            <div className="flex w-full flex-col gap-1">
              <label
                className="font-bold"
                htmlFor="ID_DOCUMENT_NAME"
              >
                الأسم <span className="text-danger">*</span>
              </label>

              <input
                ref={nameRef}
                type="text"
                id="ID_DOCUMENT_NAME"
                className="w-full rounded-md border-2 border-muted px-6 py-3 focus:outline-none focus:ring-2 focus:ring-primary"
                placeholder="الأسم وثيقة"
                required
              />
            </div>

            <label
              htmlFor="ID_PROJECT_FILE"
              className="flex w-full cursor-pointer flex-col gap-2"
            >
              <span className="font-bold">
                وثيقة <span className="text-danger">*</span>
              </span>
              <div className="group flex items-center justify-between overflow-hidden rounded-md border-2 border-muted bg-white text-secondary hover:border-primary">
                <div className="px-4 py-2">
                  {file && file.name}
                  {!file && `اختر وثيقة`}
                </div>
                <div className="h-full bg-muted px-2 py-2 group-hover:bg-primary group-hover:text-white">
                  <MdOutlineUploadFile size={22} />
                </div>
              </div>
              <input
                type="file"
                name="project_file"
                id="ID_PROJECT_FILE"
                className="hidden"
                onChange={(event) => setFile(event.target.files[0])}
              />
            </label>

            <button
              type="submit"
              disabled={inProgress}
              className="flex w-fit rounded-md border-2 border-primary-hover bg-primary px-4 py-3 text-white hover:bg-primary-hover disabled:border-muted disabled:bg-light disabled:text-dark"
            >
              {inProgress ? (
                <div className="flex items-center">
                  <FaSpinner
                    size={16}
                    className="ml-2 animate-spin"
                  />
                  <div>جاري إنشاء وثيقة...</div>
                </div>
              ) : (
                "إنشاء وثيقة"
              )}
            </button>
          </div>
        </form>
      </div>
    </div>
  )
}
