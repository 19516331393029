export default function LoaderChangeCommitteeMemberRoleForm() {
  return (
    <div className="flex items-end gap-2">
      <div className="flex w-full flex-col gap-1">
        <label
          className="font-bold"
          htmlFor="ID_COMMITTEE_MEMBER"
        >
          العضو <span className="text-danger">*</span>
        </label>
        <div className="h-[45px] animate-pulse rounded-md border-2 border-muted bg-light px-6 py-3" />
      </div>
      <div className="flex w-full flex-col gap-1">
        <label
          className="font-bold"
          htmlFor="ID_COMMITTEE_MEMBER_ROLE"
        >
          الرتبة <span className="text-danger">*</span>
        </label>
        <div className="h-[45px] animate-pulse rounded-md border-2 border-muted bg-light px-6 py-3" />
      </div>
    </div>
  )
}
