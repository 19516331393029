import { useContext, useReducer } from "react"
import { Outlet } from "react-router-dom"
import { ProjectsContext, projectsInitialState, projectsReducer } from "."

export const useProjectsContext = () => useContext(ProjectsContext)

// UserProvider to wrap the app and provide context
export function ProjectsProvider() {
  const [projectsState, projectsDispatch] = useReducer(
    projectsReducer,
    projectsInitialState,
  )

  return (
    <ProjectsContext.Provider value={{ projectsState, projectsDispatch }}>
      <Outlet />
    </ProjectsContext.Provider>
  )
}
