import React from "react"
import ProjectCommentDetailsListItem from "./ProjectCommentDetailsListItem"

export default function ProjectCommentDetailsList({ details }) {
  return (
    <div>
      {details.map((detail) => (
        <ProjectCommentDetailsListItem detail={detail} />
      ))}
    </div>
  )
}
