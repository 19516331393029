import ButtonDownloadCommitteeFolderDocument from "./ButtonDownloadCommitteeFolderDocument"
import { useParams } from "react-router-dom"
import { datetimeFormat } from "../../../../helper"
import FileIcon from "../../../core/components/UI/FileIcon"
import ButtonDeleteCommitteeFolderDocument from "./ButtonDeleteCommitteeFolderDocument"

export default function CommitteeFolderDocumentsListItem({ document }) {
  const { committee_id } = useParams()

  return (
    <div
      key={document.id}
      className="flex items-center justify-between border-b border-muted p-2 last:border-b-0"
    >
      <div className="flex items-center gap-2">
        <FileIcon
          size={32}
          type={document.extension}
        />
        <div className="">
          <h3 className="text-sm">
            {document.name}.{document.extension}
          </h3>
          <em
            title={datetimeFormat(document.created_at)}
            className="text-[10px]"
          >
            {datetimeFormat(document.created_at)}
          </em>
        </div>
      </div>
      <div className="flex items-center gap-1">
        <ButtonDownloadCommitteeFolderDocument document={document} />
        <ButtonDeleteCommitteeFolderDocument
          committeeId={committee_id}
          folderId={document.folder_id}
          documentId={document.id}
        />
      </div>
    </div>
  )
}
