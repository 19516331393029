import React from "react"
import { useAuth } from "../../auth"
import { Link } from "react-router-dom"

export default function TopMenuProfileItem() {
  const { authState } = useAuth()
  if (authState.isLoading) {
    return (
      <div className="flex rounded-md border-2 border-muted bg-light px-4 py-2">
        --
      </div>
    )
  }

  return (
    <Link
      title="profile"
      to={`/users/${authState.currentUser.id}`}
      className="flex rounded-md border-2 border-muted bg-light px-4 py-2"
    >
      <p className="font-bold">
        {authState.currentUser.first_name_ar}{" "}
        {authState.currentUser.last_name_ar}
      </p>
    </Link>
  )
}
