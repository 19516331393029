import React, { useEffect, useRef } from "react"
import { MdClose } from "react-icons/md"

export default function ModalFiler({ isOpen, onClose, children }) {
  const filterRef = useRef(null)

  const handleOnCloseFilter = () => {
    onClose()
  }

  useEffect(() => {
    if (isOpen) {
      filterRef.current.classList.remove(`-translate-x-full`)
      filterRef.current.classList.add(`translate-x-0`)
    } else {
      filterRef.current.classList.remove(`translate-x-0`)
      filterRef.current.classList.add(`-translate-x-full`)
    }
  }, [isOpen])

  return (
    <>
      {isOpen && (
        <div
          onClick={handleOnCloseFilter}
          className="fixed bottom-0 left-0 right-0 top-0 z-10 h-full w-full bg-dark/50 backdrop-blur"
        ></div>
      )}
      <div
        ref={filterRef}
        className="fixed bottom-0 left-0 top-0 z-10 w-full -translate-x-full border-2 border-muted bg-white transition md:w-[512px]"
      >
        <div className={`flex flex-col gap-4`}>
          <div className="flex items-center justify-between border-b-2 border-muted bg-light p-4">
            <h3 className="text-lg font-bold">فلتر البحث</h3>
            <button
              onClick={handleOnCloseFilter}
              className={`rounded-md border-2 border-muted bg-light p-2 transition duration-150 ease-in-out hover:bg-muted`}
            >
              <MdClose size={16} />
            </button>
          </div>
          <div className="px-4">{children}</div>
        </div>
      </div>
    </>
  )
}
