import { useLayoutEffect } from "react"
import { Link, useParams, useSearchParams } from "react-router-dom"
import useCommitteeMemberActions from "../hooks/useCommitteeMemberActions"
import {
  BreadcrumbCommitteeMember,
  useCommitteeMembersContext,
  CommitteeMembersGrid,
  LoaderCommitteeMembersGrid,
  ButtonCommitteeMembersFilter,
} from ".."

import Pagination from "../../core/components/UI/Pagination"
import NotFoundCommitteeMembers from "../components/NotFoundCommitteeMembers"

export default function CommitteeMembersPage() {
  const { committee_id } = useParams()
  const [searchParams] = useSearchParams()

  const { getMembers } = useCommitteeMemberActions()
  const { committeeMembersState } = useCommitteeMembersContext()

  useLayoutEffect(() => {
    getMembers(committee_id)
  }, [searchParams, committee_id])

  return (
    <div className="flex flex-col w-full gap-2">
      <BreadcrumbCommitteeMember />
      <div className="flex items-center justify-between">
        <Link to={`/committees/${committee_id}/members/add`}>
          <button className="px-4 py-2 font-bold text-white border-2 rounded-md border-primary-hover bg-primary hover:bg-primary-hover">
            إضافة عضو
          </button>
        </Link>
        <ButtonCommitteeMembersFilter />
      </div>
      {committeeMembersState.isLoading ? (
        <LoaderCommitteeMembersGrid />
      ) : (
        <CommitteeMembersGrid members={committeeMembersState.members} />
      )}
      <Pagination
        isLoading={committeeMembersState.isLoading}
        pagination={committeeMembersState.pagination}
      />
    </div>
  )
}
