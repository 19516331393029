import { CommitteesGridItem, LoaderCommitteesGrid } from "."

export default function CommitteesGrid({ isLoading, committees }) {
  if (isLoading) {
    return <LoaderCommitteesGrid />
  }
  if (committees)
    return (
      <div className="grid grid-cols-12 gap-2">
        {committees?.map((committee) => (
          <CommitteesGridItem
            key={committee.id}
            committee={committee}
          />
        ))}
      </div>
    )
}
