import React from "react"
import { Link } from "react-router-dom"

export default function BreadcrumbCommitteeFolderAddDocuments({ folder }) {
  return (
    <div className="mb-2 flex gap-0.5 font-bold">
      <h2>الرئيسية</h2>
      <div>/</div>
      <Link
        className="hover:text-success"
        to={`/committees`}
      >
        اللجنة الفنية العربية
      </Link>
      <div>/</div>
      <h2>{folder?.committee?.title}</h2>
      <div>/</div>
      <Link
        className="hover:text-success"
        to={`/committees/${folder?.committee.id}/folders`}
      >
        وثائق اللجنة
      </Link>
      <div>/</div>
      <h2>{folder?.name}</h2>
      <div>/</div>
      <h2 className="text-success">إضافة وثائق</h2>
    </div>
  )
}
