import { useContext, useReducer } from "react"
import { Outlet } from "react-router-dom"
import {
  CommitteeGlobalDocumentsContext,
  committeeGlobalDocumentsInitialState,
  committeeGlobalDocumentsReducer,
} from "."

export const useCommitteeGlobalDocumentsContext = () =>
  useContext(CommitteeGlobalDocumentsContext)

// UserProvider to wrap the app and provide context
export const CommitteeGlobalDocumentsProvider = () => {
  const [committeeGlobalDocumentsState, committeeGlobalDocumentsDispatch] =
    useReducer(
      committeeGlobalDocumentsReducer,
      committeeGlobalDocumentsInitialState,
    )

  return (
    <CommitteeGlobalDocumentsContext.Provider
      value={{
        committeeGlobalDocumentsState,
        committeeGlobalDocumentsDispatch,
      }}
    >
      <Outlet />
    </CommitteeGlobalDocumentsContext.Provider>
  )
}
