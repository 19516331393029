import useProjectComments from "../../hooks/useProjectComments"
import LoaderProjectCommentsList from "./LoaderProjectCommentsList"
import ProjectCommentsList from "./ProjectCommentsList"

export default function ProjectComments() {
  const { isLoading, comments } = useProjectComments()

  return isLoading ? (
    <LoaderProjectCommentsList />
  ) : (
    <ProjectCommentsList comments={comments} />
  )
}
