import { Link, useParams } from "react-router-dom"
import useProjectVersions from "../hooks/useProjectVersions"
import { MdOutlineArrowForwardIos } from "react-icons/md"
import ProjectVersionsList from "../components/project-versions-page/ProjectVersionsList"
import LoaderProjectVersionsList from "../components/project-versions-page/LoaderProjectVersionsList"
import BreadcrumbProjectVersions from "../components/project-versions-page/BreadcrumbProjectVersions"

export default function ProjectVersionsPage() {
  const { committee_id, project_id } = useParams()
  const { isLoading, versions } = useProjectVersions()
  return (
    <div className="flex w-full flex-col gap-2">
      <BreadcrumbProjectVersions />
      <div className="flex">
        <Link
          to={`/committees/${committee_id}/projects/${project_id}`}
          className="flex items-center gap-2 rounded-md border-2 border-muted-hover bg-white px-4 py-2 pr-2 font-bold hover:bg-muted disabled:animate-pulse disabled:cursor-not-allowed disabled:border-muted disabled:bg-white disabled:text-dark"
        >
          <MdOutlineArrowForwardIos size={16} />
          <div>العودة إلى المشروع الحالي</div>
        </Link>
      </div>
      {isLoading ? (
        <LoaderProjectVersionsList />
      ) : (
        <ProjectVersionsList versions={versions} />
      )}
    </div>
  )
}
