export default function LoaderEditCommitteeForm() {
  return (
    <div className="flex flex-col gap-4">
      <div className="flex flex-col gap-1">
        <label
          htmlFor="ID_COMMITTEE_IMAGE"
          className="font-bold"
        >
          الصورة <span className="text-danger">*</span>
        </label>
        <div className="h-56 animate-pulse rounded-md border-2 border-muted bg-light px-6 py-3" />
      </div>
      <div className="flex w-full flex-col gap-1">
        <label
          className="font-bold"
          htmlFor="ID_TITLE"
        >
          الأسم <span className="text-danger">*</span>
        </label>
        <div className="h-10 animate-pulse rounded-md border-2 border-muted bg-light px-6 py-3" />
      </div>
      <div className="flex w-full flex-col gap-1">
        <label
          className="font-bold"
          htmlFor="ID_SYMBOL"
        >
          الرمز <span className="text-danger">*</span>
        </label>
        <div className="h-10 animate-pulse rounded-md border-2 border-muted bg-light px-6 py-3" />
      </div>
    </div>
  )
}
