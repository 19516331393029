import { useEffect } from "react"
import { Link, useParams, useSearchParams } from "react-router-dom"
import useCommitteeFolderActions from "../hooks/useCommitteeFolderActions"
import BreadcrumbCommitteeDocument from "../components/UI/BreadcrumbCommitteeDocument"
import { useCommitteeFoldersContext } from "../context"
import CommitteeFoldersList from "../components/committee-folders-page/CommitteeFoldersList"
import LoaderCommitteeFoldersList from "../components/committee-folders-page/LoaderCommitteeFoldersList"
import Pagination from "../../core/components/UI/Pagination"
import ButtonCommitteeFolderFilter from "../components/committee-folders-page/ButtonCommitteeFolderFilter"
import NotFoundCommitteeDocuments from "../components/NotFoundCommitteeDocuments"

export default function CommitteeFoldersPage() {
  const [searchParams] = useSearchParams()
  const { committee_id } = useParams()
  const { getCommitteeFolders } = useCommitteeFolderActions()
  const { committeeFoldersState } = useCommitteeFoldersContext()

  useEffect(() => {
    getCommitteeFolders(searchParams.toString())
  }, [searchParams])

  return (
    <div className="flex flex-col w-full gap-2">
      <BreadcrumbCommitteeDocument />
      <div className="flex items-center justify-between">
        <Link to={`/committees/${committee_id}/folders/create`}>
          <button className="px-4 py-2 font-bold text-white border-2 rounded-md border-primary-hover bg-primary hover:bg-primary-hover">
            إضافة وثيقة
          </button>
        </Link>
        <ButtonCommitteeFolderFilter />
      </div>
      {committeeFoldersState.isLoading ? (
        <LoaderCommitteeFoldersList />
      ) : (
        <CommitteeFoldersList folders={committeeFoldersState.folders} />
      )}
      <Pagination
        isLoading={committeeFoldersState.isLoading}
        pagination={committeeFoldersState.pagination}
      />
    </div>
  )
}
