import { useEffect, useState } from "react"
import useCommitteeGlobalDocumentActions from "./useCommitteeGlobalDocumentActions"

export default function useCommitteeGlobalDocuments() {
  const { getDocuments } = useCommitteeGlobalDocumentActions()
  const [documents, setDocuments] = useState(null)
  const [isLoading, setIsLoading] = useState(true)
  async function fetchDocuments() {
    try {
      setIsLoading(true)
      const documents = await getDocuments()
      setDocuments(documents)
    } catch (error) {
    } finally {
      setIsLoading(false)
    }
  }
  useEffect(() => {
    fetchDocuments()
  }, [])

  return { isLoading, documents, fetchDocuments }
}
