import { useState } from "react"
import { generateRandomPassword } from "../../../helper"
import BreadcrumbProjectAttachmentsAdd from "../components/project-attachments/BreadcrumbProjectAttachmentsAdd"
import { useProjectContext } from "../context/project"
import { useParams } from "react-router-dom"
import { CgClose, CgSpinner } from "react-icons/cg"
import { MdOutlineUploadFile } from "react-icons/md"
import { FiPlus } from "react-icons/fi"
import FormAddProjectAttachments from "../components/project-attachments/FormAddProjectAttachments"

export default function ProjectAttachmentsAddPage() {
  return (
    <div className="flex w-full flex-col gap-4">
      <BreadcrumbProjectAttachmentsAdd />
      <FormAddProjectAttachments />
    </div>
  )
}
