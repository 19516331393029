import { FaSpinner, FaUserAltSlash } from "react-icons/fa"
import { FiCheck } from "react-icons/fi"
import { useAuth } from "../../../auth"
import { useUserActions } from "../.."
import { toastError } from "../../../../helper"

export default function ButtonChangeAccountStatus({ user }) {
  const { authState } = useAuth()
  const { changeAccountStatus, inProgress } = useUserActions()

  async function handleOnChangeAccountState() {
    try {
      changeAccountStatus(user.id, !user.is_activated)
    } catch (error) {
      if (!error.response && !error.request) {
        toastError("لم يتم الاتصال بالخادم. يرجى التحقق من الاتصال بالإنترنت.")
      } else if (error.response) {
        toastError(error.response.data.error)
      } else if (error.request) {
        toastError("لم يتم استلام استجابة من الخادم. حاول مرة أخرى لاحقًا.")
      } else {
        toastError("حدث خطأ غير متوقع. حاول مرة أخرى لاحقًا.")
      }
    }
  }

  return (
    <button
      onClick={handleOnChangeAccountState}
      className={`flex items-center gap-2 rounded-md border-2 border-light px-4 py-2 hover:border-muted hover:bg-light disabled:cursor-not-allowed disabled:opacity-45 disabled:hover:border-light disabled:hover:bg-white`}
      disabled={authState.currentUser.id === user.id || inProgress}
    >
      {inProgress ? (
        <div className="flex items-center">
          <FaSpinner
            size={16}
            className="ml-2 animate-spin"
          />
          <div>جاري تغيير حالة الحساب...</div>
        </div>
      ) : (
        <>
          {user.is_activated ? (
            <>
              {" "}
              <FaUserAltSlash size={20} />
              <span>توقف الحساب</span>
            </>
          ) : (
            <>
              {" "}
              <FiCheck size={20} />
              <span>تفعيل الحساب</span>
            </>
          )}
        </>
      )}
    </button>
  )
}
