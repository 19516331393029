import axiosClient from "../../../config/axiosClient"

const getCountries = async () => {
  try {
    const { data } = await axiosClient.get("/services/countries")
    return data.countries
  } catch (error) {
    throw error
  }
}

export { getCountries }
