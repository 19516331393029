import Modal from "../../../core/components/UI/Modal"

export default function ModalUserCommittees({
  full_name_ar,
  committees,
  isOpen,
  onClose,
}) {
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
    >
      <div className="w-[548px] rounded-t-md border-2 border-b-0 border-muted bg-light !text-sm">
        <h3 className="p-4">اللجان الفنية الخاصة بــ”{full_name_ar}”</h3>
      </div>
      <div className="flex h-[300px] flex-col gap-2 overflow-y-scroll border-2 border-muted p-2">
        {committees?.map((committee) => (
          <div
            key={committee.id}
            className="rounded-md border-2 border-muted p-2"
          >
            <h3 className="text-sm font-semibold">{committee.title}</h3>
            <h4 className="text-sm font-light">{committee.symbol}</h4>
          </div>
        ))}
      </div>
    </Modal>
  )
}
