import React, { useState } from "react"
import { FaRegTrashCan } from "react-icons/fa6"
import { useAuth } from "../../../auth"
import { FaSpinner } from "react-icons/fa"
import { toastError } from "../../../../helper"
import { useUserActions } from "../.."

export default function ButtonDeleteUser({ user, onClose }) {
  const { authState } = useAuth()
  const { deleteUser, inProgress } = useUserActions()
  async function handleOnDeleteUser() {
    try {
      await deleteUser(user.id)
      onClose()
    } catch (error) {
      if (!error.response && !error.request) {
        toastError("لم يتم الاتصال بالخادم. يرجى التحقق من الاتصال بالإنترنت.")
      } else if (error.response) {
        toastError(error.response.data.error)
      } else if (error.request) {
        toastError("لم يتم استلام استجابة من الخادم. حاول مرة أخرى لاحقًا.")
      } else {
        toastError("حدث خطأ غير متوقع. حاول مرة أخرى لاحقًا.")
      }
    }
  }
  return (
    <button
      onClick={handleOnDeleteUser}
      className={`flex w-full items-center gap-2 rounded-md border-2 border-danger bg-danger/10 px-4 py-2 text-danger hover:bg-danger/20 disabled:cursor-not-allowed disabled:opacity-45 disabled:hover:bg-danger/10`}
      disabled={authState.currentUser.id === user.id || inProgress}
    >
      {inProgress ? (
        <div className="flex items-center">
          <FaSpinner
            size={16}
            className="ml-2 animate-spin"
          />
          <div>جاري حذف الحساب...</div>
        </div>
      ) : (
        <>
          <FaRegTrashCan size={20} />
          <span>حذف</span>
        </>
      )}
    </button>
  )
}
