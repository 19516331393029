import { useState } from "react"
import { FiFilter } from "react-icons/fi"
import ModalCommitteeFolderFilter from "./ModalCommitteeFolderFilter"

export default function ButtonCommitteeFolderFilter() {
  const [isCommitteeFolderFilterOpen, setIsCommitteeFolderFilterOpen] =
    useState(false)

  const handleOnClickOpenCommitteeFolderFilter = () => {
    setIsCommitteeFolderFilterOpen(true)
  }

  const handleOnClickCloseCommitteeFolderFilter = () => {
    setIsCommitteeFolderFilterOpen(false)
  }

  return (
    <>
      <button
        onClick={handleOnClickOpenCommitteeFolderFilter}
        className="flex items-center gap-2 rounded-md border-2 border-muted bg-white p-2 pl-4 text-dark hover:bg-light"
      >
        <FiFilter size={16} />
        <div className="font-bold">فلتر البحث</div>
      </button>
      <ModalCommitteeFolderFilter
        isOpen={isCommitteeFolderFilterOpen}
        onClose={handleOnClickCloseCommitteeFolderFilter}
      />
    </>
  )
}
