import { Link } from "react-router-dom"
import ButtonCommitteeOptions from "./ButtonCommitteeOptions"

export default function CommitteesGridItem({ committee }) {
  return (
    <div className="group col-span-12 overflow-hidden rounded-md border-2 border-muted bg-white hover:border-primary lg:col-span-6 xl:col-span-4">
      <div className="relative min-h-28">
        <div className="absolute bottom-0 left-0 right-0 top-0">
          <img
            className="h-full w-full object-cover object-center"
            alt={committee.title}
            title={committee.title}
            src={`${process.env.REACT_APP_BASE_URL}/storage/${committee.image}`}
          />
        </div>
        <div className="absolute bottom-0 right-0 z-0 h-40 w-full bg-gradient-to-t from-white to-transparent" />
      </div>
      <div className="relative z-0 p-2 pb-0 group-hover:text-primary">
        <h3 className="line-clamp-1 text-base font-bold">{committee.title}</h3>
        <p className="font-light">{committee.symbol}</p>
      </div>
      <div className="flex w-full gap-2 p-2">
        <div className="flex w-full items-center gap-2">
          <Link
            className="flex w-full justify-between gap-2 rounded-md border-2 border-muted px-4 py-2 pl-2.5 hover:bg-light"
            to={`/committees/${committee.id}/members`}
          >
            <div>الأعضاء</div>
            <div>({committee.users_count})</div>
          </Link>
          <Link
            className="flex w-full justify-between gap-2 rounded-md border-2 border-muted px-4 py-2 pl-2.5 hover:bg-light"
            to={`/committees/${committee.id}/projects`}
          >
            <div>المشاريع</div>

            <div>({committee.projects_count})</div>
          </Link>
          <Link
            className="flex w-full justify-between gap-2 rounded-md border-2 border-muted px-4 py-2 pl-2.5 hover:bg-light"
            to={`/committees/${committee.id}/folders`}
          >
            <div>الوثائق</div>

            <div>({committee.documents_count})</div>
          </Link>
        </div>
        <ButtonCommitteeOptions committee={committee} />
      </div>
    </div>
  )
}
