export default function LoaderUserAccountInformations() {
  return (
    <div className="rounded-md border-2 border-muted bg-white p-4">
      <h2 className="mb-4 text-base font-bold">معلومات الحساب </h2>
      <div className="flex flex-col gap-2">
        <div className="flex w-full flex-col gap-1">
          <label className="font-bold">نوع الحساب</label>
          <div className="h-[45px] animate-pulse rounded-md border-2 border-muted bg-light px-6 py-3" />
        </div>

        <div className="flex w-full flex-col gap-1">
          <label className="font-bold">المسمى الوظيفي</label>
          <div className="h-[45px] animate-pulse rounded-md border-2 border-muted bg-light px-6 py-3" />
        </div>

        <div className="flex gap-2">
          <div className="flex w-full flex-col gap-1">
            <label className="font-bold">الاسم الشخصي بالعربية</label>
            <div className="h-[45px] animate-pulse rounded-md border-2 border-muted bg-light px-6 py-3" />
          </div>

          <div className="flex w-full flex-col gap-1">
            <label className="font-bold">اسم العائلة بالعربية</label>
            <div className="h-[45px] animate-pulse rounded-md border-2 border-muted bg-light px-6 py-3" />
          </div>
        </div>
        <div className="flex gap-2">
          <div className="flex w-full flex-col gap-1">
            <label className="font-bold">الاسم الشخصي بالإنجليزية</label>
            <div className="h-[45px] animate-pulse rounded-md border-2 border-muted bg-light px-6 py-3" />
          </div>
          <div className="flex w-full flex-col gap-1">
            <label className="font-bold">اسم العائلة بالإنجليزية</label>
            <div className="h-[45px] animate-pulse rounded-md border-2 border-muted bg-light px-6 py-3" />
          </div>
        </div>
        <div className="flex gap-2">
          <div className="flex w-full flex-col gap-1">
            <label className="font-bold">دولة</label>
            <div className="h-[45px] animate-pulse rounded-md border-2 border-muted bg-light px-6 py-3" />
          </div>
          <div className="flex w-full flex-col gap-1">
            <label className="font-bold">جهاز تقييس</label>
            <div className="h-[45px] animate-pulse rounded-md border-2 border-muted bg-light px-6 py-3" />
          </div>
        </div>
        <div className="flex w-full flex-col gap-1">
          <label className="font-bold">البريد الإلكتروني</label>
          <div className="h-[45px] animate-pulse rounded-md border-2 border-muted bg-light px-6 py-3" />
        </div>
        <div className="flex gap-2">
          <div className="flex w-full flex-col gap-1">
            <label className="font-bold">النوع</label>
            <div className="h-[45px] animate-pulse rounded-md border-2 border-muted bg-light px-6 py-3" />
          </div>
          <div className="flex w-full flex-col gap-1">
            <label className="font-bold">حالة الحساب</label>
            <div className="h-[45px] animate-pulse rounded-md border-2 border-muted bg-light px-6 py-3" />
          </div>
        </div>
        <div className="flex w-full flex-col gap-1">
          <label className="font-bold">نبذة تعريفية</label>
          <div className="h-[45px] animate-pulse rounded-md border-2 border-muted bg-light px-6 py-3" />
        </div>
        <div className="flex w-full flex-col gap-1">
          <label className="font-bold">أخر ظهور</label>
          <div className="h-[45px] animate-pulse rounded-md border-2 border-muted bg-light px-6 py-3" />
        </div>
      </div>
    </div>
  )
}
