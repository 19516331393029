import { MdDownload } from "react-icons/md"
import { useProjectsActions } from "../../hooks"
import { FaSpinner } from "react-icons/fa"
import { toastError, toastSuccess } from "../../../../helper"
import { useParams } from "react-router-dom"

export default function ButtonDownloadProjectVersion({ version }) {
  const { committee_id, project_id } = useParams()
  const { inProgress, downloadVersion } = useProjectsActions()

  async function handleOnDownloadDocument() {
    try {
      const fileName = `${version.title_ar}.${version.extension}`
      await downloadVersion(committee_id, project_id, version.id, fileName)
      toastSuccess("تم تحميل ملف المشروع بنجاح")
    } catch (error) {
      if (!error.response && !error.request) {
        toastError("لم يتم الاتصال بالخادم. يرجى التحقق من الاتصال بالإنترنت.")
      } else if (error.response) {
        toastError('حدث خطأ في تحميل الإصدار . حاول مرة أخرى لاحقًا.')
      } else if (error.request) {
        toastError("لم يتم استلام استجابة من الخادم. حاول مرة أخرى لاحقًا.")
      } else {
        toastError("حدث خطأ غير متوقع. حاول مرة أخرى لاحقًا.")
      }
    }
  }

  return (
    <button
      onClick={handleOnDownloadDocument}
      className="flex items-center gap-2 px-4 py-2 pr-2 font-bold text-white border-2 rounded-md border-primary-hover bg-primary hover:bg-primary-hover disabled:cursor-not-allowed disabled:border-muted disabled:bg-white disabled:text-dark"
      disabled={inProgress}
    >
      {inProgress ? (
        <>
          <FaSpinner
            size={16}
            className="animate-spin"
          />
          <div>جاري تنزيل إصدار...</div>
        </>
      ) : (
        <>
          <MdDownload size={16} />
          <div>تنزيل إصدار</div>
        </>
      )}
    </button>
  )
}
