import { PROJECTS_ACTIONS } from "."

export function projectsReducer(state, action) {
  switch (action.type) {
    case PROJECTS_ACTIONS.SET_PROJECTS:
      return {
        ...state,
        projects: action.payload.projects,
        pagination: action.payload.pagination,
      }
    case PROJECTS_ACTIONS.SET_PROJECT:
      return {
        ...state,
        project: action.payload.project,
      }

    case PROJECTS_ACTIONS.SET_IS_LOADING:
      return { ...state, isLoading: action.payload.isLoading }
    default:
      return state
  }
}
