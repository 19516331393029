import BreadcrumbCommitteeMemberRole from "../components/UI/BreadcrumbCommitteeMemberRole"

import FormChangeCommitteeMemberRole from "../components/committee-members-change-role-page/FormChangeCommitteeMemberRole"
import LoaderChangeCommitteeMemberRoleForm from "../components/committee-members-change-role-page/LoaderChangeCommitteeMemberRoleForm"
import useMember from "../hooks/useMember"

export default function CommitteeMembersChangeRolePage() {
  const { isLoading, member } = useMember()
  return (
    <div className="flex w-full flex-col gap-2">
      <BreadcrumbCommitteeMemberRole />
      <div className="rounded-md border-2 border-muted bg-white p-4">
        {isLoading ? (
          <LoaderChangeCommitteeMemberRoleForm />
        ) : (
          <FormChangeCommitteeMemberRole member={member} />
        )}
      </div>
    </div>
  )
}
