import axiosClient from "../../../config/axiosClient"

const getEntities = async () => {
  try {
    const { data } = await axiosClient.get("/services/entities")
    return data.entities
  } catch (error) {
    throw error
  }
}

export { getEntities }
