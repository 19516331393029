import useCommitteeGlobalDocumentActions from "../../hooks/useCommitteeGlobalDocumentActions"
import { toastError, toastSuccess } from "../../../../helper"
import { FaSpinner } from "react-icons/fa"
import { MdDelete } from "react-icons/md"

export default function ButtonDeleteGlobalDocument({
  documentId,
  fetchDocuments,
}) {
  const { inProgress, deleteDocument } = useCommitteeGlobalDocumentActions()

  async function handleOnDeleteAttachment() {
    try {
      const message = await deleteDocument(documentId)
      if (message) {
        toastSuccess("تم حذف المستند بنجاح")
        fetchDocuments()
      }
    } catch (error) {
      if (!error.response && !error.request) {
        toastError("لم يتم الاتصال بالخادم. يرجى التحقق من الاتصال بالإنترنت.")
      } else if (error.response) {
        toastError(error.response.data.error)
      } else if (error.request) {
        toastError("لم يتم استلام استجابة من الخادم. حاول مرة أخرى لاحقًا.")
      } else {
        toastError("حدث خطأ غير متوقع. حاول مرة أخرى لاحقًا.")
      }
    }
  }

  return (
    <button
      onClick={handleOnDeleteAttachment}
      disabled={inProgress}
      className={`flex w-full items-center gap-2 rounded-md border-2 border-danger bg-danger/10 p-1 text-danger hover:bg-danger/20 disabled:cursor-progress disabled:border-muted disabled:bg-light disabled:text-muted`}
    >
      {inProgress ? (
        <FaSpinner
          size={16}
          className="animate-spin"
        />
      ) : (
        <MdDelete size={16} />
      )}
    </button>
  )
}
