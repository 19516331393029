import React from "react"
import { Link } from "react-router-dom"

export default function SideMenuItem({ label, isActive = false, to }) {
  return (
    <Link
      to={to}
      className="flex rounded-md border-2 border-muted bg-white px-4 py-2 hover:border-primary hover:text-primary"
    >
      <div>{label}</div>
    </Link>
  )
}
