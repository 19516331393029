import React, { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import useProjectsActions from "./useProjectsActions"

export default function useProjectAttachemnts() {
  const { committee_id, project_id } = useParams()
  const { getProjectAttachments } = useProjectsActions()
  const [attachments, setAttachments] = useState(null)
  const [isLoading, setIsLoading] = useState(true)

  async function fetchProjectAttachments() {
    try {
      setIsLoading(true)
      const attachments = await getProjectAttachments(committee_id, project_id)
      setAttachments(attachments)
    } catch (error) {
      throw error
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    fetchProjectAttachments()
  }, [committee_id, project_id])

  return { attachments, isLoading, fetchProjectAttachments }
}
