import React from "react"
import ButtonCommentDetails from "./ButtonCommentDetails"

export default function ProjectCommentDetailsListItem({ detail }) {
  return (
    <div
      className="flex justify-between rounded-md border-2 border-muted bg-white"
      key={detail.id}
    >
      <div className="grid w-full grid-cols-12 p-2">
        <div className="col-span-4 flex gap-1">
          <div>رقم الصفحة : </div>
          <div>{detail.page_number}</div>
        </div>
        <div className="col-span-4 flex gap-1">
          <div>رقم البند : </div>
          <div>{detail.item_number}</div>
        </div>
        <div className="col-span-4 flex gap-1">
          <div>نوع الملاحظة : </div>
          <div>{detail.type}</div>
        </div>
      </div>
     <ButtonCommentDetails detail={detail} />
    </div>
  )
}
