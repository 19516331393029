import { FaArchive } from "react-icons/fa"
import { Link, useParams } from "react-router-dom"

export default function ButtonProjectVersions() {
  const { committee_id, project_id } = useParams()
  return (
    <Link
      to={`/committees/${committee_id}/projects/${project_id}/versions`}
      className="flex items-center gap-2 rounded-md border-2 border-muted-hover bg-white px-4 py-2 pr-2 font-bold hover:bg-muted disabled:animate-pulse disabled:cursor-not-allowed disabled:border-muted disabled:bg-white disabled:text-dark"
    >
      <FaArchive size={16} />
      <div>الإصدارات</div>
    </Link>
  )
}
