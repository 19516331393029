import { MdEdit } from "react-icons/md"
import { Link, useParams } from "react-router-dom"
import Modal from "../../../core/components/UI/Modal"
import { HiDocumentAdd } from "react-icons/hi"
import ButtonDownloadCommitteeFolder from "./ButtonDownloadCommitteeFolder"
import ButtonDeleteCommitteeFolder from "./ButtonDeleteCommitteeFolder"

export default function ModalCommitteeFolderOptions({
  isOpen,
  onClose,
  folder,
}) {
  const { committee_id } = useParams()
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
    >
      <div className="flex min-w-80 flex-col gap-2 rounded-md border-2 border-muted p-2">
        {folder.documents && <ButtonDownloadCommitteeFolder folder={folder} />}
        <Link
          to={`/committees/${committee_id}/folders/${folder.id}/rename`}
          className={`flex items-center gap-2 rounded-md border-2 border-muted px-4 py-2 hover:border-muted`}
        >
          <MdEdit size={20} />
          <span>تغيير اسم المجلد</span>
        </Link>
        <Link
          to={`/committees/${committee_id}/folders/${folder.id}/documents/add`}
          className={`hover:border-mute flex items-center gap-2 rounded-md border-2 border-muted px-4 py-2`}
        >
          <HiDocumentAdd size={20} />
          <span>إضافة وثائق</span>
        </Link>
        <ButtonDeleteCommitteeFolder
          folderId={folder.id}
          committeeId={committee_id}
          onClose={onClose}
        />
      </div>
    </Modal>
  )
}
