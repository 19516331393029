import SideMenuItem from "./SideMenuItem"

export default function SideMenu() {
  return (
    <div className="flex min-w-60 text-sm">
      <div className="sticky top-4 h-[calc(100vh-1rem)] w-full">
        <div className="flex flex-col gap-2">
          <SideMenuItem
            label="المستخدمين"
            to="/users?page=1"
          />
          <SideMenuItem
            label="اللجان الفنية العربية"
            to="/committees"
          />
          <SideMenuItem
            label="الوثائق العامة للجان الفنية"
            to="/committees/global/documents"
          />
        </div>
      </div>
    </div>
  )
}
