import { useState } from "react"
import { FiFilter } from "react-icons/fi"
import { ModalCommitteeMembersFilter } from "."

export default function ButtonCommitteeMembersFilter() {
  const [isCommitteeMembersFilterOpen, setIsCommitteeMembersFilterOpen] =
    useState(false)

  const handleOnClickOpenCommitteeMembersFilter = () => {
    setIsCommitteeMembersFilterOpen(true)
  }

  const handleOnClickCloseCommitteeMembersFilter = () => {
    setIsCommitteeMembersFilterOpen(false)
  }

  return (
    <>
      <button
        onClick={handleOnClickOpenCommitteeMembersFilter}
        className="flex items-center gap-2 rounded-md border-2 border-muted bg-white p-2 pl-4 text-dark hover:bg-light"
      >
        <FiFilter size={16} />
        <div className="font-bold">فلتر البحث</div>
      </button>
      <ModalCommitteeMembersFilter
        isOpen={isCommitteeMembersFilterOpen}
        onClose={handleOnClickCloseCommitteeMembersFilter}
      />
    </>
  )
}
