export default function LoaderEditUserForm() {
  return (
    <div>
      <div className="flex flex-col gap-4">
        <div className="flex flex-col gap-1">
          <label
            className="font-bold"
            htmlFor="ID_TYPE"
          >
            الفئة<span className="text-danger">*</span>
          </label>
          <div className="h-10 animate-pulse rounded-md border-2 border-muted bg-light px-6 py-3" />
        </div>

        <div className="flex flex-col gap-1">
          <label
            className="font-bold"
            htmlFor="ID_COUNTRY"
          >
            دولة <span className="text-danger">*</span>
          </label>
          <div className="h-10 animate-pulse rounded-md border-2 border-muted bg-light px-6 py-3" />
        </div>

        <div className="flex flex-col gap-1">
          <label
            className="font-bold"
            htmlFor="ID_ENTITY"
          >
            جهاز التقييس <span className="text-danger">*</span>
          </label>
          <div className="h-10 animate-pulse rounded-md border-2 border-muted bg-light px-6 py-3" />
        </div>

        <div className="flex gap-4">
          <div className="flex w-full flex-col gap-1">
            <label
              className="font-bold"
              htmlFor="ID_FIRST_NAME_AR"
            >
              الاسم الشخصي بالعربية <span className="text-danger">*</span>
            </label>
            <div className="h-10 animate-pulse rounded-md border-2 border-muted bg-light px-6 py-3" />
          </div>
          <div className="flex w-full flex-col gap-1">
            <label
              className="font-bold"
              htmlFor="ID_LAST_NAME_AR"
            >
              اسم العائلة بالعربية <span className="text-danger">*</span>
            </label>
            <div className="h-10 animate-pulse rounded-md border-2 border-muted bg-light px-6 py-3" />
          </div>
        </div>

        <div className="flex gap-4">
          <div className="flex w-full flex-col gap-1">
            <label
              className="font-bold"
              htmlFor="ID_FIRST_NAME_EN"
            >
              الاسم الشخصي بالإنجليزية <span className="text-danger">*</span>
            </label>
            <div className="h-10 animate-pulse rounded-md border-2 border-muted bg-light px-6 py-3" />
          </div>
          <div className="flex w-full flex-col gap-1">
            <label
              className="font-bold"
              htmlFor="ID_LAST_NAME_EN"
            >
              اسم العائلة بالإنجليزية <span className="text-danger">*</span>
            </label>
            <div className="h-10 animate-pulse rounded-md border-2 border-muted bg-light px-6 py-3" />
          </div>
        </div>
        <div className="flex flex-col gap-1">
          <label
            className="font-bold"
            htmlFor="ID_CURRENT_JOB"
          >
            المسمى الوظيفي <span className="text-danger">*</span>
          </label>
          <div className="h-10 animate-pulse rounded-md border-2 border-muted bg-light px-6 py-3" />
        </div>
        <div className="flex flex-col gap-1">
          <label
            className="font-bold"
            htmlFor="ID_EMAIL"
          >
            البريد الإلكتروني <span className="text-danger">*</span>
          </label>
          <div className="h-10 animate-pulse rounded-md border-2 border-muted bg-light px-6 py-3" />
        </div>

        <div className="flex flex-col gap-1">
          <label
            className="font-bold"
            htmlFor="ID_GENDER"
          >
            النوع <span className="text-danger">*</span>
          </label>
          <div className="h-10 animate-pulse rounded-md border-2 border-muted bg-light px-6 py-3" />
        </div>
        <div className="flex flex-col gap-1">
          <label
            className="font-bold"
            htmlFor="ID_ACTIVATE"
          >
            حالة الحساب
          </label>
          <div className="h-10 animate-pulse rounded-md border-2 border-muted bg-light px-6 py-3" />
        </div>
        <div className="flex flex-col gap-1">
          <label
            className="font-bold"
            htmlFor="ID_DESCRIPTION"
          >
            نبذة تعريفية
          </label>
          <div className="h-24 rounded-md border-2 border-muted bg-light px-6 py-3" />
        </div>
      </div>
    </div>
  )
}
