import { useState } from "react"
import { MdDownload } from "react-icons/md"
import { useParams } from "react-router-dom"
import useProjectsActions from "../../hooks/useProjectsActions"
import { FaSpinner } from "react-icons/fa"
import { toastError, toastSuccess } from "../../../../helper"

export default function ButtonDownloadProjectAttachment({ attachment }) {
  const { committee_id, project_id } = useParams()
  const { downloadProjectAttachment } = useProjectsActions()
  const [isDownloading, setIsDownloading] = useState(false)

  const handleOnDownloadAttachment = async () => {
    setIsDownloading(true)
    try {
      await downloadProjectAttachment(
        committee_id,
        project_id,
        attachment.id,
        `${attachment.name}.${attachment.extension}`,
      )
      toastSuccess("تم تحميل ملف المشروع بنجاح")
    } catch (error) {
      if (!error.response && !error.request) {
        toastError("لم يتم الاتصال بالخادم. يرجى التحقق من الاتصال بالإنترنت.")
      } else if (error.response) {
        toastError(error.response.data.error)
      } else if (error.request) {
        toastError("لم يتم استلام استجابة من الخادم. حاول مرة أخرى لاحقًا.")
      } else {
        toastError("حدث خطأ غير متوقع. حاول مرة أخرى لاحقًا.")
      }
    } finally {
      setIsDownloading(false) // Reset the download state
    }
  }

  return (
    <button
      onClick={handleOnDownloadAttachment}
      disabled={isDownloading}
      className="rounded-md border-2 border-muted bg-light p-1 hover:bg-muted disabled:bg-muted"
    >
      {isDownloading ? (
        <FaSpinner
          size={16}
          className="animate-spin"
        />
      ) : (
        <MdDownload size={16} />
      )}
    </button>
  )
}
