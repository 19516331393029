import React from "react"
import { Link } from "react-router-dom"
import { useCommittee } from "../../../committees"

export default function BreadcrumbCommitteeFolderCreate() {
  const { isLoading, committee } = useCommittee()
  return (
    <div className="mb-2 flex gap-0.5 font-bold">
      <Link
        className="hover:text-success"
        to="/"
      >
        الرئيسية
      </Link>
      <div>/</div>
      <Link
        className="hover:text-success"
        to={`/committees`}
      >
        اللجنة الفنية العربية
      </Link>
      <div>/</div>
      <Link
        className="hover:text-success"
        to={`/committees/${committee?.id}`}
      >
        {committee?.title}
      </Link>
      <div>/</div>
      <Link
        className="hover:text-success"
        to={`/committees`}
      >
        وثائق اللجنة
      </Link>
      <div>/</div>
      <h2 className="text-success">إضافة وثائق</h2>
    </div>
  )
}
