import { useState } from "react"
import { MdAttachment } from "react-icons/md"
import useProjectAttachemnts from "../../hooks/useProjectAttachemnts"
import ModalProjectAttachments from "./ModalProjectAttachments"

export default function ButtonProjectAttachments() {
  const [isOpen, setIsOpen] = useState(false)

  const handleOnColseProjectAttachmentsModal = () => {
    setIsOpen(false)
  }

  const handleOnProjectAttachmentsModal = () => {
    setIsOpen(true)
  }

  return (
    <>
      <button
        onClick={handleOnProjectAttachmentsModal}
        className="flex items-center gap-2 rounded-md border-2 border-muted-hover bg-white px-4 py-2 pr-2 font-bold hover:bg-muted"
      >
        <MdAttachment
          size={16}
          className="rotate-90"
        />
        <div>وثائق ذات صلة</div>
      </button>
      <ModalProjectAttachments
        isOpen={isOpen}
        onClose={handleOnColseProjectAttachmentsModal}
      />
    </>
  )
}
