import React from "react"
import CommitteeFoldersListItem from "./CommitteeFoldersListItem"

export default function CommitteeFoldersList({ folders }) {
  return (
    <div className="flex flex-col gap-2">
      {folders?.map((folder) => (
        <CommitteeFoldersListItem folder={folder} />
      ))}
    </div>
  )
}
