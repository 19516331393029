import React from "react"
import CommitteeFolderDocumentsListItem from "./CommitteeFolderDocumentsListItem"

export default function CommitteeFolderDocumentsList({ documents }) {
  return (
    <div className="flex w-full flex-col">
      {documents?.map((document) => (
        <CommitteeFolderDocumentsListItem document={document} />
      ))}
    </div>
  )
}
