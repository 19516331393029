import { useRef, useState } from "react"
import { FaSpinner } from "react-icons/fa"
import { FiPlus } from "react-icons/fi"
import ReferenceInput from "../../../core/components/UI/ReferenceInput"
import { MdOutlineUploadFile } from "react-icons/md"
import RadioButton from "../../../core/components/UI/RadioButton"
import {
  generateRandomPassword,
  toastError,
  toastSuccess,
} from "../../../../helper"
import { useNavigate, useParams } from "react-router-dom"
import { useProjectsActions } from "../../hooks"

export default function FormEditProjectDetails({ project }) {
  const navigate = useNavigate()
  const { committee_id, project_id } = useParams()
  const { inProgress, updateProject } = useProjectsActions()

  const titleArRef = useRef()
  const titleEnRef = useRef()
  const languageRef = useRef()
  const numberRef = useRef()
  const descriptionRef = useRef()
  const domainRef = useRef()
  const icsRef = useRef()
  const referenceRef = useRef()

  const [category, setCategory] = useState(project.category)
  const [formType, setFormType] = useState(project.type.split("_")[0])
  const [projectType, setProjectType] = useState(project.type.split("_")[1])
  const [references, setReferences] = useState(
    project.references.map((reference) => ({
      id: generateRandomPassword(),
      value: reference,
    })),
  )
  
  const [projectFile, setProjectFile] = useState(null)

  async function handleOnSubmitEditProjectForm(event) {
    try {
      event.preventDefault()
      const payload = {
        title_ar: titleArRef.current.value,
        title_en: titleEnRef.current.value,
        type: `${formType}_${projectType}`,
        category: category,
        language: languageRef.current.value,
        number: numberRef.current?.value,
        domain: domainRef.current.value,
        reference: referenceRef.current.value,
        description: descriptionRef.current.value,
        ics: icsRef.current.value,
        references: references.map((ref) => ref.value),
        file: projectFile,
      }

      const message = await updateProject(committee_id, project_id, payload)

      if (message) {
        toastSuccess(message)
        navigate(`/committees/${committee_id}/projects/${project_id}`)
      }
    } catch (error) {
      if (!error.response && !error.request) {
        toastError("لم يتم الاتصال بالخادم. يرجى التحقق من الاتصال بالإنترنت.")
      } else if (error.response) {
        toastError(error.response.data.error)
      } else if (error.request) {
        toastError("لم يتم استلام استجابة من الخادم. حاول مرة أخرى لاحقًا.")
      } else {
        toastError("حدث خطأ غير متوقع. حاول مرة أخرى لاحقًا.")
      }
    }
  }

  const addReference = () => {
    setReferences([...references, { id: generateRandomPassword(), value: "" }])
  }

  const handleReferenceChange = (id, event) => {
    const updatedReferences = references.map((ref) =>
      ref.id === id ? { ...ref, value: event.target.value } : ref,
    )
    setReferences(updatedReferences)
  }

  const removeReference = (id) => {
    setReferences(references.filter((ref) => ref.id !== id))
  }
  return (
    <form
      onSubmit={handleOnSubmitEditProjectForm}
      className="flex flex-col gap-4 rounded-md border-2 border-muted bg-white p-4"
    >
      <div className="flex flex-col gap-2">
        <label className="font-bold">
          نوع الوثيقة<span className="text-danger">*</span>
        </label>
        <div className="flex gap-2">
          <RadioButton
            onClick={() => setCategory("لائحة فنية")}
            label="لائحة فنية"
            isActive={category === "لائحة فنية"}
          />
          <RadioButton
            onClick={() => setCategory("مشروع مواصفة")}
            label="مشروع مواصفة"
            isActive={category === "مشروع مواصفة"}
          />
        </div>
      </div>

      <div className="flex flex-col gap-2">
        <label className="font-bold">
          نوع إضافة المشروع<span className="text-danger">*</span>
        </label>
        <div className="flex gap-4">
          <RadioButton
            onClick={() => setFormType("NEW")}
            label="جديد"
            isActive={formType === "NEW"}
          />
          <RadioButton
            onClick={() => setFormType("REVIEW")}
            label="مراجعة"
            isActive={formType === "REVIEW"}
          />
        </div>
      </div>

      <div className="flex flex-col gap-2">
        <label className="font-bold">
          نوع المشروع<span className="text-danger">*</span>
        </label>
        <div className="flex gap-2">
          <RadioButton
            onClick={() => setProjectType("PREPARATION")}
            label="اعداد"
            isActive={projectType === "PREPARATION"}
          />
          <RadioButton
            onClick={() => setProjectType("ADOPTION")}
            label="تبني"
            isActive={projectType === "ADOPTION"}
          />
        </div>
      </div>

      <div className="flex flex-col gap-4">
        <div className="flex w-full flex-col gap-2">
          <label
            htmlFor="ID_TITLE_AR_PROJECT"
            className="font-bold"
          >
            عنوان المشروع باللغة العربية <span className="text-danger">*</span>
          </label>
          <input
            ref={titleArRef}
            id="ID_TITLE_PROJECT"
            type="text"
            placeholder="عنوان بالعربية"
            defaultValue={project.title_ar}
            className="w-full rounded-md border-2 border-muted px-6 py-3 focus:outline-none focus:ring-2 focus:ring-primary"
          />
        </div>
        <div className="flex w-full flex-col gap-2">
          <label
            htmlFor="ID_TITLE_EN_PROJECT"
            className="font-bold"
          >
            عنوان المشروع باللغة الإنجليزية{" "}
            <span className="text-danger">*</span>
          </label>
          <input
            ref={titleEnRef}
            id="ID_TITLE_EN_PROJECT"
            type="text"
            placeholder="عنوان بالإنجليزية"
            defaultValue={project.title_en}
            className="w-full rounded-md border-2 border-muted px-6 py-3 focus:outline-none focus:ring-2 focus:ring-primary"
          />
        </div>
      </div>
      <label
        htmlFor="ID_PROJECT_FILE"
        className="flex w-full cursor-pointer flex-col gap-2"
      >
        <span className="font-bold">
          ملف المشروع <span className="text-danger">*</span>
        </span>
        <div className="group flex items-center justify-between overflow-hidden rounded-md border-2 border-muted bg-white text-secondary hover:border-primary">
          <div className="px-4 py-2">
            {projectFile && projectFile.name}
            {!projectFile && `اختر ملف`}
          </div>
          <div className="h-full bg-muted px-2 py-2 group-hover:bg-primary group-hover:text-white">
            <MdOutlineUploadFile size={22} />
          </div>
        </div>
        <input
          type="file"
          name="project_file"
          id="ID_PROJECT_FILE"
          className="hidden"
          accept={projectType === "PREPARATION" ? ".doc,.docx" : ".pdf"}
          onChange={(event) => setProjectFile(event.target.files[0])}
        />
        <em>
          الصيغ المسموح بها{" "}
          {projectType === "PREPARATION" ? '".doc,.docx"' : '".pdf"'}.
        </em>
      </label>
      <div className="flex w-full flex-col gap-2">
        <label className="font-bold">لغة الملف</label>
        <input
          ref={languageRef}
          type="text"
          placeholder="لغة الملف"
          name="language"
          defaultValue={project.language}
          className="w-full rounded-md border-2 border-muted px-6 py-3 focus:outline-none focus:ring-2 focus:ring-primary"
        />
      </div>
      {formType === "REVIEW" && (
        <div className="flex w-full flex-col gap-2">
          <label className="font-bold">
            رقم المواصفة القياسية العربية
            <span className="text-danger">*</span>
          </label>
          <input
            ref={numberRef}
            type="text"
            placeholder="رقم المواصفة القياسية العربية"
            name="number"
            defaultValue={project.number}
            className="w-full rounded-md border-2 border-muted px-6 py-3 focus:outline-none focus:ring-2 focus:ring-primary"
          />
        </div>
      )}
      <div className="flex w-full flex-col gap-2">
        <label className="font-bold">
          المرجع الرئيسي <span className="text-danger">*</span>
        </label>
        <input
          ref={referenceRef}
          type="text"
          placeholder="المرجع الرئيسي"
          name="reference"
          defaultValue={project.reference}
          className="w-full rounded-md border-2 border-muted px-6 py-3 focus:outline-none focus:ring-2 focus:ring-primary"
        />
      </div>

      <div className="flex w-full flex-col gap-2">
        <label className="font-bold">
          التصنيف الدولي للمواصفة (ICS)<span className="text-danger">*</span>
        </label>
        <input
          ref={icsRef}
          type="text"
          placeholder="(ICS) التصنيف الدولي للمواصفة"
          name="ics"
          defaultValue={project.ics}
          className="w-full rounded-md border-2 border-muted px-6 py-3 focus:outline-none focus:ring-2 focus:ring-primary"
        />
      </div>

      <div className="flex w-full flex-col gap-2">
        <label className="font-bold">
          مجال المشروع<span className="text-danger">*</span>
        </label>
        <textarea
          ref={domainRef}
          placeholder="مجال المشروع"
          name="domain"
          defaultValue={project.domain}
          className="h-28 w-full rounded-md border-2 border-muted px-6 py-3 focus:outline-none focus:ring-2 focus:ring-primary"
        ></textarea>
      </div>
      <div className="flex w-full flex-col gap-2">
        <label className="font-bold">
          ملاحظات إضافية<span className="text-danger">*</span>
        </label>
        <textarea
          ref={descriptionRef}
          placeholder="ملاحظات إضافية"
          name="description"
          defaultValue={project.description}
          className="h-28 w-full rounded-md border-2 border-muted px-6 py-3 focus:outline-none focus:ring-2 focus:ring-primary"
        ></textarea>
      </div>

      <div className="flex w-full flex-col gap-2">
        <label className="font-bold">
          المراجع الداخلية <span className="text-danger">*</span>
        </label>
        {references.map((reference, index) => (
          <ReferenceInput
            key={reference.id}
            reference={reference}
            index={index}
            onChange={handleReferenceChange}
            onRemove={removeReference}
          />
        ))}

        <button
          onClick={addReference}
          type="button"
          className="flex w-full items-center justify-center gap-2 rounded-md border-2 border-muted bg-light px-6 py-3 hover:bg-muted focus:outline-none"
        >
          <FiPlus size={18} />
          <div>إضافة مرجع</div>
        </button>
      </div>
      <button
        type="submit"
        disabled={inProgress}
        className="flex w-fit items-center rounded-md border-2 border-primary-hover bg-primary px-4 py-3 text-white transition duration-200 hover:bg-primary-hover disabled:cursor-not-allowed disabled:border-muted disabled:bg-light disabled:text-dark"
      >
        {inProgress ? (
          <div className="flex items-center">
            <FaSpinner
              size={16}
              className="ml-4 animate-spin"
            />
            <span>جاري تعديل المشروع...</span>
          </div>
        ) : (
          <span>تعديل المشروع</span>
        )}
      </button>
    </form>
  )
}
