import { FaRegTrashCan } from "react-icons/fa6"
import { FaSpinner } from "react-icons/fa"
import { toastError, toastSuccess } from "../../../../helper"
import { useCommitteeMemberActions } from "../.."

export default function ButtonDeleteCommitteeMember({ member, onClose }) {
  const { deleteMember, inProgress } = useCommitteeMemberActions()

  async function handleOnDeleteCommitteeMember() {
    try {
      const message = await deleteMember(member.committee_id, member.id)

      if (message) {
        toastSuccess(message)
        onClose()
      }
    } catch (error) {
      if (!error.response && !error.request) {
        toastError("لم يتم الاتصال بالخادم. يرجى التحقق من الاتصال بالإنترنت.")
      } else if (error.response) {
        toastError(error.response.data.error)
      } else if (error.request) {
        toastError("لم يتم استلام استجابة من الخادم. حاول مرة أخرى لاحقًا.")
      } else {
        toastError("حدث خطأ غير متوقع. حاول مرة أخرى لاحقًا.")
      }
    }
  }

  return (
    <button
      onClick={handleOnDeleteCommitteeMember}
      className={`flex w-full items-center gap-2 rounded-md border-2 border-danger bg-danger/10 px-4 py-2 text-danger hover:bg-danger/20 disabled:cursor-not-allowed disabled:opacity-45 disabled:hover:bg-danger/10`}
      disabled={inProgress}
    >
      {inProgress ? (
        <div className="flex items-center">
          <FaSpinner
            size={16}
            className="ml-2 animate-spin"
          />
          <div>جاري حذف العضو...</div>
        </div>
      ) : (
        <>
          <FaRegTrashCan size={20} />
          <span>حذف العضو</span>
        </>
      )}
    </button>
  )
}
