import {
  BreadcrumbCommitteeUserProfile,
  UserAccountInformations,
} from "../components"

export default function UsersProfilePage() {
  return (
    <div className="flex w-full flex-col gap-2">
      <BreadcrumbCommitteeUserProfile />
      <UserAccountInformations />
    </div>
  )
}
