import { useEffect, useState } from "react"
import Select from "react-tailwindcss-select"
import useCountries from "../../hooks/useCountries"

export default function SelectCountries({ value, onChange }) {
  const { isLoading, countries } = useCountries()
  const [selectedCountry, setSelectedCountry] = useState(null)

  useEffect(() => {
    if (value && countries.length > 0) {
      const defaultCountry = countries.find((country) => country.id === value)
      if (defaultCountry) {
        setSelectedCountry({
          label: defaultCountry.name_ar,
          value: defaultCountry.id,
          image: defaultCountry.image,
        })
      } else {
        setSelectedCountry(null)
      }
    } else {
      setSelectedCountry(null)
    }
  }, [value, countries])

  const handleOnChange = (option) => {
    setSelectedCountry(option)
    if (onChange) {
      onChange(option?.value ?? null)
    }
  }

  return (
    <Select
      value={selectedCountry}
      onChange={handleOnChange}
      options={countries.map((country) => ({
        label: country.name_ar,
        value: country.id,
        image: country.image,
      }))}
      loading={isLoading}
      isDisabled={isLoading}
      isSearchable
      placeholder="دولة..."
      isClearable
      classNames={{
        menuButton: ({ isDisabled }) =>
          `focus:outline-none focus:ring-2 focus:ring-primary border-2 border-muted py-0.5 pr-3 bg-light flex rounded-md bg-white ${isDisabled ? "opacity-50 cursor-not-allowed" : ""}`,
      }}
      formatOptionLabel={(data) => (
        <li
          className={`z-50 mt-1 line-clamp-1 block cursor-pointer select-none rounded-md px-4 py-3 transition duration-200 ${data.isSelected ? `bg-primary font-bold text-white` : ``}`}
        >
          <div className="flex items-center gap-2">
            <img
              className="w-8 rounded-sm border-2 border-muted"
              src={`${process.env.REACT_APP_BASE_URL}/storage/${data.image}`}
              alt={data.label}
            />
            <div>{data.label}</div>
          </div>
        </li>
      )}
    />
  )
}
