import { useEffect, useState } from "react"
import { getCommittees } from "../services/committee"

export default function useCommittees() {
  const [isLoading, setIsLoading] = useState(true)
  const [committees, setCommittees] = useState([])
  const [errors, setErrors] = useState({})

  useEffect(() => {
    const fetchEntities = async () => {
      setIsLoading(true)
      try {
        const committees = await getCommittees()
        setCommittees(committees)
      } catch (error) {
        if (error.response) {
          const { data } = error.response
          if (data && data.errors) {
            setErrors(data.errors)
          } else {
            setErrors({ general: ["حدث خطأ غير متوقع. حاول مرة أخرى لاحقًا."] })
          }
        } else if (error.request) {
          setErrors({
            general: ["لم يتم تلقي استجابة من الخادم. حاول مرة أخرى لاحقًا."],
          })
        } else {
          setErrors({
            general: ["حدث خطأ أثناء إعداد الطلب. حاول مرة أخرى لاحقًا."],
          })
        }
      } finally {
        setIsLoading(false)
      }
    }
    fetchEntities()
  }, [])

  return { isLoading, committees, errors }
}
