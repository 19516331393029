import { MdDownload } from "react-icons/md"
import { useProjectsActions } from "../../hooks"
import { FaSpinner } from "react-icons/fa"
import { toastError, toastSuccess } from "../../../../helper"
import { useProjectContext } from "../../context/project"

export default function ButtonDownloadProject() {
  const { projectState } = useProjectContext()
  const { inProgress, downloadProject } = useProjectsActions()

  async function handleOnDownloadDocument() {
    try {
      const fileName = `${projectState.project.title_ar}.${projectState.project.extension}`
      await downloadProject(
        projectState.project.committee.id,
        projectState.project.id,
        fileName,
      )
      toastSuccess("تم تحميل ملف المشروع بنجاح")
    } catch (error) {
      if (!error.response && !error.request) {
        toastError("لم يتم الاتصال بالخادم. يرجى التحقق من الاتصال بالإنترنت.")
      } else if (error.response) {
        toastError("  حدث خطأ في تحميل ملف المشروع . حاول مرة أخرى لاحقًا.  ")
      } else if (error.request) {
        toastError("لم يتم استلام استجابة من الخادم. حاول مرة أخرى لاحقًا.")
      } else {
        toastError("حدث خطأ غير متوقع. حاول مرة أخرى لاحقًا.")
      }
    }
  }

  return (
    <button
      onClick={handleOnDownloadDocument}
      className="flex items-center gap-2 px-4 py-2 pr-2 font-bold text-white border-2 rounded-md border-success-hover bg-success hover:bg-success-hover disabled:cursor-not-allowed disabled:border-muted disabled:bg-white disabled:text-dark"
      disabled={inProgress || projectState.isLoading}
    >
      {inProgress ? (
        <>
          <FaSpinner
            size={16}
            className="animate-spin"
          />
          <div>جاري تنزيل مشروع...</div>
        </>
      ) : (
        <>
          <MdDownload size={16} />
          <div>تنزيل مشروع</div>
        </>
      )}
    </button>
  )
}
