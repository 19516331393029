import { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { useProjectsActions } from "."

export default function useProjectComments() {
  const { committee_id, project_id } = useParams()
  const { getProjectComments } = useProjectsActions()
  const [comments, setComments] = useState(null)
  const [isLoading, setIsLoading] = useState(true)

  async function fetchProjectComments() {
    try {
      setIsLoading(true)
      const comments = await getProjectComments(committee_id, project_id)
      setComments(comments)
    } catch (error) {
      throw error
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    fetchProjectComments()
  }, [committee_id, project_id])

  return { comments, isLoading }
}
