import { useRef, useState } from "react"
import ModalFiler from "../../../core/components/UI/ModalFiler"
import SelectCountries from "../../../core/components/UI/SelectCountries"
import SelectUserTypes from "../../../core/components/UI/SelectUserTypes"
import SelectAccountStatus from "../../../core/components/UI/SelectAccountStatus"
import SelectCommittees from "../../../core/components/UI/SelectCommittees"
import { useSearchParams } from "react-router-dom"

export default function ModalUsersFilter({ onClose, isOpen }) {
  const [searchParams, setSearchParams] = useSearchParams()

  const [countryId, setCountryId] = useState(null)
  const [userType, setUserType] = useState(null)
  const [isActivated, setIsActivated] = useState(null)
  const [committeeId, setCommitteeId] = useState(null)
  const fullnameRef = useRef(null)
  const emailRef = useRef(null)

  const onSubmit = (event) => {
    event.preventDefault()

    const filters = {
      country_id: countryId,
      type: userType,
      is_activated: isActivated,
      committee_id: committeeId,
      fullname: fullnameRef.current?.value,
      email: emailRef.current?.value,
    }

    setSearchParams({ ...filters, page: "1" })
    onClose()
  }

  const handleReset = () => {
    setCountryId(null)
    setUserType(null)
    setIsActivated(null)
    setCommitteeId(null)
    fullnameRef.current.value = ""
    emailRef.current.value = ""
    setSearchParams({ page: "1" })
    onClose()
  }

  return (
    <ModalFiler
      onClose={onClose}
      isOpen={isOpen}
    >
      <form
        className="flex flex-col gap-2"
        onSubmit={onSubmit}
      >
        <div className="flex flex-col gap-1">
          <label
            htmlFor="country_id"
            className="font-bold"
          >
            البلد
          </label>
          <SelectCountries
            value={countryId}
            onChange={(selectedCountryId) => setCountryId(selectedCountryId)}
          />
        </div>
        <div className="flex flex-col gap-1">
          <label
            htmlFor="type"
            className="font-bold"
          >
            نوع الحساب
          </label>
          <SelectUserTypes
            value={userType}
            onChange={(selectedUserType) => setUserType(selectedUserType)}
          />
        </div>

        <div className="flex flex-col gap-1">
          <label
            htmlFor="is_activated"
            className="font-bold"
          >
            حالة الحساب
          </label>
          <SelectAccountStatus
            value={isActivated}
            onChange={(selectedStatus) => setIsActivated(selectedStatus)}
          />
        </div>
        <div className="flex flex-col gap-1">
          <label
            htmlFor="committee_id"
            className="font-bold"
          >
            اللجنة الفنية العربية
          </label>
          <SelectCommittees
            value={committeeId}
            onChange={(selectedCommitteeId) =>
              setCommitteeId(selectedCommitteeId)
            }
          />
        </div>
        <div className="flex flex-col gap-1">
          <label
            htmlFor="fullname"
            className="font-bold"
          >
            الاسم الكامل
          </label>
          <input
            type="text"
            id="fullname"
            placeholder="الاسم الكامل"
            className="w-full rounded-md border-2 border-muted px-6 py-3 focus:outline-none focus:ring-2 focus:ring-primary"
            ref={fullnameRef}
          />
        </div>
        <div className="flex flex-col gap-1">
          <label
            htmlFor="ID_EMAIL"
            className="font-bold"
          >
            بريد إلكتروني
          </label>
          <input
            type="email"
            id="ID_EMAIL"
            placeholder="بريد إلكتروني"
            className="w-full rounded-md border-2 border-muted px-6 py-3 focus:outline-none focus:ring-2 focus:ring-primary"
            ref={emailRef}
          />
        </div>
        <div className="flex gap-2">
          <button
            type="button"
            className="w-full rounded-md border-2 border-muted bg-light px-4 py-3 hover:bg-muted"
            onClick={handleReset}
          >
            مسح
          </button>
          <button
            type="submit"
            className="w-full rounded-md border-2 border-primary-hover bg-primary px-4 py-3 text-white hover:bg-primary-hover"
          >
            بحث
          </button>
        </div>
      </form>
    </ModalFiler>
  )
}
