import { createBrowserRouter, Navigate } from "react-router-dom"
import Layout from "../features/core/layout/Layout"
import SplashPage from "../features/core/pages/SplashPage"
import { UserProvider } from "../features/users/context"
import {
  UsersPage,
  UsersChangePasswordPage,
  UsersCreatePage,
  UsersEditPage,
  UsersProfilePage,
} from "../features/users"
import {
  CommitteesPage,
  CommitteeProvider,
  CommitteesCreatePage,
  CommitteesEditPage,
} from "../features/committees"
import {
  CommitteeMembersAddPage,
  CommitteeMembersPage,
  CommitteeMembersProvider,
  CommitteeMembersChangeRolePage,
} from "../features/committee-members"

import { ProjectsProvider } from "../features/project"
import {
  ProjectEditPage,
  ProjectsDetailsPage,
  ProjectsPage,
} from "../features/project/pages"
import { ProjectProvider } from "../features/project/context/project"
import ProjectVersionsPage from "../features/project/pages/ProjectVersionsPage"
import ProjectLayout from "../features/project/components/ProjectLayout"
import ProjectAttachmentsAddPage from "../features/project/pages/ProjectAttachmentsAddPage"
import { CommitteeGlobalDocumentsProvider } from "../features/global-committee-documents/context"
import {
  CommitteeGlobalDocumentsCreatePage,
  CommitteeGlobalDocumentsPage,
} from "../features/global-committee-documents/pages"
import { CommitteeFoldersProvider } from "../features/committee-documents/context"
import {
  CommitteeFolderRenamePage,
  CommitteeFoldersCreatePage,
  CommitteeFoldersPage,
} from "../features/committee-documents/pages"
import CommitteeFolderAddDocumentsPage from "../features/committee-documents/pages/CommitteeFolderAddDocumentsPage"

const router = createBrowserRouter([
  {
    path: "",
    element: <SplashPage />,
    children: [
      {
        path: "",
        element: <Layout />,
        children: [
          {
            path: "users",
            element: <UserProvider />,
            children: [
              {
                path: "",
                element: <UsersPage />,
              },
              {
                path: "create",
                element: <UsersCreatePage />,
              },
              {
                path: ":user_id",
                children: [
                  {
                    path: "",
                    element: <UsersProfilePage />,
                  },
                  {
                    path: "edit",
                    element: <UsersEditPage />,
                  },
                  {
                    path: "change/password",
                    element: <UsersChangePasswordPage />,
                  },
                ],
              },
            ],
          },
          {
            path: "committees",
            element: <CommitteeProvider />,
            children: [
              {
                path: "",
                element: <CommitteesPage />,
              },
              {
                path: "create",
                element: <CommitteesCreatePage />,
              },
              {
                path: ":committee_id",
                children: [
                  {
                    path: "edit",
                    element: <CommitteesEditPage />,
                  },
                  {
                    path: "members",
                    element: <CommitteeMembersProvider />,
                    children: [
                      {
                        path: "",
                        element: <CommitteeMembersPage />,
                      },
                      {
                        path: "add",
                        element: <CommitteeMembersAddPage />,
                      },
                      {
                        path: ":member_id",
                        children: [
                          {
                            path: "change-role",
                            element: <CommitteeMembersChangeRolePage />,
                          },
                        ],
                      },
                    ],
                  },
                  {
                    path: "projects",
                    element: <ProjectsProvider />,
                    children: [
                      {
                        path: "",
                        element: <ProjectsPage />,
                      },
                      {
                        path: ":project_id",
                        element: <ProjectProvider />,
                        children: [
                          {
                            path: "",
                            element: <ProjectLayout />,
                            children: [
                              {
                                path: "",
                                element: <ProjectsDetailsPage />,
                              },
                              {
                                path: "versions",
                                element: <ProjectVersionsPage />,
                              },
                              {
                                path: "edit",
                                element: <ProjectEditPage />,
                              },
                              {
                                path: "attachments",
                                children: [
                                  {
                                    path: "",
                                    element: <ProjectAttachmentsAddPage />,
                                  },
                                ],
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                  {
                    path: "folders",
                    element: <CommitteeFoldersProvider />,
                    children: [
                      {
                        path: "",
                        element: <CommitteeFoldersPage />,
                      },
                      {
                        path: "create",
                        element: <CommitteeFoldersCreatePage />,
                      },
                      {
                        path: ":folder_id",
                        children: [
                          {
                            path: "rename",
                            element: <CommitteeFolderRenamePage />,
                          },
                          {
                            path: "documents",
                            children: [
                              {
                                path: "add",
                                element: <CommitteeFolderAddDocumentsPage />,
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },

              {
                path: "global/documents",
                element: <CommitteeGlobalDocumentsProvider />,
                children: [
                  {
                    path: "",
                    element: <CommitteeGlobalDocumentsPage />,
                  },
                  {
                    path: "create",
                    element: <CommitteeGlobalDocumentsCreatePage />,
                  },
                ],
              },
            ],
          },
          {
            path: "",
            element: <Navigate to={"/users?page=1"} />,
          },
          {
            path: "*",
            element: <Navigate to={"/users?page=1"} />,
          },
        ],
      },
    ],
  },
])

export { router }
