import { useState } from "react"
import { SlOptions } from "react-icons/sl"
import { ModalUserOptions } from "."

export default function ButtonUserOptions({ user }) {
  const [isOptionsModelOpen, setIsOptionsModelOpen] = useState(false)

  const handleOnClickOpenOptionsModal = () => {
    setIsOptionsModelOpen(true)
  }

  const handleOnClickCloseOptionsModal = () => {
    setIsOptionsModelOpen(false)
  }
  return (
    <>
      <button
        onClick={handleOnClickOpenOptionsModal}
        className="rounded-md border-2 border-muted p-2 hover:bg-light"
      >
        <SlOptions size={16} />
      </button>
      <ModalUserOptions
        isOpen={isOptionsModelOpen}
        user={user}
        onClose={handleOnClickCloseOptionsModal}
      />
    </>
  )
}
