import { datetimeFormat, getUserTypeByKey } from "../../../../helper"
import ButtonUserCommittees from "./ButtonUserCommittees"
import ButtonUserOptions from "./ButtonUserOptions"
import { Link } from "react-router-dom"

export default function UsersGridItem({ user }) {
  return (
    <div className="col-span-12 overflow-hidden bg-white border-2 rounded-md group border-muted hover:border-primary md:col-span-6 lg:col-span-4 xl:col-span-3">
      <Link
        to={`/users/${user.id}`}
        className="relative group-hover:text-primary"
      >
        <div className="absolute top-0 left-0 z-0 h-20">
          <div className="relative w-full h-full">
            <img
              className="object-contain w-full h-full opacity-80"
              alt={user.country.name_ar}
              title={user.country.name_ar}
              src={`${process.env.REACT_APP_BASE_URL}/storage/${user.country.image}`}
            />
            <div className="absolute top-0 bottom-0 right-0 z-0 w-1/2 h-full bg-gradient-to-l from-white to-transparent"></div>
            <div className="absolute bottom-0 right-0 z-0 w-full h-1/2 bg-gradient-to-t from-white to-transparent"></div>
          </div>
        </div>
        <div className="relative z-0 flex flex-col gap-2 p-2">
          <div className="">{user.country.name_ar}</div>
          <div className="flex gap-0.5">
            <div className="font-bold">نوع الحساب :</div>
            <div className="">{getUserTypeByKey(user.type)}</div>
          </div>
          <div className="flex gap-0.5">
            <div className="font-bold">الاسم الكامل :</div>
            <div className="">{user.full_name_ar}</div>
          </div>
          <div className="flex gap-0.5">
            <div className="font-bold">البريد الإلكتروني :</div>
            <div className="">{user.email}</div>
          </div>
          <div className="flex gap-0.5">
            <div className="font-bold">أخر ظهور :</div>
            <div className="">
              {user.last_active
                ? datetimeFormat(user.last_active)
                : "لم يتم تسجيل الدخول بعد"}
            </div>
          </div>
          <div className="flex gap-0.5">
            <div className="font-bold">حالة الحساب :</div>
            <div className="">
              {user.is_activated ? <p>نشط</p> : <p>متوقف</p>}
            </div>
          </div>
        </div>
      </Link>
      <div className="flex justify-between p-2 pt-0">
        <ButtonUserCommittees
          committees={user.committees}
          full_name_ar={user.full_name_ar}
        />
        <ButtonUserOptions user={user} />
      </div>
    </div>
  )
}
