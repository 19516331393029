import { Link, useParams } from "react-router-dom"
import useCommitteeFolder from "../hooks/useCommitteeFolder"
import BreadcrumbCommitteeFolderAddDocuments from "../components/committee-folder-add-documents-page/BreadcrumbCommitteeFolderAddDocuments"
import FormAddCommitteeFolderDocuments from "../components/committee-folder-add-documents-page/FormAddCommitteeFolderDocuments"

export default function CommitteeFolderAddDocumentsPage() {
  const { isLoading, folder } = useCommitteeFolder()

  return (
    <div className="flex w-full flex-col gap-2">
      <BreadcrumbCommitteeFolderAddDocuments folder={folder} />
      <div className="rounded-md border-2 border-muted bg-white p-4">
        <FormAddCommitteeFolderDocuments folder={folder} />
      </div>
    </div>
  )
}
