import { useState } from "react"
import useAuthActions from "./useAuthActions"

const useLogout = () => {
  const [inProgress, setInProgress] = useState(false)
  const { logout } = useAuthActions()

  async function handleOnLogout() {
    setInProgress(true)
    try {
      await logout()
    } catch (error) {
    } finally {
      setInProgress(false)
    }
  }
  return { handleOnLogout, inProgress }
}

export default useLogout
