import { datetimeAgo, datetimeFormat } from "../../../../helper"
import ProjectCommentDetailsList from "./ProjectCommentDetailsList"

export default function ProjectCommentsList({ comments }) {
  return (
    <div className="flex flex-col gap-2">
      {comments?.map((comment) => (
        <div
          className="flex flex-col gap-2 rounded-md border-2 border-muted bg-white p-2 pb-3"
          key={comment}
        >
          <div>
            <div className="flex justify-between">
              <div className="flex items-center gap-2">
                <div className="h-6 overflow-hidden rounded-[4px] border-2 border-muted">
                  <img
                    className="h-full w-full"
                    alt={comment.user.country.name_ar}
                    title={comment.user.country.name_ar}
                    src={`${process.env.REACT_APP_BASE_URL}/storage/${comment.user.country.image}`}
                  />
                </div>
                <em
                  title={datetimeFormat(comment.created_at)}
                  className="text-xs text-secondary"
                >
                  {datetimeAgo(comment.created_at)}
                </em>
              </div>
            </div>
            <div className="text-sm font-bold">
              {comment.user.first_name_ar} {comment.user.last_name_ar}
            </div>
          </div>
          {comment.is_rejected ? (
            <ProjectCommentDetailsList details={comment.details} />
          ) : (
            <p>لا توجد ملاحظات</p>
          )}
        </div>
      ))}
    </div>
  )
}
