import { PROJECT_ACTIONS } from "."

export function projectReducer(state, action) {
  switch (action.type) {
    case PROJECT_ACTIONS.SET_PROJECT:
      return {
        ...state,
        project: action.payload.project,
      }

    case PROJECT_ACTIONS.SET_IS_LOADING:
      return { ...state, isLoading: action.payload.isLoading }
    default:
      return state
  }
}
