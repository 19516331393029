import { useRef, useState } from "react"
import { useUserActions } from "../.."
import SelectAccountStatus from "../../../core/components/UI/SelectAccountStatus"
import SelectUserTypes from "../../../core/components/UI/SelectUserTypes"
import SelectEntities from "../../../core/components/UI/SelectEntities"
import SelectCountries from "../../../core/components/UI/SelectCountries"
import SelectGender from "../../../core/components/UI/SelectGender"
import { toastError, toastSuccess } from "../../../../helper"
import { useNavigate } from "react-router-dom"
import { FaSpinner } from "react-icons/fa"

export default function FormEditUser({ user }) {
  const navigate = useNavigate()

  const { updateUser, inProgress } = useUserActions()

  const [type, setType] = useState(user.type)
  const [countryId, setCountryId] = useState(user.country_id)
  const [entityId, setEntityId] = useState(user.entity_id)
  const [gender, setGender] = useState(user.gender)
  const [isActivated, setActivated] = useState(user.is_activated)

  const firstNameArRef = useRef(null)
  const lastNameArRef = useRef(null)
  const firstNameEnRef = useRef(null)
  const lastNameEnRef = useRef(null)
  const currentJobRef = useRef(null)
  const emailRef = useRef(null)

  const onSubmitUpdateUserForm = async (event) => {
    try {
      event.preventDefault()
      const payload = {
        first_name_ar: firstNameArRef.current?.value,
        last_name_ar: lastNameArRef.current?.value,
        first_name_en: firstNameEnRef.current?.value,
        last_name_en: lastNameEnRef.current?.value,
        current_job: currentJobRef.current?.value,
        email: emailRef.current?.value,
        type,
        country_id: countryId,
        entity_id: entityId,
        gender,
        is_activated: isActivated,
      }

      const message = await updateUser(user.id, payload)

      if (message) {
        toastSuccess(message)
        navigate("/users?page=1")
      }
    } catch (error) {
      if (!error.response && !error.request) {
        toastError("لم يتم الاتصال بالخادم. يرجى التحقق من الاتصال بالإنترنت.")
      } else if (error.response) {
        toastError(error.response.data.error)
      } else if (error.request) {
        toastError("لم يتم استلام استجابة من الخادم. حاول مرة أخرى لاحقًا.")
      } else {
        toastError("حدث خطأ غير متوقع. حاول مرة أخرى لاحقًا.")
      }
    }
  }

  return (
    <form onSubmit={onSubmitUpdateUserForm}>
      <div className="flex flex-col gap-4">
        <div className="flex flex-col gap-1">
          <label
            className="font-bold"
            htmlFor="ID_TYPE"
          >
            الفئة<span className="text-danger">*</span>
          </label>
          <SelectUserTypes
            value={type}
            onChange={(selectedType) => setType(selectedType)}
          />
        </div>

        <div className="flex flex-col gap-1">
          <label
            className="font-bold"
            htmlFor="ID_COUNTRY"
          >
            دولة <span className="text-danger">*</span>
          </label>
          <SelectCountries
            value={countryId}
            onChange={(selectedCountry) => setCountryId(selectedCountry)}
          />
        </div>

        <div className="flex flex-col gap-1">
          <label
            className="font-bold"
            htmlFor="ID_ENTITY"
          >
            جهاز التقييس <span className="text-danger">*</span>
          </label>
          <SelectEntities
            value={entityId}
            onChange={(selectedEntity) => setEntityId(selectedEntity)}
          />
        </div>

        <div className="flex gap-4">
          <div className="flex w-full flex-col gap-1">
            <label
              className="font-bold"
              htmlFor="ID_FIRST_NAME_AR"
            >
              الاسم الشخصي بالعربية <span className="text-danger">*</span>
            </label>
            <input
              ref={firstNameArRef}
              type="text"
              id="ID_FIRST_NAME_AR"
              name="first_name_ar"
              defaultValue={user.first_name_ar}
              className="w-full rounded-md border-2 border-muted px-6 py-3 focus:outline-none focus:ring-2 focus:ring-primary"
            />
          </div>
          <div className="flex w-full flex-col gap-1">
            <label
              className="font-bold"
              htmlFor="ID_LAST_NAME_AR"
            >
              اسم العائلة بالعربية <span className="text-danger">*</span>
            </label>
            <input
              ref={lastNameArRef}
              type="text"
              id="ID_LAST_NAME_AR"
              name="last_name_ar"
              defaultValue={user.last_name_ar}
              className="w-full rounded-md border-2 border-muted px-6 py-3 focus:outline-none focus:ring-2 focus:ring-primary"
            />
          </div>
        </div>

        <div className="flex gap-4">
          <div className="flex w-full flex-col gap-1">
            <label
              className="font-bold"
              htmlFor="ID_FIRST_NAME_EN"
            >
              الاسم الشخصي بالإنجليزية <span className="text-danger">*</span>
            </label>
            <input
              ref={firstNameEnRef}
              type="text"
              id="ID_FIRST_NAME_EN"
              name="first_name_en"
              defaultValue={user.first_name_en}
              className="w-full rounded-md border-2 border-muted px-6 py-3 focus:outline-none focus:ring-2 focus:ring-primary"
            />
          </div>
          <div className="flex w-full flex-col gap-1">
            <label
              className="font-bold"
              htmlFor="ID_LAST_NAME_EN"
            >
              اسم العائلة بالإنجليزية <span className="text-danger">*</span>
            </label>
            <input
              ref={lastNameEnRef}
              type="text"
              id="ID_LAST_NAME_EN"
              name="last_name_en"
              defaultValue={user.last_name_en}
              className="w-full rounded-md border-2 border-muted px-6 py-3 focus:outline-none focus:ring-2 focus:ring-primary"
            />
          </div>
        </div>
        <div className="flex flex-col gap-1">
          <label
            className="font-bold"
            htmlFor="ID_CURRENT_JOB"
          >
            المسمى الوظيفي <span className="text-danger">*</span>
          </label>
          <input
            ref={currentJobRef}
            type="text"
            id="ID_CURRENT_JOB"
            name="current_job"
            defaultValue={user.current_job}
            className="w-full rounded-md border-2 border-muted px-6 py-3 focus:outline-none focus:ring-2 focus:ring-primary"
          />
        </div>
        <div className="flex flex-col gap-1">
          <label
            className="font-bold"
            htmlFor="ID_EMAIL"
          >
            البريد الإلكتروني <span className="text-danger">*</span>
          </label>
          <input
            ref={emailRef}
            type="email"
            id="ID_EMAIL"
            name="email"
            defaultValue={user.email}
            className="w-full rounded-md border-2 border-muted px-6 py-3 focus:outline-none focus:ring-2 focus:ring-primary"
          />
        </div>

        <div className="flex flex-col gap-1">
          <label
            className="font-bold"
            htmlFor="ID_GENDER"
          >
            النوع <span className="text-danger">*</span>
          </label>
          <SelectGender
            value={gender}
            onChange={(selectedGender) => setGender(selectedGender)}
          />
        </div>
        <div className="flex flex-col gap-1">
          <label
            className="font-bold"
            htmlFor="ID_ACTIVATE"
          >
            حالة الحساب
          </label>
          <SelectAccountStatus
            value={isActivated}
            onChange={(selectedActivation) => setActivated(selectedActivation)}
          />
        </div>
        <div className="flex flex-col gap-1">
          <label
            className="font-bold"
            htmlFor="ID_DESCRIPTION"
          >
            نبذة تعريفية
          </label>
          <textarea
            rows={5}
            id={"ID_DESCRIPTION"}
            name={"description"}
            className="w-full rounded-md border-2 border-muted px-6 py-4 focus:outline-none focus:ring-2 focus:ring-primary"
          >
            {user.description}
          </textarea>
        </div>
        <button
          type="submit"
          disabled={inProgress}
          className="flex w-fit rounded-md border-2 border-primary-hover bg-primary px-4 py-3 text-white hover:bg-primary-hover disabled:border-muted disabled:bg-light disabled:text-dark"
        >
          {inProgress ? (
            <div className="flex items-center">
              <FaSpinner
                size={16}
                className="ml-2 animate-spin"
              />
              <div>جاري تحديث المستخدم...</div>
            </div>
          ) : (
            "تحديث المستخدم"
          )}
        </button>
      </div>
    </form>
  )
}
