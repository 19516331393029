import React, { useState } from "react"
import { SlOptions } from "react-icons/sl"
import ModalCommitteeFolderOptions from "./ModalCommitteeFolderOptions"

export default function ButtonCommitteeFolderOptions({ folder }) {
  const [isOptionsModelOpen, setIsOptionsModelOpen] = useState(false)

  const handleOnClickOpenOptionsModal = (event) => {
    event.stopPropagation()
    setIsOptionsModelOpen(true)
  }

  const handleOnClickCloseOptionsModal = () => {
    setIsOptionsModelOpen(false)
  }
  return (
    <>
      <button
        onClick={handleOnClickOpenOptionsModal}
        className="rounded-[5px] border-2 border-muted p-1 hover:bg-light"
      >
        <SlOptions size={16} />
      </button>
      <ModalCommitteeFolderOptions
        isOpen={isOptionsModelOpen}
        folder={folder}
        onClose={handleOnClickCloseOptionsModal}
      />
    </>
  )
}
