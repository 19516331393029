import React from "react"
import TopMenuProfileItem from "./TopMenuProfileItem"
import { useLogout } from "../../auth"
import ButtonLogout from "../components/layout/ButtonLogout"

export default function TopMenu() {
  return (
    <div className="flex items-center justify-between border-b-2 border-muted bg-white px-4 py-2">
      <div className="flex items-center gap-2">
        <img
          src="/logo192.png"
          width={50}
          height={50}
          alt=""
        />
        <div>
          <h2 className="text-base font-bold">مركز المواصفات والمقاييس</h2>
          <h3>المنظمة العربية للتنمية الصناعية والتقييس والتعدين</h3>
        </div>
      </div>
      <div className="flex items-center gap-2">
        <TopMenuProfileItem />
        <ButtonLogout />
      </div>
    </div>
  )
}
