import axiosClient from "../../../config/axiosClient"

const CommitteeDocumentService = {
  addDocument(committeeId, folderId, document) {
    return axiosClient.post(
      `/dashboard/committees/${committeeId}/folders/${folderId}/documents`,
      document,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      },
    )
  },
  downloadDocument(committeeId, folderId, documentId) {
    return axiosClient.get(
      `/dashboard/committees/${committeeId}/folders/${folderId}/documents/${documentId}/download`,
      { responseType: "blob" },
    )
  },
  deleteDocument(committeeId, folderId, documentId) {
    return axiosClient.delete(
      `/dashboard/committees/${committeeId}/folders/${folderId}/documents/${documentId}`,
    )
  },
}

export default CommitteeDocumentService
