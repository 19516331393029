import React from "react"
import { Link } from "react-router-dom"
import useCommittee from "../../../committees/hooks/useCommittee"
import useMember from "../../hooks/useMember"

export default function BreadcrumbCommitteeMemberRole() {
  const { committee, isLoading } = useCommittee()
  const { isLoading: isMemberLoading, member } = useMember()
  return (
    <div className="mb-2 flex gap-0.5 font-bold">
      <Link
        className="hover:text-success"
        to={"/"}
      >
        الرئيسية
      </Link>
      <div>/</div>
      <Link
        className="hover:text-success"
        to={"/committees"}
      >
        اللجان الفنية العربية
      </Link>
      <div>/</div>
      {isLoading ? (
        <h2 className="animate-pulse">اللجنة الفنية العربية لمواصفات ...</h2>
      ) : (
        <h2 className="text-success">{committee?.title}</h2>
      )}
      <div>/</div>
      {isMemberLoading ? (
        <div className="h-4 w-32 animate-pulse rounded-md bg-muted"></div>
      ) : (
        <Link
          className="hover:text-success"
          to={`/users/${member.id}`}
        >
          {member.full_name_ar}
        </Link>
      )}
      <div>/</div>

      <h2 className="text-success">أعضاء اللجنة</h2>
    </div>
  )
}
