import { MdDelete } from "react-icons/md"
import useCommitteeFolderActions from "../../hooks/useCommitteeFolderActions"
import { toastError, toastSuccess } from "../../../../helper"
import { FaSpinner } from "react-icons/fa"

export default function ButtonDeleteCommitteeFolder({
  committeeId,
  folderId,
  onClose,
}) {
  const { deleteCommitteeFolder, inProgress } = useCommitteeFolderActions()
  async function handleOnClickDeleteCommitteeFolder() {
    try {
      const message = await deleteCommitteeFolder(committeeId, folderId)
      if (message) {
        toastSuccess(message)
        onClose()
      }
    } catch (error) {
      if (!error.response && !error.request) {
        toastError("لم يتم الاتصال بالخادم. يرجى التحقق من الاتصال بالإنترنت.")
      } else if (error.response) {
        toastError(error.response.data.error)
      } else if (error.request) {
        toastError("لم يتم استلام استجابة من الخادم. حاول مرة أخرى لاحقًا.")
      } else {
        toastError("حدث خطأ غير متوقع. حاول مرة أخرى لاحقًا.")
      }
    }
  }
  return (
    <button
      onClick={handleOnClickDeleteCommitteeFolder}
      disabled={inProgress}
      className={`flex w-full items-center gap-2 rounded-md border-2 border-danger bg-danger/10 px-4 py-2 text-danger hover:bg-danger/20 disabled:cursor-progress disabled:border-muted disabled:bg-light disabled:text-dark/50`}
    >
      {inProgress ? (
        <>
          <FaSpinner
            size={16}
            className="animate-spin"
          />
          <div>جاري حذف المجلد...</div>
        </>
      ) : (
        <>
          <MdDelete size={20} />
          <div>حذف المجلد</div>
        </>
      )}
    </button>
  )
}
