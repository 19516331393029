export default function LoaderEditProjectDetailsForm() {
  return (
    <div className="flex flex-col gap-4 rounded-md border-2 border-muted bg-white p-4">
      <div className="flex flex-col gap-2">
        <label className="font-bold">
          نوع الوثيقة<span className="text-danger">*</span>
        </label>
        <div className="flex gap-2">
          <div className="h-[45px] w-full animate-pulse rounded-md border-2 border-muted bg-light px-6 py-3" />
          <div className="h-[45px] w-full animate-pulse rounded-md border-2 border-muted bg-light px-6 py-3" />
        </div>
      </div>

      <div className="flex flex-col gap-2">
        <label className="font-bold">
          نوع إضافة المشروع<span className="text-danger">*</span>
        </label>
        <div className="flex gap-2">
          <div className="h-[45px] w-full animate-pulse rounded-md border-2 border-muted bg-light px-6 py-3" />
          <div className="h-[45px] w-full animate-pulse rounded-md border-2 border-muted bg-light px-6 py-3" />
        </div>
      </div>

      <div className="flex flex-col gap-2">
        <label className="font-bold">
          نوع المشروع<span className="text-danger">*</span>
        </label>
        <div className="flex gap-4">
          <div className="h-[45px] w-full animate-pulse rounded-md border-2 border-muted bg-light px-6 py-3" />
          <div className="h-[45px] w-full animate-pulse rounded-md border-2 border-muted bg-light px-6 py-3" />
        </div>
      </div>

      <div className="flex flex-col gap-4">
        <div className="flex w-full flex-col gap-2">
          <label
            htmlFor="ID_TITLE_AR_PROJECT"
            className="font-bold"
          >
            عنوان المشروع باللغة العربية <span className="text-danger">*</span>
          </label>
          <div className="h-[45px] w-full animate-pulse rounded-md border-2 border-muted bg-light px-6 py-3" />
        </div>
        <div className="flex w-full flex-col gap-2">
          <label
            htmlFor="ID_TITLE_EN_PROJECT"
            className="font-bold"
          >
            عنوان المشروع باللغة الإنجليزية{" "}
            <span className="text-danger">*</span>
          </label>
          <div className="h-[45px] w-full animate-pulse rounded-md border-2 border-muted bg-light px-6 py-3" />
        </div>
      </div>
      <label
        htmlFor="ID_PROJECT_FILE"
        className="flex w-full cursor-pointer flex-col gap-2"
      >
        <span className="font-bold">
          ملف المشروع <span className="text-danger">*</span>
        </span>
        <div className="h-[45px] w-full animate-pulse rounded-md border-2 border-muted bg-light px-6 py-3" />
      </label>
      <div className="flex w-full flex-col gap-2">
        <label className="font-bold">لغة الملف</label>
        <div className="h-[45px] w-full animate-pulse rounded-md border-2 border-muted bg-light px-6 py-3" />
      </div>
      <div className="flex w-full flex-col gap-2">
        <label className="font-bold">
          رقم المواصفة القياسية العربية
          <span className="text-danger">*</span>
        </label>
        <div className="h-[45px] w-full animate-pulse rounded-md border-2 border-muted bg-light px-6 py-3" />
      </div>
      <div className="flex w-full flex-col gap-2">
        <label className="font-bold">
          المرجع الرئيسي <span className="text-danger">*</span>
        </label>
        <div className="h-[45px] w-full animate-pulse rounded-md border-2 border-muted bg-light px-6 py-3" />
      </div>

      <div className="flex w-full flex-col gap-2">
        <label className="font-bold">
          التصنيف الدولي للمواصفة (ICS)<span className="text-danger">*</span>
        </label>
        <div className="h-[45px] w-full animate-pulse rounded-md border-2 border-muted bg-light px-6 py-3" />
      </div>

      <div className="flex w-full flex-col gap-2">
        <label className="font-bold">
          مجال المشروع<span className="text-danger">*</span>
        </label>
        <div className="h-28 w-full animate-pulse rounded-md border-2 border-muted bg-light px-6 py-3" />
      </div>
      <div className="flex w-full flex-col gap-2">
        <label className="font-bold">
          ملاحظات إضافية<span className="text-danger">*</span>
        </label>
        <div className="h-28 w-full animate-pulse rounded-md border-2 border-muted bg-light px-6 py-3" />
      </div>

      <div className="flex w-full flex-col gap-2">
        <label className="font-bold">
          المراجع الداخلية <span className="text-danger">*</span>
        </label>
        <div className="h-[45px] w-full animate-pulse rounded-md border-2 border-muted bg-light px-6 py-3" />
        <div className="h-[45px] w-full animate-pulse rounded-md border-2 border-muted bg-light px-6 py-3" />
        <div className="h-[45px] w-full animate-pulse rounded-md border-2 border-muted bg-light px-6 py-3" />
      </div>
    </div>
  )
}
