import { Link } from "react-router-dom"
import { useProjectContext } from "../../context/project"

export default function BreadcrumbProjectVersions() {
  const {
    projectState: { project, isLoading },
  } = useProjectContext()

  if (isLoading)
    return <div className="h-4 w-64 animate-pulse rounded-sm bg-muted" />
  return (
    <div className="flex gap-0.5 font-bold">
      <h2>الرئيسية</h2>
      <div>/</div>
      <Link
        className="line-clamp-1 hover:text-success"
        to={"/committees"}
      >
        اللجان الفنية العربية
      </Link>
      <div>/</div>
      <h2 className="line-clamp-1">{project.committee.title}</h2>
      <div>/</div>
      <Link
        className="line-clamp-1 hover:text-success"
        to={`/committees/${project.committee.id}/projects`}
      >
        مشاريع اللجنة
      </Link>
      <div>/</div>
      <Link
        className="line-clamp-1 hover:text-success"
        to={`/committees/${project.committee.id}/projects/${project.id}`}
      >
        {project.title_ar}
      </Link>
      <div>/</div>
      <h2 className="line-clamp-1 text-success">الإصدارات</h2>
    </div>
  )
}
