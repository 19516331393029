import axiosClient from "../../../config/axiosClient"

const getCommittees = async () => {
  try {
    const { data } = await axiosClient.get("/services/committees")

    return data.committees
  } catch (error) {
    throw error
  }
}

export { getCommittees }
