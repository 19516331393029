import React from "react"
import { IoMdTrash } from "react-icons/io"
import useCommitteeDocumentActions from "../../hooks/useCommitteeDocumentActions"
import useCommitteeFolderActions from "../../hooks/useCommitteeFolderActions"
import { toastError, toastSuccess } from "../../../../helper"

export default function ButtonDeleteCommitteeFolderDocument({
  committeeId,
  folderId,
  documentId,
}) {
  const { deleteDocument, inProgress } = useCommitteeDocumentActions()
  async function handleOnDeleteCommitteeFolderDocument() {
    try {
      const message = await deleteDocument(committeeId, folderId, documentId)
      if (message) {
        toastSuccess(message)
      }
    } catch (error) {
      if (!error.response && !error.request) {
        toastError("لم يتم الاتصال بالخادم. يرجى التحقق من الاتصال بالإنترنت.")
      } else if (error.response) {
        toastError(error.response.data.error)
      } else if (error.request) {
        toastError("لم يتم استلام استجابة من الخادم. حاول مرة أخرى لاحقًا.")
      } else {
        toastError("حدث خطأ غير متوقع. حاول مرة أخرى لاحقًا.")
      }
    }
  }
  return (
    <button
      onClick={handleOnDeleteCommitteeFolderDocument}
      className="rounded-[5px] border-2 border-danger bg-danger/30 p-1 text-danger hover:bg-danger hover:text-white"
    >
      <IoMdTrash size={16} />
    </button>
  )
}
