import { useState } from "react"
import CommitteeFolderService from "../services/committeeFolderService"
import { useCommitteeFoldersContext } from "../context/CommitteeFoldersProvider"
import { useParams, useSearchParams } from "react-router-dom"
import { COMMITTEE_FOLDERS_ACTIONS } from "../context"
import download from "downloadjs"

export default function useCommitteeFolderActions() {
  const { committee_id } = useParams()
  const { committeeFoldersDispatch } = useCommitteeFoldersContext()
  const [inProgress, setInProgress] = useState(false)

  async function getCommitteeFolders(queryString) {
    try {
      committeeFoldersDispatch({
        type: COMMITTEE_FOLDERS_ACTIONS.SET_IS_LOADING,
        payload: {
          isLoading: true,
        },
      })
      const response = await CommitteeFolderService.getFolders(
        committee_id,
        queryString,
      )

      committeeFoldersDispatch({
        type: COMMITTEE_FOLDERS_ACTIONS.SET_FOLDERS,
        payload: {
          folders: response.data.folders, // Assuming response contains a data object with folders
          pagination: {
            links: response.data.links,
            current_page: response.data.current_page,
            first_page: response.data.first_page,
            last_page: response.data.last_page,
          },
        },
      })
    } catch (err) {
    } finally {
      committeeFoldersDispatch({
        type: COMMITTEE_FOLDERS_ACTIONS.SET_IS_LOADING,
        payload: {
          isLoading: false,
        },
      })
    }
  }

  const getCommitteeFolder = async (committeeId, folderId) => {
    try {
      const { data } = await CommitteeFolderService.getFolder(
        committeeId,
        folderId,
      )
      return data.folder
    } catch (error) {}
  }

  const createCommitteeFolder = async (name) => {
    try {
      setInProgress(true)
      const response = await CommitteeFolderService.createFolder(
        committee_id,
        name,
      )
      await getCommitteeFolders()
      return response.data.folder_id
    } catch (error) {
      throw error
    } finally {
      setInProgress(false)
    }
  }
  async function downloadCommitteeFolder(folderId, folderName) {
    try {
      setInProgress(true)
      const response = await CommitteeFolderService.downloadFolder(
        committee_id,
        folderId,
      )
      download(response.data, folderName, response.headers["Content-Type"])
      return `تم تحميل المجلد "${folderName}" بنجاح!`
    } catch (error) {
      throw error
    } finally {
      setInProgress(false)
    }
  }
  async function renameCommitteeFolder(committeeId, folderId, payload) {
    try {
      setInProgress(true)
      const { data } = await CommitteeFolderService.renameFolder(
        committeeId,
        folderId,
        payload,
      )
      return data.message
    } catch (error) {
      throw error
    } finally {
      setInProgress(false)
    }
  }

  const deleteCommitteeFolder = async (committeeId, folderId) => {
    try {
      setInProgress(true)
      const { data } = await CommitteeFolderService.deleteFolder(
        committeeId,
        folderId,
      )
      await getCommitteeFolders()
      return data.message
    } catch (error) {
      throw error
    } finally {
      setInProgress(false)
    }
  }

  return {
    getCommitteeFolders,
    getCommitteeFolder,
    createCommitteeFolder,
    downloadCommitteeFolder,
    renameCommitteeFolder,
    deleteCommitteeFolder,
    inProgress,
  }
}
