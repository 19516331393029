import { useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { CgClose, CgSpinner } from "react-icons/cg"
import { MdOutlineUploadFile } from "react-icons/md"
import { FiPlus } from "react-icons/fi"
import {
  generateRandomPassword,
  toastError,
  toastSuccess,
} from "../../../../helper"
import { useProjectsActions } from "../../hooks"
import { FaSpinner } from "react-icons/fa"

export default function FormAddProjectAttachments() {
  const navigate = useNavigate()
  const { committee_id, project_id } = useParams()
  const { addProjectAttachment } = useProjectsActions()
  const [inProgress, setInProgress] = useState(false)
  const [attachments, setAttachments] = useState([
    { id: generateRandomPassword(), name: "", file: null },
  ])

  const handleAttachmentChange = (id, file) => {
    setAttachments(
      attachments.map((attachment) =>
        attachment.id === id
          ? {
              ...attachment,
              file: file,
              name: file.name?.split(".").slice(0, -1).join("."),
            }
          : attachment,
      ),
    )
  }

  const handleNameChange = (id, name) => {
    setAttachments(
      attachments.map((attachment) =>
        attachment.id === id ? { ...attachment, name } : attachment,
      ),
    )
  }

  const removeAttachment = (id) => {
    setAttachments(attachments.filter((attachment) => attachment.id !== id))
  }

  const addAttachment = () => {
    setAttachments([
      ...attachments,
      { id: generateRandomPassword(), name: "", file: null },
    ])
  }

  async function handleOnSubmitAddProjectAttachments(event) {
    try {
      event.preventDefault()
      setInProgress(true)
      for (let i = 0; i < attachments.length; i++) {
        const payload = {
          name: attachments[i].name,
          file: attachments[i].file,
        }

        const message = await addProjectAttachment(
          committee_id,
          project_id,
          payload,
        )
        toastSuccess(message)
      }
      navigate(`/committees/${committee_id}/projects/${project_id}`)
    } catch (error) {
      if (!error.response && !error.request) {
        toastError("لم يتم الاتصال بالخادم. يرجى التحقق من الاتصال بالإنترنت.")
      } else if (error.response) {
        toastError(error.response.data.error)
      } else if (error.request) {
        toastError("لم يتم استلام استجابة من الخادم. حاول مرة أخرى لاحقًا.")
      } else {
        toastError("حدث خطأ غير متوقع. حاول مرة أخرى لاحقًا.")
      }
    } finally {
      setInProgress(false)
      console.log("end")
    }
  }
  return (
    <form
      onSubmit={handleOnSubmitAddProjectAttachments}
      className="flex flex-col gap-4 rounded-md border-2 border-muted bg-white p-4"
    >
      {attachments.map((attachment, index) => (
        <div className="flex w-full items-end gap-2">
          <div className="flex w-full flex-col gap-1">
            <label className="font-bold">
              اسم الوثيقة<span className="text-danger">*</span>
            </label>
            <input
              type="text"
              placeholder="اسم الوثيقة"
              defaultValue={attachment.name}
              onChange={(e) => {
                handleNameChange(attachment.id, e.target.value)
              }}
              className="w-full rounded-md border-2 border-muted px-6 py-3 focus:outline-none focus:ring-2 focus:ring-primary"
            />
          </div>
          <label
            htmlFor={`ID_ATTACHEMNT_FILE_${attachment.id}`}
            className="flex w-full cursor-pointer flex-col gap-2"
          >
            <span className="font-bold">
              الوثيقة <span className="text-danger">*</span>
            </span>
            <div className="group flex items-center justify-between overflow-hidden rounded-md border-2 border-muted bg-white text-secondary hover:border-primary">
              <div className="line-clamp-1 w-full px-4 py-2">
                {attachment.file ? attachment.file.name : `اختر ملف`}
              </div>
              <div className="h-full w-fit bg-muted px-2 py-2 group-hover:bg-primary group-hover:text-white">
                <MdOutlineUploadFile size={22} />
              </div>
            </div>
            <input
              type="file"
              name={`project_attachment_${attachment.id}`}
              id={`ID_ATTACHEMNT_FILE_${attachment.id}`}
              className="hidden"
              onChange={(e) =>
                handleAttachmentChange(attachment.id, e.target.files[0])
              }
            />
          </label>

          {index > 0 && (
            <button
              type="button"
              onClick={() => removeAttachment(attachment.id)}
              className="rounded-md border-2 border-danger-hover bg-danger/80 px-2 py-2 text-white hover:bg-danger"
            >
              <CgClose size={22} />
            </button>
          )}
        </div>
      ))}
      <button
        onClick={addAttachment}
        type="button"
        className="flex w-full items-center justify-center gap-2 rounded-md border-2 border-muted bg-light px-6 py-3 hover:bg-muted focus:outline-none"
      >
        <FiPlus size={18} />
        إضافة وثيقة
      </button>
      <button
        type="submit"
        disabled={inProgress}
        className="flex w-fit items-center rounded-md border-2 border-primary-hover bg-primary px-4 py-3 text-white transition duration-200 hover:bg-primary-hover disabled:cursor-not-allowed disabled:border-muted disabled:bg-light disabled:text-dark"
      >
        {inProgress ? (
          <div className="flex items-center">
            <FaSpinner
              size={16}
              className="ml-4 animate-spin"
            />
            <span>جاري إضافة وثائق ذات صلة...</span>
          </div>
        ) : (
          <span>إضافة وثائق ذات صلة</span>
        )}
      </button>
    </form>
  )
}
