import { Link } from "react-router-dom"
import { MdEdit } from "react-icons/md"
import Modal from "../../../core/components/UI/Modal"
import { ButtonDeleteCommittee } from "."

export default function ModalCommitteeOptions({ committee, isOpen, onClose }) {
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
    >
      <div className="flex min-w-80 flex-col gap-2 rounded-md border-2 border-muted p-2">
        <Link
          to={`/committees/${committee.id}/edit`}
          className={`flex items-center gap-2 rounded-md border-2 border-light px-4 py-2 hover:border-muted hover:bg-light`}
        >
          <MdEdit size={20} />
          <span>تعديل اللجنة</span>
        </Link>
        <ButtonDeleteCommittee
          committee={committee}
          onClose={onClose}
        />
      </div>
    </Modal>
  )
}
