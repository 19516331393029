import { Outlet } from "react-router-dom"
import SplashLoading from "./../components/SplashLoading"
import { useAuth, useAuthActions } from "../../auth"
import { useEffect } from "react"

export default function SplashPage() {
  const { authState } = useAuth()
  const { getCurrentUser } = useAuthActions()

  useEffect(function () {
    getCurrentUser()
  }, [])

  useEffect(
    function () {

      if (!authState.isLoading && authState.currentUser === null) {
        window.location.replace(process.env.REACT_APP_AUTH_URL)
      } else if (
        !authState.isLoading &&
        authState.currentUser !== null &&
        authState.currentUser?.type !== "ADMIN"
      ) {
        window.location.replace(process.env.REACT_APP_FORUMS_URL)
      }
    },
    [authState.currentUser, authState.isLoading],
  )

  if (
    !authState.isLoading &&
    authState.currentUser !== null &&
    authState.currentUser?.type === "ADMIN"
  ) {
    return <Outlet />
  }
  return <SplashLoading />
}
