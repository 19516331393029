import { CommitteeMembersGridItem } from "."

export default function CommitteeMembersGrid({ members }) {
  return (
    <div className="grid grid-cols-12 gap-2">
      {members.map((member) => (
        <CommitteeMembersGridItem
          key={member.id}
          member={member}
        />
      ))}
    </div>
  )
}
