import { AuthService } from ".."
import { AUTH_ACTIONS, useAuth } from ".."

export default function useAuthActions() {
  const { authDispatch } = useAuth()

  async function getCurrentUser() {
    try {
      authDispatch({
        type: AUTH_ACTIONS.SET_IS_LOADING,
        payload: {
          isLoading: true,
        },
      })
      const { data } = await AuthService.getCurrentUser()

      authDispatch({
        type: AUTH_ACTIONS.SET_CURRENT_USER,
        payload: {
          user: data.user,
        },
      })
    } catch (error) {
      // throw error
    } finally {
      authDispatch({
        type: AUTH_ACTIONS.SET_IS_LOADING,
        payload: {
          isLoading: false,
        },
      })
    }
  }

  async function logout() {
    try {
      await AuthService.logout()
      authDispatch({
        type: AUTH_ACTIONS.SET_CURRENT_USER,
        payload: {
          user: null,
        },
      })
    } catch (error) {
      throw error
    }
  }

  return { logout, getCurrentUser }
}
