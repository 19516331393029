import { Outlet, useLocation } from "react-router-dom"
import SideMenu from "./side-menu/SideMenu"
import { Toaster } from "react-hot-toast" // Import ToastContainer
import TopMenu from "./TopMenu"
import { useEffect } from "react"

export default function Layout() {
  const location = useLocation()

  useEffect(() => {
    window.scrollTo(0, 0) // Scroll to top
  }, [location])
  return (
    <div className="flex w-full flex-col text-xs">
      <Toaster
        position="top-left"
        reverseOrder={false}
      />
      <TopMenu />
      <div className="mb-32 flex h-full w-full gap-2 p-2">
        <SideMenu />
        <Outlet />
      </div>
    </div>
  )
}
