import { useEffect, useState } from "react"
import useCommitteeFolderActions from "./useCommitteeFolderActions"
import { useParams } from "react-router-dom"

export default function useCommitteeFolder() {
  const { committee_id, folder_id } = useParams()
  const [folder, setFolder] = useState(null)
  const [isLoading, setIsLoading] = useState(true)
  const { getCommitteeFolder } = useCommitteeFolderActions()

  async function fetchCommitteeFolder(params) {
    try {
      setIsLoading(true)
      const folder = await getCommitteeFolder(committee_id, folder_id)
      setFolder(folder)
    } catch (error) {
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    fetchCommitteeFolder()
  }, [committee_id, folder_id])

  return { folder, isLoading }
}
