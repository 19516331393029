import React from "react"
import Modal from "../../../core/components/UI/Modal"

export default function ModalCommentDetails({ isOpen, onClose, detail }) {
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
    >
      <div className="flex max-h-[500px] w-full flex-col gap-4 overflow-y-scroll border-2 border-muted p-2 md:w-[600px]">
        <div className="rounded-md border-2 border-muted p-2">
          <h2 className="font-bold">النص الأصلي : </h2>
          <p>{detail.original}</p>
        </div>
        <div className="rounded-md border-2 border-muted p-2">
          <h2 className="font-bold">النص المقترح : </h2>
          <p>{detail.suggested}</p>
        </div>
        <div className="rounded-md border-2 border-muted p-2">
          <h2 className="font-bold">المبررات : </h2>
          <p>{detail?.justifications}</p>
        </div>
      </div>
    </Modal>
  )
}
