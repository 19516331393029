import { useState, useRef } from "react"
import { FiFilter } from "react-icons/fi"
import { useSearchParams } from "react-router-dom"
import SelectProjectTypes from "../../../core/components/UI/SelectProjectTypes"
import SelectCategory from "../../../core/components/UI/SelectCategory"
import ModalFiler from "../../../core/components/UI/ModalFiler"

export default function ModalProjectsFilter({ onClose, isOpen }) {
  const [searchParams, setSearchParams] = useSearchParams()

  const referenceRef = useRef(null)
  const fullnameRef = useRef(null)
  const titleRef = useRef(null)
  const numberRef = useRef(null)

  const [countryId, setCountryId] = useState(null)
  const [type, setType] = useState("")
  const [category, setCategory] = useState(null)

  const onSubmit = (event) => {
    event.preventDefault()

    const filters = {
      country_id: countryId,
      project_type: type,
      project_category: category,
      project_title: titleRef.current.value,
      project_reference: referenceRef.current.value,
      project_number: numberRef.current.value,
      member_fullname: fullnameRef.current.value,
    }

    setSearchParams({ ...filters, page: "1" })
    onClose()
  }

  const handleReset = () => {
    setCountryId(null)
    setType(null)
    setCategory(null)
    fullnameRef.current.value = ""
    titleRef.current.value = ""
    referenceRef.current.value = ""
    numberRef.current.value = ""

    setSearchParams({ page: "1" })
    onClose()
  }

  return (
    <ModalFiler
      onClose={onClose}
      isOpen={isOpen}
    >
      <form
        className="flex flex-col gap-2"
        onSubmit={onSubmit}
      >
        <div className="flex flex-col gap-1">
          <label
            htmlFor="ID_PROJECT_CATEGORY"
            className="font-bold"
          >
            نوع الوثيقة
          </label>
          <SelectCategory
            value={category}
            onChange={(selectedCategory) => setCategory(selectedCategory)}
          />
        </div>
        <div className="flex flex-col gap-1">
          <label
            htmlFor="ID_PROJECT_TYPE"
            className="font-bold"
          >
            نوع المشروع
          </label>
          <SelectProjectTypes
            value={type}
            onChange={(type) => setType(type)}
          />
        </div>
        <div className="flex flex-col gap-1">
          <label
            htmlFor="ID_FULLNAME"
            className="font-bold"
          >
            الاسم الكامل
          </label>
          <input
            ref={fullnameRef}
            type="text"
            id="ID_FULLNAME"
            placeholder="الاسم الكامل"
            className="w-full rounded-md border-2 border-muted px-6 py-3 focus:outline-none focus:ring-2 focus:ring-primary"
          />
        </div>
        <div className="flex flex-col gap-1">
          <label
            htmlFor="ID_TITLE"
            className="font-bold"
          >
            عنوان المشروع
          </label>
          <input
            ref={titleRef}
            type="text"
            id="ID_TITLE"
            placeholder="عنوان المشروع"
            className="w-full rounded-md border-2 border-muted px-6 py-3 focus:outline-none focus:ring-2 focus:ring-primary"
          />
        </div>
        <div className="flex flex-col gap-1">
          <label
            htmlFor="ID_REFERENCE"
            className="font-bold"
          >
            المرجع الرئيسي
          </label>
          <input
            ref={referenceRef}
            type="text"
            id="ID_REFERENCE"
            placeholder="المرجع الرئيسي "
            className="w-full rounded-md border-2 border-muted px-6 py-3 focus:outline-none focus:ring-2 focus:ring-primary"
          />
        </div>
        <div className="flex flex-col gap-1">
          <label
            htmlFor="ID_NUMBER"
            className="font-bold"
          >
            رقم المواصفة القياسية العربية
          </label>
          <input
            ref={numberRef}
            type="text"
            id="ID_NUMBER"
            placeholder="رقم المواصفة القياسية العربية"
            className="w-full rounded-md border-2 border-muted px-6 py-3 focus:outline-none focus:ring-2 focus:ring-primary"
          />
        </div>

        <div className="flex gap-2">
          <button
            type="button"
            className="w-full rounded-md border-2 border-muted bg-light px-4 py-3 hover:bg-muted"
            onClick={handleReset}
          >
            مسح
          </button>
          <button
            type="submit"
            className="w-full rounded-md border-2 border-primary-hover bg-primary px-4 py-3 text-white hover:bg-primary-hover"
          >
            بحث
          </button>
        </div>
      </form>
    </ModalFiler>
  )
}
