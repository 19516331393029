import { Link, useParams } from "react-router-dom"
import Modal from "../../../core/components/UI/Modal"
import ButtonDownloadProjectAttachment from "./ButtonDownloadAttachment"
import ButtonRemoveProjectAttachment from "./ButtonRemoveAttachment"
import useProjectAttachemnts from "../../hooks/useProjectAttachemnts"

export default function ModalProjectAttachments({ isOpen, onClose }) {
  const { committee_id, project_id } = useParams()
  const { attachments, isLoading, fetchProjectAttachments } =
    useProjectAttachemnts()

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
    >
      <div className="flex w-full flex-col border-2 border-muted text-xs md:w-[600px]">
        <div className="flex items-center justify-between border-b-2 border-muted bg-light p-2">
          <h3 className="font-bold">وثائق ذات صلة</h3>
          <Link
            to={`/committees/${committee_id}/projects/${project_id}/attachments`}
            className="flex items-center gap-2 rounded-md border-2 border-muted bg-white px-4 py-2 hover:bg-muted"
          >
            إضافة وثائق
          </Link>
        </div>
        <div className="flex h-[300px] flex-col gap-2 overflow-y-scroll p-2">
          {isLoading
            ? [...Array(12)].map((_, index) => (
                <div
                  key={index}
                  className="min-h-12 w-full animate-pulse rounded-md border-2 border-muted bg-white"
                />
              ))
            : attachments?.map((attachment) => (
                <div
                  key={attachment.id}
                  className="flex items-center justify-between rounded-md border-2 border-muted p-2"
                >
                  <div className="flex items-center gap-2">
                    {attachment.name}.{attachment.extension}
                  </div>
                  <div className="flex gap-2">
                    <ButtonDownloadProjectAttachment attachment={attachment} />
                    <ButtonRemoveProjectAttachment
                      attachmentId={attachment.id}
                      fetchProjectAttachments={fetchProjectAttachments}
                    />
                  </div>
                </div>
              ))}
        </div>
      </div>
    </Modal>
  )
}
