import React, { useRef } from "react"
import ModalFiler from "../../../core/components/UI/ModalFiler"
import { useSearchParams } from "react-router-dom"

export default function ModalCommitteesFilter({ onClose, isOpen }) {
  const [searchParams, setSearchParams] = useSearchParams()
  const committeeTitleRef = useRef(null)
  const projectTitleRef = useRef(null)
  const memberNameRef = useRef(null)

  function handleOnSubmitCommitteesFilterForm(event) {
    event.preventDefault()
    const filters = {
      project_title: projectTitleRef.current?.value,
      committee_title: committeeTitleRef.current?.value,
      member_fullname: memberNameRef.current?.value,
    }

    setSearchParams({ ...filters, page: "1" })
    onClose()
  }
  function handleOnClickResetCommitteesFilter() {
    committeeTitleRef.current.value = ""
    memberNameRef.current.value = ""
    setSearchParams({ page: "1" })
    onClose()
  }
  return (
    <ModalFiler
      onClose={onClose}
      isOpen={isOpen}
    >
      <form
        className="flex flex-col gap-2"
        onSubmit={handleOnSubmitCommitteesFilterForm}
      >
        <div className="flex flex-col gap-1">
          <label
            htmlFor="committee_title"
            className="font-bold"
          >
            عنوان اللجنة
          </label>
          <input
            type="text"
            id="committee_title"
            placeholder="عنوان اللجنة"
            className="w-full rounded-md border-2 border-muted px-6 py-3 focus:outline-none focus:ring-2 focus:ring-primary"
            ref={committeeTitleRef}
          />
        </div>
        <div className="flex flex-col gap-1">
          <label
            htmlFor="project_title"
            className="font-bold"
          >
            عنوان المشروع
          </label>
          <input
            type="text"
            id="project_title"
            placeholder="عنوان المشروع"
            className="w-full rounded-md border-2 border-muted px-6 py-3 focus:outline-none focus:ring-2 focus:ring-primary"
            ref={projectTitleRef}
          />
        </div>
        <div className="flex flex-col gap-1">
          <label
            htmlFor="member_fullname"
            className="font-bold"
          >
            اسم العضو
          </label>
          <input
            type="text"
            id="member_fullname"
            placeholder="اسم العضو"
            className="w-full rounded-md border-2 border-muted px-6 py-3 focus:outline-none focus:ring-2 focus:ring-primary"
            ref={memberNameRef}
          />
        </div>
        <div className="flex gap-2">
          <button
            type="button"
            className="w-full rounded-md border-2 border-muted bg-light px-4 py-3 hover:bg-muted"
            onClick={handleOnClickResetCommitteesFilter}
          >
            مسح
          </button>
          <button
            type="submit"
            className="w-full rounded-md border-2 border-primary-hover bg-primary px-4 py-3 text-white hover:bg-primary-hover"
          >
            بحث
          </button>
        </div>
      </form>
    </ModalFiler>
  )
}
