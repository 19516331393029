import { useUser } from "../.."
import { LoaderUserAccountInformations, UserAccountInformationsItem } from "."
import { datetimeFormat, getUserTypeByKey } from "../../../../helper"

export default function UserAccountInformations() {
  const { user, isLoading } = useUser()

  if (isLoading) {
    return <LoaderUserAccountInformations />
  }
  if (user)
    return (
      <div className="rounded-md border-2 border-muted bg-white p-4">
        <h2 className="mb-4 text-base font-bold">معلومات الحساب </h2>
        <div className="flex flex-col gap-2">
          <UserAccountInformationsItem
            label={"نوع الحساب"}
            value={getUserTypeByKey(user.type)}
          />
          <UserAccountInformationsItem
            label={"المسمى الوظيفي"}
            value={user.current_job}
          />
          <div className="flex gap-2">
            <UserAccountInformationsItem
              label={"الاسم الشخصي بالعربية"}
              value={user.first_name_ar}
            />
            <UserAccountInformationsItem
              label={"اسم العائلة بالعربية"}
              value={user.last_name_ar}
            />
          </div>
          <div className="flex gap-2">
            <UserAccountInformationsItem
              label={"الاسم الشخصي بالإنجليزية"}
              value={user.first_name_en}
            />
            <UserAccountInformationsItem
              label={"اسم العائلة بالإنجليزية"}
              value={user.last_name_en}
            />
          </div>
          <div className="flex gap-2">
            <UserAccountInformationsItem
              label={"دولة "}
              value={user.country.name_ar}
            />
            <UserAccountInformationsItem
              label={"جهاز تقييس"}
              value={user.entity.name}
            />
          </div>

          <UserAccountInformationsItem
            label={"البريد الإلكتروني"}
            value={user.email}
          />
          <div className="flex gap-2">
            <UserAccountInformationsItem
              label={"النوع"}
              value={user.gender}
            />
            <UserAccountInformationsItem
              label={"حالة الحساب"}
              value={user.is_activated ? "نشط" : "متوقف"}
            />
          </div>
          <UserAccountInformationsItem
            label={"نبذة تعريفية"}
            value={user.description}
          />
          <UserAccountInformationsItem
            label={"أخر ظهور "}
            value={user.last_active ? datetimeFormat(user.last_active) : "--"}
          />
        </div>
      </div>
    )
}
