import axiosClient from "../../../config/axiosClient"

const AuthService = {
  getCurrentUser() {
    return axiosClient.get(`/session/me`)
  },
  logout() {
    return axiosClient.post(`/session/logout`)
  },
}

export default AuthService
